import { Button, ButtonProps } from "@material-ui/core";
import { FunctionComponent } from "react";
import { useTranslate } from "../../service/translation";

interface ViewChannelButtonProps extends ButtonProps {
    component?: any;
    to?: string;
}

const ViewChannelButton: FunctionComponent<ViewChannelButtonProps> = (props: ViewChannelButtonProps) => {
    const translate = useTranslate();

    return (
        <Button
            aria-label={translate("newshub.action.viewChannel")}
            color="primary"
            size="small"
            variant="contained"
            {...props}
            tabIndex={-1}
        >
            {translate("newshub.action.viewChannel")}
        </Button>
    );
};

export default ViewChannelButton;
