import React, { FunctionComponent } from "react";
import MepCardSkeleton from "./MepCardSkeleton";
import { SkeletonProps } from "@material-ui/lab";
import { useMepCardListStyles } from "./useMepCardListStyles";
import {Box} from "@material-ui/core";

interface MepCardListSkeletonProps extends SkeletonProps {
    numItems: number;
}

const MepCardListSkeleton: FunctionComponent<MepCardListSkeletonProps> = (props: MepCardListSkeletonProps) => {
    const classes = useMepCardListStyles();
    const { numItems, ...rest } = props;

    const items = Array.from({ length: numItems }, (_, i) => i + 1);

    return (
        <Box className={classes.list} height={"auto"}>
            {
                items.map((i: number) => (
                    <MepCardSkeleton
                        key={`mep-card-skeleton-${i}`}
                        {...rest}
                    />
                ))
            }
        </Box>
    );
};

MepCardListSkeleton.defaultProps = {
    animation: "wave",
    numItems: 1,
};

export default MepCardListSkeleton;
