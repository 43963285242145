import React, { FunctionComponent,useState,useEffect } from "react";
import TopicChipList from "./TopicChipList";
import TopicChipListSkeleton from "./TopicChipListSkeleton";
import {apiProvider,ApiParamsType} from "../../service/data/apiProvider";
import {appDefaultFilters, FiltersContext, MapTopicParams} from "../../service/filters";


interface TopicListProps {
    title?: string;
    justifyContent?: string;
    providerId?: number
}

const TopicList: FunctionComponent<TopicListProps> = (props: TopicListProps) => {

    const { justifyContent, title,providerId } = props;
    const [filters,setFilters] = React.useContext(FiltersContext);
    const [topics, setTopics] = useState([]);

    const onClear = () => {
        setFilters({
            ...filters,
            activeTopics:[],
            crossActions:{
                ...filters.crossActions,
                clearTopics:false
            }
        }
        );
    }

    const fetchTopics = ()=>{
        let params:ApiParamsType = {...MapTopicParams(filters)};

        apiProvider.getTopics(params,providerId)
            .then((topics) => {
                let unique:string[] = [];
                let newTopics = topics.filter((item:any,index:number) => {
                    let current = item.content.toLowerCase();
                    if(unique.indexOf(current) !== -1) return false;
                    unique.push(current);
                    return true;
                }).filter((item:any,index:number) => index < 25);

                //add missing active topics.
                for(let i in filters.activeTopics){
                    let old  = filters.activeTopics[i]
                    let olHasInNew = newTopics.filter( (item:any,index:number) => item.content === old );
                    if(olHasInNew.length === 0) newTopics.unshift({
                        id:old,
                        content: old,
                        value: 0, //fix value 4 in index operation .
                        virality:'2'
                    });
                }

                setTopics(newTopics);
            })
            .catch((error)=> {setTopics([])}
        );
    }

    useEffect(() => {
        if(filters.topicsVersion === 0) return;
        fetchTopics();
    },[filters.topicsVersion,providerId]);



    useEffect(() => {
       // fetchTopics();
    },[]);


    useEffect(() => {
        if(filters.crossActions.clearTopics === true){
            onClear();
        }

    },[filters.crossActions.clearTopics]);


    if (topics.length == 0) {
        return (
           <></>
        );
    }



    return (
        <TopicChipList
            data={topics}
            title={title}
            justifyContent={justifyContent}
            onClear={onClear}
        />
    );
};

export default TopicList;
