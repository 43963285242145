import React, { FunctionComponent } from "react";
import { Button, ButtonProps } from "@material-ui/core";
import { useTranslate } from "../../service/translation";

interface UndoButtonProps {}

const UndoButton: FunctionComponent<UndoButtonProps & ButtonProps> = (props: UndoButtonProps & ButtonProps) => {
    const translate = useTranslate();

    return (
        <Button
            color="primary"
            size="small"
            {...props}
        >
            {translate("newshub.action.undo")}
        </Button>
    )
};

export default UndoButton;
