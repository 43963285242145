import { Reducer } from "redux";
import { HIDE_NOTIFICATION, RESET_NOTIFICATION, SHOW_NOTIFICATION } from "../actions";
import { NotificationActionType } from "../NotificationActionType";
import { NotificationState } from "../NotificationState";
import { UNDO } from "../../undo";

const initialState: NotificationState = [];

const notificationReducer: Reducer<NotificationState> = (previousState = initialState, action: NotificationActionType) => {
    switch (action.type) {
        case SHOW_NOTIFICATION:
            return previousState.concat(action.payload);
        case HIDE_NOTIFICATION:
        case UNDO:
            return previousState.slice(1);
        case RESET_NOTIFICATION:
            return initialState;
        default:
            return previousState;
    }
};

export { notificationReducer };
