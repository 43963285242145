import { Reducer } from "redux";
import { START_OPTIMISTIC_MODE, STOP_OPTIMISTIC_MODE } from "../../undo";
import { REFRESH_VIEW, SET_AUTO_REFRESH, SET_SIDEBAR_VISIBILITY, TOGGLE_SIDEBAR } from "../actions";
import { RefreshActionType } from "../RefreshActionType";
import { UiState } from "../UiState";

const defaultState: UiState = {
    autoRefreshEnabled: true,
    sidebarOpen: true,
    optimistic: true,
    viewVersion: 0,
};

// @ts-ignore
const refreshReducer: Reducer<UiState> = (previousState: UiState = defaultState, action: RefreshActionType) => {
    switch (action.type) {
        case REFRESH_VIEW:
            return { ...previousState, viewVersion: previousState.viewVersion + 1 };
        case SET_SIDEBAR_VISIBILITY:
            return { ...previousState, sidebarOpen: action.payload };
        case SET_AUTO_REFRESH:
            return { ...previousState, autoRefreshEnabled: action.payload };
        case START_OPTIMISTIC_MODE:
            return { ...previousState, optimistic: true };
        case STOP_OPTIMISTIC_MODE:
            return { ...previousState, optimistic: false };
        case TOGGLE_SIDEBAR:
            return { ...previousState, sidebarOpen: ! previousState.sidebarOpen };
        default:
            return previousState;
    }
};

export { refreshReducer };
