import React from "react";
import TelegramIcon from "@material-ui/icons/Telegram";
import { IconButton, IconButtonProps, Tooltip } from "@material-ui/core";
import { useTranslate } from "../../service/translation";

const TelegramIconButton = (props: IconButtonProps) => {
    const translate = useTranslate();

    return (
        <Tooltip title={translate("newshub.action.telegram")}>
            <IconButton
                aria-label={translate("newshub.action.telegram")}
                color="primary"
                size="small"
                {...props}
            >
                <TelegramIcon />
            </IconButton>
        </Tooltip>
    )
};

export default TelegramIconButton;
