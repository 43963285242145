import React, { FunctionComponent, useCallback } from "react";
import RefreshIcon from "@material-ui/icons/Refresh";
import { IconButton, IconButtonProps, Tooltip } from "@material-ui/core";
import { refreshView } from "../../service/refresh";
import { useDispatch } from "react-redux";
import { useTranslate } from "../../service/translation";

interface RefreshIconButtonProps {
    onClick?: (e: MouseEvent) => void;
}

const RefreshIconButton: FunctionComponent<RefreshIconButtonProps & IconButtonProps> = (props: RefreshIconButtonProps) => {
    const dispatch = useDispatch();
    const translate = useTranslate();
    const { onClick, ...rest } = props;

    const handleClick = useCallback(event => {
        event.preventDefault();
        dispatch(refreshView());

        if (typeof onClick === "function") {
            onClick(event);
        }
    }, [dispatch, onClick]);

    return (
        <Tooltip title={translate("newshub.action.refresh")}>
            <IconButton
                aria-label={translate("newshub.action.refresh")}
                color="inherit"
                onClick={handleClick}
                {...rest}
            >
                <RefreshIcon />
            </IconButton>
        </Tooltip>
    );
};

export default RefreshIconButton;
