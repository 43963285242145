import React, {FunctionComponent, useState} from "react";
import {Box, Button, Grid, makeStyles} from "@material-ui/core";
import { useTranslate } from "../../service/translation";
import {FiltersContext} from "../../service/filters/FiltersContext";
import {apiProvider,ApiParamsType,ContributorType} from "../../service/data/apiProvider";
import { filter } from "lodash";
import { index } from "d3-array";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";


const useStyles = makeStyles(theme => ({
    groupFilterContainer: {
        padding: theme.spacing(1,0,1),
        whiteSpace: "nowrap",
        flexWrap: "nowrap",
        maxWidth: "100%",
        overflowX: "auto",
        position: "relative",
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(2,0),
            justifyContent: "start",
        },
        "& button": {
            margin: theme.spacing(0,1),
            backgroundColor: theme.palette.type === "light" ? theme.palette.background.paper : theme.palette.background.paper,
            color: theme.palette.type === "light" ? "#555555" : theme.palette.secondary.dark,
            fontWeight: "normal",
            "&.active": {
                backgroundColor: theme.palette.type === "light" ? theme.palette.primary.main : theme.palette.primary.main,
                color: theme.palette.type === "light" ? "white" : "black",
            },
            "&:hover": {
                backgroundColor: theme.palette.type === "light" ? theme.palette.primary.main : theme.palette.primary.main,
                color: theme.palette.type === "light" ? "white" : "black",
            },
        },
    },
    leftArrow: {
        display: "none",
        position: "absolute",
        top: 0,
        left: 0,
        height: "100%",
        width: "90px",
        backgroundColor: "transparent !important",
        background:  theme.palette.type === "light" ? "linear-gradient(to right, #f6f8fa 0%,rgba(255,255,255,0) 100%) !important" : "linear-gradient(to right, #333 0%,rgba(255,255,255,0) 100%) !important",
        zIndex:1,
        margin: "0 !important",
        padding: "0 !important",
        minWidth: "0 !important",
        borderRadius: "0 !important",
        justifyContent: "flex-start",
        color: theme.palette.type === "light" ? "#ccc !important" : "#ccc !important",
        pointerEvents: "none",
        [theme.breakpoints.down("sm")]: {
            display: "flex",
        }
    },
    rightArrow: {
        display: "none",
        position: "absolute",
        top: 0,
        right: 0,
        height: "100%",
        width: "90px",
        backgroundColor: "transparent !important",
        background:  theme.palette.type === "light" ? "linear-gradient(to left, #f6f8fa 0%,rgba(255,255,255,0) 100%) !important" : "linear-gradient(to left, #333 0%,rgba(255,255,255,0) 100%) !important",
        zIndex:1,
        margin: "0 !important",
        padding: "0 !important",
        minWidth: "0 !important",
        borderRadius: "0 !important",
        justifyContent: "flex-end",
        color: theme.palette.type === "light" ? "#ccc !important" : "#ccc !important",
        pointerEvents: "none",
        [theme.breakpoints.down("sm")]: {
            display: "flex",
        }
    },

}), { name: "MepGroupFilter" });

const MepGroupFilter: FunctionComponent = () => {
    const classes = useStyles();
    const translate = useTranslate();
    const [filters,setFilters] = React.useContext(FiltersContext);
    const [eparties,setEparties] = useState<any>([]);

    const [isActive, setActive] = useState(false);


    const getEparties = () => {
        apiProvider.getPoliticalGroups()
        .then((response) => {
            response.unshift({
                eparty:'All',
                fullname:'All'
            });
            setEparties(response);

        })
        .catch((error) => {
            console.log('ERROR >>', error);
        });
    };

    const handleParty = (eparty:string) => {
        let eParty = filters.channels.eParty === eparty? 'All' : eparty;

        setFilters({
            ...filters,
            channels:{
                ...filters.channels,
                eParty: eParty,

            }
        })
    };

    React.useEffect(() => {
        getEparties();
    },[]);

    return (
        <>
            <Button className={classes.leftArrow}><ChevronLeftIcon /></Button>
            <Button className={classes.rightArrow}><ChevronRightIcon /></Button>
        {filters.channels.type === 'mep' &&
        <Grid container justifyContent="center" className={classes.groupFilterContainer}>

            <Box>
                {eparties.map ( (party:any,index:number) =>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={()=> {handleParty(party.eparty)}}
                        size="small"
                        className={( filters.channels.eParty === party.eparty )? 'active':''}
                    >
                        {translate(party.eparty)}
                    </Button>
                )}
            </Box>
        </Grid>
        }
        </>
    );
};

export default MepGroupFilter;
