import React, { FunctionComponent } from "react";
import ChannelsContent from "./ChannelsContent";
import HomeContent from "./HomeContent";
import MepContent from "./MepContent";
import NewshubContext from "./NewshubContext";
import NewshubUi from "./NewshubUi";
import TimelineContent from "./TimelineContent";
import { AnalyticsProvider } from "../../service/analytics";
import { DataProvider } from "../../service/data";
import { History } from "history";
import { InitialState } from "../../service/newshub";
import { I18nProvider } from "../../service/translation";
import { Route, Switch } from "react-router";
import { ThemeOptions } from "@material-ui/core";
import SearchContent from "./SearchContent";
import CookiesContent from "./CookiesContent";
import PrivacyContent from "./PrivacyContent";
import EmbedContent from "./EmbedContent";
import ErrorContent from "./ErrorContent";

interface NewshubProps {
    analyticsProvider: AnalyticsProvider;
    i18nProvider: I18nProvider;
    initialState?: InitialState;
    dataProvider: DataProvider;
    history?: History;
    theme?: ThemeOptions;



}

/**
 * Main app component, entry point to the application.
 *
 * Just a wrapper which initializes the several service contexts
 * (analytics, translation, data, redux, etc.) and defines the
 * main routes, to save you a few typings.
 *
 * @example
 * const App = () => (
 *     <Newshub dataProvider={myDataProvider} />
 * )
 */
const Newshub: FunctionComponent<NewshubProps> = ({
    analyticsProvider,
    i18nProvider,
    initialState,
    dataProvider,
    history,
    theme,
}) => {
    if (process.env.NODE_ENV === "production") {
        // Production checks go here
    }
    return (
        <NewshubContext
            analyticsProvider={analyticsProvider}
            i18nProvider={i18nProvider}
            initialState={initialState}
            dataProvider={dataProvider}
            history={history}
            theme={theme}


        >
            <NewshubUi>
                <Switch>
                    <Route exact path="/channels" component={ChannelsContent} />
                    <Route exact path="/embed/tw/:id" component={EmbedContent} />
                    <Route exact path="/contributors/:id" component={MepContent} />
                    <Route exact path="/cookies" component={CookiesContent} />
                    <Route exact path="/privacy" component={PrivacyContent} />
                    <Route exact path="/search" component={SearchContent} />
                    <Route exact path="/timeline" component={TimelineContent} />
                    <Route exact path="/tw/:id" component={EmbedContent} />
                    <Route exact path="/" component={HomeContent} />
                    <Route component={ErrorContent} />
                </Switch>
            </NewshubUi>
        </NewshubContext>
    );
};

export default Newshub;
