import { NotificationSideEffect } from "./NotificationSideEffect";
import { put, takeEvery } from "redux-saga/effects";
import { showNotification } from "../actions";

interface ActionWithSideEffect {
    type: string;
    payload?: any;
    error: string | { message: string };
    meta: {
        notification: NotificationSideEffect;
        translatable: boolean;
        optimistic?: boolean;
    };
}

function *handleNotification({ error, meta: { notification, optimistic, translatable = true }}: ActionWithSideEffect) {
    const { level, message, messageArgs = {}} = notification;

    if (error) {
        yield put(showNotification(
            level || "warning",
            typeof error === "string" ? error : error.message || message,
            { messageArgs, translatable, undoable: false }
        ));
    } else {
        yield put(showNotification(
            level || "info",
            message,
            { messageArgs, translatable, undoable: !! optimistic }
        ));
    }
}

const notificationSaga = function* () {
    // @ts-ignore
    yield takeEvery(action => action.meta && action.meta.notification, handleNotification);
};

export { notificationSaga };
