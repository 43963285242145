import React from 'react';
import { Newshub } from './component';

import noAnalyticsProvider from "./service/analytics/noAnalyticsProvider";
import polyglotProvider from "./service/translation/polyglotProvider";
import apiDataProvider from "./service/data/apiDataProvider";

const App = () => (
    <Newshub
        analyticsProvider={noAnalyticsProvider}
        i18nProvider={polyglotProvider()}
        dataProvider={apiDataProvider}
    />
);

export default App;
