import { combineReducers } from "redux";
import { dataFetchReducer } from "../../data";
import { fetchReducer } from "../../fetch";
import { notificationReducer } from "../../notification";
import { refreshReducer } from "../../refresh";

const defaultReducer = () => null;

const newshubReducer = combineReducers({
    loading: fetchReducer || defaultReducer,
    notifications: notificationReducer || defaultReducer,
    refresh: refreshReducer || defaultReducer,
    resources: dataFetchReducer || defaultReducer,
});

export { newshubReducer }
