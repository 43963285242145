export function roundCurrentTime(minutes:number = 1){
    //during 'minutes' the time is the same ---> to implement efective cache 
    let now = Math.floor(Date.now()/1000);
    return now - (((now / 60) % minutes)* 60);
}

export function getNextHour():number{
    let now = new Date();
    let nextHours = (now.getUTCHours() + 1) % 24;
    let nextHourTime:number =  now.setUTCHours(nextHours,0,0);

    //if nextHours == 0 ; next day
    if(nextHours === 0) nextHourTime += 3600000 * 24 ; 
    return nextHourTime;
 
}

export function getDayStart(seconds?:number){
    let date = (seconds)? new Date(seconds * 1000) :new Date();
    return Math.floor(date.setHours(0,0,0)/1000);
}

export function getDayEnd(seconds?:number){
    let date = (seconds)? new Date(seconds * 1000) :new Date();
    return Math.floor(date.setHours(23,59,59,999)/1000);
}


export function stringToTime(date:string|number):any {
    date = ''+date;
    if(date.length === 8) {
        date = date + '00';
    }
    return Date.UTC( parseInt(date.substr(0,4)),parseInt(date.substr(4,2))-1,parseInt(date.substr(6,2)),parseInt(date.substr(8,2)),0);
}


export function addZero(i:number):string {
    let cadena:string = (i < 10)? "0" + i : ''+i;
    return cadena;
  }