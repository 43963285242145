import React from "react";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import TwitterIcon from "@material-ui/icons/Twitter";
import { SocialNetwork } from "../../service/data";
import XIconSVG from "./XIconSVG";

const getSocialIcon = (source: SocialNetwork) => {
    switch (source) {
        case "facebook":
            return (<FacebookIcon />);
        case "instagram":
            return (<InstagramIcon />);
        case "twitter":
            return (<XIconSVG />);
    }

    const error = new Error();
    error.name = "UnknownSocialNetworkError";
    error.message = `
        Unknown social network "${source}". Available social networks
        are "facebook", "instagram", and "twitter"
    `;

    throw error;
};

export {
    getSocialIcon,
}
