import React, { FunctionComponent } from "react";
import MenuIcon from "@material-ui/icons/Menu";
import classNames from "classnames";
import {IconButton, IconButtonProps, Tooltip, makeStyles, Box} from "@material-ui/core";
import { Link } from "react-router-dom";
import { useTranslate } from "../../service/translation";

interface MenuIconButtonProps extends IconButtonProps {
    dense?: boolean;
    to?: string;
}

const useStyles = makeStyles(theme => ({
    root: {
        // Match <TopBar /> height (min-height: 64px)
        padding: theme.spacing(1, 0.5),
    },
    dense: {
        // Match <TopBar variant="dense" /> height (48px)
        padding: theme.spacing(0, 0.5),
    },
    button: {
        width: theme.spacing(6),
    },
}), { name: "NewshubSwitchThemeIconButton" });

const MenuIconButton: FunctionComponent<MenuIconButtonProps> = (props: MenuIconButtonProps) => {
    const classes = useStyles();
    const translate = useTranslate();
    const { dense, to, ...rest } = props;

    return (
        <Box className={classNames(classes.root, { [classes.dense]: dense, })}>
            <IconButton aria-label={translate("newshub.action.toggleMenu")} className={classes.button} component={to ? Link : "button"} to={to} {...rest}>
                <Tooltip placement="right" title={translate("newshub.action.toggleMenu")}>
                    <MenuIcon />
                </Tooltip>
            </IconButton>
        </Box>
    )
};

MenuIconButton.defaultProps = {
    dense: false,
};

export default MenuIconButton;
