import classNames from "classnames";
import React, { FunctionComponent } from "react";
import { Avatar, AvatarProps, makeStyles } from "@material-ui/core";
import { Mep } from "../../service/data";

type MepAvatarSize =
    | "small"
    | "medium"
    | "large";

interface MepAvatarProps extends AvatarProps {
    marginLeft?: number;
    marginRight?: number;
    record: Mep;
    size?: MepAvatarSize;
}

const useStyles = makeStyles(theme => ({
    avatar: {
    },
    small: {
        width: theme.spacing(4),
        height: theme.spacing(4),
    },
    medium: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    large: {
        width: theme.spacing(19),
        height: theme.spacing(19),
    },
}));

const MepAvatar: FunctionComponent<MepAvatarProps> = (props: MepAvatarProps) => {
    const classes = useStyles();
    const { marginLeft, marginRight, record, size, ...rest } = props;
    const { avatar, firstName, lastName } = record;

    // Provide text as a fallback
    return (
        <Avatar
            className={classNames(classes.avatar, {
                [classes.small]: size === "small",
                [classes.medium]: size === "medium",
                [classes.large]: size === "large",
            })}
            src={avatar}
            alt={lastName}
            style={{
                marginLeft,
                marginRight,
            }}
            {...rest}
        >
            {firstName.charAt(0).toUpperCase()}
            {lastName.charAt(0).toUpperCase()}
        </Avatar>
    );
};

MepAvatar.defaultProps = {
    marginLeft: 0,
    marginRight: 0,
    size: "medium",
};

export default MepAvatar;
