export const SET_AUTO_REFRESH = "EP/SET_AUTO_REFRESH";

export interface SetAutoRefreshAction {
    readonly type: typeof SET_AUTO_REFRESH;
    readonly payload: boolean;
}

export const setAutoRefresh = (enabled: boolean): SetAutoRefreshAction => ({
    type: SET_AUTO_REFRESH,
    payload: enabled,
});
