import ChannelsContent from "./ChannelsContent";
import ErrorContent from "./ErrorContent";
import ErrorContent503 from "./ErrorContent503";
import FilterForm from "./FilterForm";
import Layout from "./Layout";
import LoadingContent from "./LoadingContent";
import LoadingIndicator from "./LoadingIndicator";
import LoadingPage from "./LoadingPage";
import Menu from "./Menu";
import Newshub from "./Newshub";
import NewshubContext from "./NewshubContext";
import NewshubUi from "./NewshubUi";
import NotFoundContent from "./NotFoundContent";
import NotFoundContentTimeline from "./NotFoundContentTimeline";
import NotFoundContentChannels from "./NotFoundContentChannels";
import NotFoundContentHome from "./NotFoundContentHome";
import Notification from "./Notification";
import TopBar from "./TopBar";

// In theme.spacing()-compatible units
export const MENU_WIDTH_OPEN = 30;
export const MENU_WIDTH_CLOSED = 7;
export const FILTER_WIDTH = 37;

export {
    ChannelsContent,
    ErrorContent,
    ErrorContent503,
    FilterForm,
    Layout,
    LoadingContent,
    LoadingIndicator,
    LoadingPage,
    Menu,
    Newshub,
    NewshubContext,
    NewshubUi,
    NotFoundContent,
    NotFoundContentHome,
    NotFoundContentTimeline,
    NotFoundContentChannels,
    Notification,
    TopBar,
}
