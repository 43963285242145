import React from "react";
import InstagramIcon from "@material-ui/icons/Instagram";
import { IconButton, IconButtonProps, Tooltip } from "@material-ui/core";
import { useTranslate } from "../../service/translation";

const InstagramIconButton = (props: IconButtonProps) => {
    const translate = useTranslate();

    return (
        <Tooltip title={translate("newshub.action.instagram")}>
            <IconButton
                aria-label={translate("newshub.action.instagram")}
                color="primary"
                size="small"
                {...props}
            >
                <InstagramIcon />
            </IconButton>
        </Tooltip>
    )
};

export default InstagramIconButton;
