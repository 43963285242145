import React from 'react';
import { createStyles, makeStyles, useTheme, Theme } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import {Button, InputLabel, Typography} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import _without from "lodash/without";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            width: "calc(100% - 30px)",
            paddingLeft: '30px'
        },
        multipleSelect: {
            width: '100%',
            backgroundColor: theme.palette.type === "light" ? theme.palette.background.default : theme.palette.background.default,
            marginTop: '0 !important',
            "&:before, &:after": {
                display: 'none !important',
            },
            "&>div": {
                width: '100%',
                borderRadius: '20px !important',
                minHeight: '36px',
                backgroundColor: theme.palette.type === "light" ? theme.palette.background.default : theme.palette.background.default,
                border: '1px solid #555',
                padding: '1px',
                "&:focus": {
                    backgroundColor: 'transparent',
                },
            },
            [theme.breakpoints.down("sm")]: {
                backgroundColor: theme.palette.type === "light" ? theme.palette.background.paper : theme.palette.background.paper,
                "&>div": {
                    backgroundColor: theme.palette.type === "light" ? theme.palette.background.paper : theme.palette.background.paper,
                },
            },
        },
        clearAll: {
            position: 'absolute',
            bottom: '100%',
            right: 0,
        },
        chips: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        chip: {
            margin: 2,
        },
        noLabel: {
            marginTop: theme.spacing(3),
        },
        '@global': {
            ".MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated": {
                zIndex: 1,
                left: "48px !important",
                top: "50% !important",
                transform: "translateY(-50%) !important",
                color: theme.palette.type === "light" ? theme.palette.text.hint : theme.palette.text.hint,
                pointerEvents: "none",
            },
            ".MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.Mui-focused":{
                opacity: 1,
            },
            ".MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiFormLabel-filled":{
                opacity: 0,
            },
        },
    }),
);



function getStyles(country: string, optionValue: string[], theme: Theme) {
    return {
        fontWeight:
            optionValue.indexOf(country) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}


interface iSelectOption {
    key: string;
    name: string;
}

interface iMultipleSelectProps {
    options: iSelectOption[];
    defaultValue?: string[];
    handleChange?: (value:string[]) => void
    clearHandler?: () => void
    ariaLabel?:string;

}


export default function MultipleSelect(props: iMultipleSelectProps) {
    const classes = useStyles();
    const theme = useTheme();

    const [optionValue, setOptionValue] = React.useState<string[]>( (typeof props.defaultValue === "object" )? props.defaultValue as string[]: []);

    const updateOptionValue = ( value:string[]) => {
        setOptionValue(value);
        if(typeof props.handleChange === 'function') props.handleChange(value);
    };

    const clear = () => {
        if(typeof props.clearHandler === 'function') props.clearHandler();
    }


    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        updateOptionValue(event.target.value as string[]);
    };

   /* const handleChangeMultiple = (event: React.ChangeEvent<{ value: unknown }>) => {
        const { options } = event.target as HTMLSelectElement;
        const value: string[] = [];
        for (let i = 0, l = options.length; i < l; i += 1) {
            if (options[i].selected) {
                value.push(options[i].value);
            }
        }
        updateOptionValue(value);
        setCountryName(value);
    };*/

    const handleDelete = (e: React.MouseEvent, value: string) => {
        e.preventDefault();
        //setOptionValue((current) => _without(current, value));
        updateOptionValue(optionValue.filter(o => o !== value));

    };

    return (
        <div>
            <FormControl hiddenLabel={true}  className={classes.formControl}>
                <Button
                    color="primary"
                    size="small"
                    className={classes.clearAll}
                    onClick= {clear}
                >
                    <Typography variant="body2" color="primary" component="span">Clear</Typography>
                </Button>
                <InputLabel>Select an option</InputLabel>
                <Select
                    aria-label={props.ariaLabel || ''}
                    className={classes.multipleSelect}
                    multiple
                    MenuProps={{
                        style:{
                            maxHeight: 400,
                            marginTop: 40,
                        },
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "left"
                        },
                        transformOrigin: {
                            vertical: "top",
                            horizontal: "left"
                        },
                        getContentAnchorEl: null,
                    }}
                    value={optionValue}
                    onChange={handleChange}
                    input={<Input/>}
                    renderValue={(selected) => (
                        <div className={classes.chips}>
                            {(selected as string[]).map((value) => (
                                <Chip
                                    key={value}
                                    label={value}
                                    clickable
                                    color="primary"
                                    deleteIcon={
                                        <CloseIcon
                                            onMouseDown={(event) => event.stopPropagation()}
                                        />
                                    }
                                    className={classes.chip}
                                    onDelete={(e) => handleDelete(e, value)}
                                    onClick={() => console.log("clicked chip")}
                                />
                            ))}
                        </div>
                    )}
                >
                    {props.options.map((option) => (
                        <MenuItem key={option.key} value={option.key} style={getStyles(option.name, optionValue, theme)}>
                            {option.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
}
