import Autolinker from 'autolinker';
import {countries} from '../../config/countries';
import {addZero, getDayEnd, getDayStart, getNextHour, stringToTime} from '../tools/Tools';

interface CallbackOneParam<T1, T2 = void> {
  (param1: T1): T2;
}

export type ApiParamsType = {
    [key: string]: string | number ;
}

export type ApiSuccessResponse = {
    code : number
    data: []
}


export type ContributorType = {
    type:string;
    name:string;
}

export const allowedContributorTypes = ['eppress', 'mep', 'president', 'committee', 'grouppress'];


const virality: string[] = [
    "low",
    "medium",
    "high",
    "trending",
];

const ePartyOrder = [
    'EPP',
    'S&D',
    'Renew',
    'Greens/EFA',
    'ID',
    'ECR',
    'The Left',
    'GUE/NGL',
    'NA',
    'NI'

];



var politicalGroupsIds:any = [];

function getEpartyId(eparty:string){
    let party:any[] = politicalGroupsIds.filter((p:any,i:number) =>  p[0] === eparty);
    if(party.length < 1) return 0;
    return party[0][1];
}

function timeToHoursMinutes(time:number):string{
    let num = time/60;
    let hours = (num / 60);
    let rhours = Math.floor(hours);
    let minutes = (hours - rhours) * 60;
    let rminutes = Math.round(minutes);
    let text = '';
    if(rhours > 0 ) text +=  rhours + "h ";
    if(rminutes > 0 ) text +=  rminutes + "m ";
    if (text.length === 0) text = '1m ';
    return text + 'ago';
}

function parseDay(date:number){
    let dateS = ''+date;
    let year = dateS.substr(0,4);
    let month = dateS.substr(4,2);
    let day = dateS.substr(6,2);
    return `${day}/${month}`;
}

function getBrowserLanguage(){
    // @ts-ignore
    let lang =  navigator.language || navigator.userLanguage || 'en';
    if(lang.indexOf('-') !== -1 ) lang = (lang.split('-')[0]).toLowerCase();
    return lang;
}

function timeToDate(time:number):string{
    let currentDate = new Date(time*1000);
    let cDay = currentDate.getUTCDate();
    let cMonth = currentDate.getUTCMonth() + 1;
    let cYear = currentDate.getUTCFullYear();
    return `${cDay}/${cMonth}/${cYear}`;
}


function excludedContributors(){
    let excluded:string[] = [];
    if(typeof process.env.REACT_APP_EXCLUDE_CONTRIBUTORS === 'string' && process.env.REACT_APP_EXCLUDE_CONTRIBUTORS.length > 0){
        excluded = process.env.REACT_APP_EXCLUDE_CONTRIBUTORS.replace(' ','').split(',');
    }
    console.log('Excluded contributors',excluded,process.env);
    return excluded;

}


export function timeAgo(time:number):string{
    const h24 = 3600 * 24 ;
    let ago:number = (Date.now()/1000)-time;
    if(ago < h24) return timeToHoursMinutes(ago);
    return timeToDate(time);
}


function getCountryName(key:string){
    for(let item of countries){
        if(item.key === key) return item.name;
    }
    return '';
}

 function parseContributor(item:any){
    if(!item) return null;
    let politicalGroup:any = {
        name: '',
        party: '',
        eparty :'',
        picture: ''
    };

    let socialNetworks:any =  {};

    if(item.politicalGroup) {


        politicalGroup = {
            name: '',
            eparty:  item.politicalGroup.eparty,
            party: item.politicalGroup.party,
            localParty:item.politicalGroup.localParty,
            picture: item.eParty,
            id: getEpartyId(item.politicalGroup.eparty)
        };
    }

    if(item.socialNetworks){
        for(let i in item.socialNetworks){
            let type = item.socialNetworks[i].type;
            socialNetworks[type] = {...item.socialNetworks[i]};
        }
    }

    return  {
        id: item.id,
        avatar: item.pictureLink,
        country:  getCountryName(item.country),
        firstName: item.firstName || '',
        lastName: item.lastName || '',
        politicalGroup:politicalGroup,
        twitter: item.sourceUrl,
        socialNetworks:socialNetworks,
        type: item.type.type || null

    }
}


function parseImage(img:string):string{
    if(img.indexOf('http') !== -1) return img;
    return process.env.REACT_APP_APIURL +'/images/'+ encodeURIComponent (img) ;
}

function parseSocialItem(item:any){
    const linkerConf:any = {
        newWindow: true,
        hashtag: 'twitter',
        mention:'twitter'
    };
    var mediaUrl = null;
    if(item.media){
        switch(item.media.type){
            case 'photo':
                if(item.media.src){
                    mediaUrl ={
                        src:  parseImage(item.media.src),
                        type: 'photo'
                    };
                }
                break;
            case 'video':
                if(item.media.previewImageUrl){
                    mediaUrl ={
                        src:  parseImage(item.media.previewImageUrl),
                        type: 'video'
                    };
                }
                break;
        }
    }

    return {
        id: item.id,
        content: Autolinker.link(item.content,linkerConf),
        media:mediaUrl,
        mep: parseContributor(item.contributor),
        sourceUrl: item.shareUrl,
        source: "twitter",
        postedAt: new Date(item.postedAt * 1000),
        timeAgo: timeAgo(item.postedAt),
    }
}





class ApiProvider {
    private _baseUrl: string;
    private _requestUiids:ApiParamsType = {};

    constructor(config:any){
        this._baseUrl = config.baseUrl || '';
    }

    private createError(code:number = 500, message:string = 'unkown_error'){
        return {code:code, message:message};
    }

    private paramsToQuery(params:ApiParamsType):string{
        return Object.keys(params).map(function(prop) {
            return [prop, encodeURIComponent(  decodeURIComponent(''+params[prop]) )].join("=");
          }).join("&");
    }

    private sanitizeUrl(url:string){
        let parts = url.split('?');
        if(typeof parts[1] !== 'undefined'){
            let params = new URLSearchParams(parts[1]);
            let params_array:ApiParamsType = {};
            //@ts-ignore
            for (let value of params.keys()) {
              params_array[value] = ''+params.get(value);
            }
            parts[1] = this.paramsToQuery(params_array);
        }
        url = parts.join('?');
        console.log('sanitized url',url );
        return url.replace('http:','https:');

    }

    private postFeedback(url:string,params:any, resolve:CallbackOneParam<any>, reject:CallbackOneParam<any>)
    {
        const options = {
            method: 'POST',
            body: JSON.stringify(params),
            headers: {
                'Content-Type': 'application/json'
            }
        };
        url = url.replace('http:','https:');
        fetch(url, options)
        .then((respose) => respose.json())
        .then((json)=> {

            if(json.type !== 'success') reject(this.createError(json.code, json.data));
            resolve(json.data);
        })
        .catch((error) => {
            reject(this.createError(500 , error.message));
        });
    }

    private  getRequest(url:string,params:ApiParamsType|null, resolve:CallbackOneParam<any>, reject:CallbackOneParam<any>, uuid:string = ''){
        let paramsString = (params!== null)? '?'+ this.paramsToQuery(params): '';
        url = this.sanitizeUrl(url) +  paramsString;
        console.log('API REQUEST: ' + url );

        fetch(url)
            .then((respose) => respose.json())
            .then((json)=> {

                if(json.type !== 'success') reject(this.createError(json.code, json.data));
                resolve({data:json.data,uuid:uuid});
            })
            .catch((error) => {
                reject({error:this.createError(500 , error.message),uuid:error});
            });
    }

    public getEpartiesIds(){
        var request = new XMLHttpRequest();
        request.open('GET', this._baseUrl+ '/contributor/?pageNo=0&type=grouppress', false);  // `false` makes the request synchronous
        request.send(null);

        if (request.status !== 200) return [];
        let response = JSON.parse(request.responseText);
        return response.data.items;
    }

    //params can be a url
    public getSocialItems(params:ApiParamsType|string, providerId?:number){
        let queryParams:ApiParamsType|null;
        let url:string;
        if(typeof(params) === 'string'){
            url = params;
            queryParams =  null;
        }else {
            url = this._baseUrl+'/social-item/';
            //if(typeof providerId !== 'undefined') url += `${providerId}/`;
            queryParams = params;
            if(typeof providerId !== 'undefined'){
                queryParams['contributors_ids'] = providerId;
            }

            //exclude contributors
            let exclude = excludedContributors();
            if(exclude.length > 0){
                queryParams['contributors_exclues'] = exclude.join(',');
            }

        }

        let uuid = Date.now()+'_'+Math.floor(Math.random() * 100);
        this._requestUiids['socialiTems'] = uuid;

        return new Promise<any> ((resolve, reject) => {
            this.getRequest(url,queryParams,
                (response) => {
                    let uuid = response.uuid;
                    if(uuid !== this._requestUiids['socialiTems']) return;

                    let data = response.data;
                    let items:any = [];
                    for(let i in data.items ) {
                        let item:any= parseSocialItem(data.items[i]);
                        items.push(item);
                    }

                    resolve({items:items, next:data.next,total:data.total});
                },
                (response) => {
                    let uuid = response.uuid;
                    if(uuid !== this._requestUiids['socialiTems']) return;

                    let error = response.error;
                    reject(error);
                },
                uuid
            );
        });
    }


    getTopics (params:ApiParamsType,providerId?:number){

        const url = (typeof providerId !== 'undefined' )?  this._baseUrl+`/topic/${providerId}/` : this._baseUrl+'/topic/';

        if(typeof providerId === 'undefined' ){
            params['contributorType'] = allowedContributorTypes.join(',');
        }

        return new Promise<any> ((resolve, reject) => {
            this.getRequest(url,params,
                (response) => {
                    let data = response.data;
                    let topics:any = []
                    let maxValue = 0;
                    for(let i in data){
                        let value =  parseInt(data[i].virality);
                        if(value > maxValue) maxValue = value;
                        topics.push({
                            id:data[i].content,
                            content: data[i].content,
                            value: value -1, //fix value 4 in index operation .
                            virality:''
                        });

                    }
                    for(let i in topics){
                        let viralityIndex = Math.floor(topics[i].value *100 / (maxValue * 25));
                        topics[i].virality = virality[viralityIndex];
                    }

                    resolve(topics);
                },

                (response) => {
                    let error = response.error;
                    reject(error);
                }
            );
        });
    }

    getPoliticalGroups(){
        const url = this._baseUrl+'/type/eparty/';
        return new Promise<any> ((resolve, reject) => {
            this.getRequest(url,null,
                (response) => {
                    let data = response.data;
                    let filtered = data.filter((party:any, index:number, arr:any) => {
                        return party.fullName != null;
                    });
                    let ordered = filtered.sort((x:any,y:any) => {
                        let xIndex = ePartyOrder.indexOf(x.eparty);
                        let yIndex = ePartyOrder.indexOf(y.eparty);
                        if( xIndex > yIndex ) return 1
                        if (xIndex <  yIndex) return -1
                        return 0;
                    });
                  resolve(ordered);
                },

                (response) => {
                    let error = response.error;
                    reject(error);
                }
            );
        });
    }

    getContributorTypes(){
        const url = this._baseUrl+'/type/contributor/';
        return new Promise<any> ((resolve, reject) => {
            const contributors:any = [
                {
                    type: 'all',
                    original: 'All',
                    name: 'All',
                },
                {
                    type: 'president',
                    original: 'President',
                    name: 'President'
                },

                {
                    type: 'mep',
                    original: 'mep',
                    name: 'MEPs'
                },

                {
                    type: 'grouppress',
                    original: 'grouppress',
                    name: 'Political Groups'
                },
                {
                    type: 'committee',
                    original: 'committee',
                    name: 'Committees'
                },
                {
                    type: 'eppress',
                    original: 'eppress',
                    name: 'EP Accounts'
                },

            ];

            resolve(contributors);
        });
    }

    getContributor(id:string){

        let exclude = excludedContributors();
        if(exclude.indexOf(''+id) !== -1 ) {
           id = "0";
        }

        const url = this._baseUrl+'/contributor/'+ id +'/';
        return new Promise<any> ((resolve, reject) => {
            this.getRequest(url,null,
                (response) => {
                    let data = response.data;
                    resolve(parseContributor(data));
                },

                (response) => {
                    let error = response.error;
                    reject(error);
                }
            );
        });

    }

    getContributors(params:ApiParamsType|string){
        let url:string;
        let queryParams:ApiParamsType|null;
        if(typeof(params) === 'string'){
            url = params;
            queryParams =  null;
        }else {
            url = this._baseUrl+'/contributor/';
            queryParams = params;
        }

        let exclude = excludedContributors();
        return new Promise<any> ((resolve, reject) => {
            this.getRequest(url,queryParams,
                (response) => {
                    let data  = response.data;
                    let total = data.total;
                    let items:any = [];
                    if(data.items !== null) {
                        const filteredItems = data.items;
                        for(let i in filteredItems){
                            if(exclude.indexOf(''+filteredItems[i].id) !== -1 ){
                                total--;
                                continue;
                            }
                            items.push(parseContributor(filteredItems[i]));
                        }
                    }

                //@ts-ignore
                if (queryParams && typeof queryParams['type'] !== 'undefined' && queryParams['type']=== 'grouppress'){
                    items = items.sort((x:any,y:any) => {
                        let xIndex = ePartyOrder.indexOf(x.politicalGroup.eparty);
                        let yIndex = ePartyOrder.indexOf(y.politicalGroup.eparty);
                        if( xIndex > yIndex ) return 1
                        if (xIndex <  yIndex) return -1
                        return 0;
                    });

                }
                resolve({contributors: items, next:data.next, total:total});
                },

                (response) => {
                    let error = response.error;
                    console.log('ERROR',error);
                    reject(error);
                }
            );
        });
    }

    getTranslation(itemId:string){
        const url = this._baseUrl+'/translate/'+itemId+'/'+ getBrowserLanguage() +'/';
        return new Promise<any> ((resolve, reject) => {
            this.getRequest(url,null,
                (response) => {
                    let data = response.data;
                    resolve(data);
                },
                (response) => {
                    let error = response.error;
                    reject(error);
                }
            );
        });
    }

    getSuggestion(text:string){
        const url = this._baseUrl+'/tip/'+text+'/';
        return new Promise<any> ((resolve, reject) => {
            this.getRequest(url,null,
                (response) => {
                    let data = response.data;
                    resolve(data);
                },
                (response) => {
                    let error = response.error;
                    reject(error);
                }
            );
        });
    }

    getTimeline(key:string){
        let to:number;
        let from:number;
            switch(key){
            case 'day':
                return this.getTimeLine24h();

            case 'week':
                to = getDayEnd();
                from = getDayStart(to - ( 3600 * 24 * 7));
                return this.getTimeLineFromTo(from, to);

            case 'month':
                to = getDayEnd();
                from = getDayStart(to - ( 3600 * 24 * 30));
                return this.getTimeLineFromTo(from, to);

            default:
                return new Promise<any> ((resolve, reject) => {
                    resolve([]);
                });
        }

    }

    getTimeLineFromTo (from:number, to:number){
        const url = this._baseUrl+'/time-line/';
        let queryParams:ApiParamsType = {
            from:from,
            to:to
        }


        return new Promise<any> ((resolve, reject) => {
            this.getRequest(url,queryParams,
                (response) => {
                    let data = response.data;
                    let timeline:any[] = [];
                    let lastTime:number = 0;
                    let total = 0;
                    for(let i= data.length-2; i >= 0; i--){
                        total += data[i].total;
                        lastTime = stringToTime(data[i].day);
                        timeline.push({
                            x: parseDay(data[i].day),
                            y : data[i].total,
                            time:lastTime
                        });
                    }

                    //add a day more:
                    lastTime = lastTime + 3600000 * 24;
                    let lastDay = new Date(lastTime);
                    timeline.push({
                        x: addZero(lastDay.getDate())+'/'+ addZero(lastDay.getMonth()+1),
                        y : 0,
                        time:lastTime

                    });

                    resolve({timeline:timeline, from:from, to:to,total:total});
                },
                (response) => {
                    let error = response.error;
                    reject(error);
                }
            );
        });

    }

    getTimeLine24h() {
        let url = this._baseUrl+'/time-line/l24h/';
        let expected:number[] = [];
        let now = getNextHour();
        //all hours expected
        for(let i = 0; i <= 23; i++ ){
            let current =  new Date(now - (i * 3600000));
            let hour:string = addZero(current.getUTCHours());
            let day:string = addZero(current.getUTCDate());
            let month:string = addZero(current.getUTCMonth()+1);
            let year:string = addZero(current.getUTCFullYear());
             expected.unshift(parseInt( year+month+day+hour) );
        }

        return new Promise<any> ((resolve, reject) => {
            this.getRequest(url,null,
            (response) => {
                let data = response.data;
                // complete hous without data
                let dataCompleted:any = expected.map((date:number,i:number) => {
                    let r = data.filter( (item:any,i:number) => {
                        return item.day === date;
                    });
                    return (r.length > 0 )? r[0] : {day:date, total:0};
                });

                let timeline:any[] = [];
                let total = 0;
                let from:number = 0;

                timeline = dataCompleted.map( (item:any,i:number) => {
                    let time = stringToTime(item.day);
                    if(i === 0) from = getDayStart(Math.floor(time/1000));

                    let date = new Date(time);
                    let x = addZero(date.getHours())+':00'; //local Hour
                    let y = item.total;
                    total += item.total;
                    return {x:x,y:y,time:time};

                });
                resolve({timeline:timeline, from:from, to:getDayEnd(),total:total});

            },
            (response) => {
                let error = response.error;
                reject(error);
            });
        });
    }

    sendFeedBack(params:any){
        let url = this._baseUrl+'/feedback/send/';
        return new Promise<any> ((resolve, reject) => {
            this.postFeedback(url,params,
                (data) => {
                    resolve(data);
                },
                (error) => {
                    reject(error);
                }
            );
        });

    }

}


const apiProvider = new ApiProvider({baseUrl:process.env.REACT_APP_APIURL});
politicalGroupsIds = (apiProvider.getEpartiesIds()).map((item:any,i:number) => [item.politicalGroup.eparty,item.id ]);

export {apiProvider};

