import React, { FunctionComponent,ChangeEvent } from "react";
import SearchIcon from "@material-ui/icons/Search";
import {InputBase, makeStyles, Box, Typography, Divider, Grid, Chip, Menu, MenuItem, MenuList} from "@material-ui/core";
import { useTranslate } from "../../service/translation";
import {MepCardList} from "../mep";
import {ViewAllButton} from "../button";
import { Link } from "react-router-dom";
import {apiProvider,ApiParamsType} from "../../service/data/apiProvider";
import CloseIcon from "@material-ui/icons/Close";
import { Form } from "react-final-form";
import { FiltersContext,appDefaultFilters } from "../../service/filters";
import MepCardListSearchBar from "../mep/MepCardListSearchBar";



const useStyles = makeStyles(theme => ({
    search: {
        height: 40,
        borderRadius: 30,
        position: "relative",
        maxWidth: 480,
        width: "100%",
        margin: "0 auto",
        [theme.breakpoints.down("sm")]: {
            margin: 0,
            /*maxWidth: 'calc(100% - 40px)',*/
            borderBottom: theme.palette.type === "light" ? "1px solid #CCC" :  "1px solid " + theme.palette.text.hint,
            borderRadius: 0,
        },
    },
    searchBox: {
        color: "inherit",
        height: "100%",
        width: "100%",
        "&.active":{
            borderRadius: "30px 30px 0 0",
            backgroundColor: theme.palette.type === "light" ? theme.palette.background.paper : theme.palette.background.paper,
            boxShadow: theme.palette.type === "light" ? "0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)" : 'inset 0px 2px 3px #111',
        },
    },
    searchDivider: {
        backgroundColor: theme.palette.type === "light" ? "#CCC" : theme.palette.secondary.dark,
        margin: theme.spacing(0, 2),
    },
    searchIcon: {
        alignItems: "center",
        display: "flex",
        height: "100%",
        justifyContent: "center",
        padding: theme.spacing(0, 2),
        position: "absolute",
        pointerEvents: "none",
        color: theme.palette.primary.main,
    },
    searchInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create("width"),
        maxWidth: 480,
        width: "100%",
    },
    searchModuleContainer: {
        padding: theme.spacing(2),
        "& p": {
            fontSize: "16px !important",
        },
    },
    suggestedWordsContainer: {
        padding: theme.spacing(1, 0),
        "& p": {
            marginTop: theme.spacing(1),
        },
        "& .MuiChip-clickable":{
            width: "100%",
            marginBottom: theme.spacing(1),
            backgroundColor: theme.palette.type === "light" ? theme.palette.background.paper : theme.palette.background.paper,
            color: theme.palette.type === "light" ? theme.palette.text.hint : theme.palette.text.hint,
            fontSize: "16px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            "&:active":{
                boxShadow: "none",
            },
            "& .MuiChip-label":{
                paddingLeft: 0,
                paddingRight: 0,
            },
            "& svg":{
                margin: 0,
            },
            "& .MuiTouchRipple-root":{
                display: "none",
            },
        },
    },
    searchModuleChip:{
        "&:hover":{
            "& *":{
                color: theme.palette.type === "light" ? theme.palette.primary.dark : theme.palette.primary.dark,
            },
        },
    },
    searchModuleMenuList:{
        padding: 0,
        "& > li":{
            width: "100%",
            marginTop: theme.spacing(1),
            backgroundColor: theme.palette.type === "light" ? theme.palette.background.paper : theme.palette.background.paper,
            color: theme.palette.type === "light" ? theme.palette.text.hint : theme.palette.text.hint,
            fontSize: "16px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            "&:active":{
                boxShadow: "none",
            },
            "& .MuiChip-label":{
                paddingLeft: 0,
                paddingRight: 0,
            },
            "& svg":{
                margin: 0,
            },
            "& .MuiTouchRipple-root":{
                display: "none",
            },
            "&:hover":{
                color: theme.palette.type === "light" ? theme.palette.primary.main : theme.palette.primary.dark,
                backgroundColor: "transparent",
            },
            "&:focus-visible": {
                backgroundColor: "transparent !important",
            },
            "&:-moz-focusring": {
                backgroundColor: "transparent !important",
            },

        },
    },
    searchModuleMenuItem:{
        padding: 0,
    },
    mepListContainer: {
        "& .MuiGrid-root": {
            display: "block",
            "& .gridItem": {
                maxWidth: "100%",
                flexBasis: "100%",
                padding: 0,
            },
            "& .MuiCard-root": {
                boxShadow: "none",
                border: 0,
                "& .MuiCardHeader-root": {
                    padding: theme.spacing(1, 0),
                    "& .MuiTypography-h2":{
                        color: theme.palette.type === "light" ? theme.palette.text.primary : theme.palette.text.primary,
                    },
                    "&:hover":{
                        "& *":{
                            color: theme.palette.type === "light" ? theme.palette.primary.dark : theme.palette.primary.dark,
                        },
                        "& .MuiTypography-h2":{
                            color: theme.palette.type === "light" ? theme.palette.primary.main : theme.palette.primary.main,
                        },
                    },
                },
                "& .MuiCardHeader-action": {
                    display: "none"
                },

            },
        }
    },
}));


interface SearchModuleProps {
    onSubmit?: () => void
}


const SearchModule :FunctionComponent<SearchModuleProps> = (props: SearchModuleProps) => {
    const classes = useStyles();
    const {onSubmit} = props;
    const translate = useTranslate();
    const handleViewAllChannels = () => {};
    const [suggestionWords, setSuggestionWords] = React.useState<any[]>([]);
    const [suggestionnChannels, setSuggestionChannels] = React.useState<any[]>([]);
    const [filters,setFilters] = React.useContext(FiltersContext);
    const [inputValue,setInputValue] = React.useState('');
    const isSuggestion = React.useRef(false);
    const [hasTopSearchBar,setHasTopSearchBar] = React.useState(false);

    var timer:any = React.useRef(false);
    var channels = React.useRef<any[]>([]);


    const setSuggestions = (values:any) => {
        setSuggestionWords(values.filter( (item:any,i:number) => item.type === 'words' ).filter( (item:any,i:number) => i<5 ));

        //Channels
        channels.current = [];
        setSuggestionChannels(channels.current);
        let suggestedContributors:any[] = values.filter( (item:any,i:number) => item.type === 'contributor' ).filter( (item:any,i:number) => i<4 );
        if(suggestedContributors.length > 0){
            suggestedContributors.map((item:any, i:number) => {
                apiProvider.getContributor(item.id)
                .then((provider) => {
                    if(!provider) return;
                    channels.current.push(provider);
                    setSuggestionChannels([...channels.current]);
                })
                .catch((error) => {
                    console.log('Error', error);
                });
            });
        }


    };



    const fecthSuggestions = (text:string) => {
        if(timer.current) clearTimeout(timer.current);
        timer.current = setTimeout(() => {
            text = text.split(" ").filter( value => value.length > 0).join(',');
            apiProvider.getSuggestion(text)
            .then((values) => {
                //words
                setSuggestionWords(values.filter( (item:any,i:number) => item.type === 'words' ).filter( (item:any,i:number) => i<5 ));

                //Channels
                channels.current = [];
                setSuggestionChannels(channels.current);
                let suggestedContributors:any[] = values.filter( (item:any,i:number) => item.type === 'contributor' ).filter( (item:any,i:number) => i<4 );
                if(suggestedContributors.length > 0){
                    suggestedContributors.map((item:any, i:number) => {
                        apiProvider.getContributor(item.id)
                        .then((provider) => {
                            if(!provider) return;
                            channels.current.push(provider);
                            setSuggestionChannels([...channels.current]);
                        })
                        .catch((error) => {
                            console.log('Error', error);
                        });
                    });
                }
            })
            .catch((error) => {
                console.log('Error', error);
            });
        }, 1000);
    };






    React.useEffect( () => {
        switch(filters.crossActions.searchBarEvent.action){
            case 'onChange':
                setHasTopSearchBar(true);
                
                let searchValue = filters.crossActions.searchBarEvent.payload.value;
                setSuggestions(filters.currentSuggestions);
                break;
            case 'openAgain':
                setHasTopSearchBar(true);
                setSuggestions(filters.currentSuggestions);
                break;
        }

    },[filters.crossActions.searchBarEvent]);





    const handleDelete = (oldsearch:string) => {
        let searches = filters.oldSearches.filter((item, i) => item !== oldsearch);
        setFilters({...filters, oldSearches:[...searches]});

    };
    const handleClick = (suggestion:string) => {
        //searchText(suggestion);
        setFilters({
            ...filters,
            crossActions: {
                ...filters.crossActions,
                searchBarEvent: {
                    action: 'searchText',
                    payload:{value:suggestion}
                }
            }
        });
    };

    const onChange = (event:any) => {
        let value = event.currentTarget.value as string;
        setInputValue(value);
        if(value.length <2 ) return;
        fecthSuggestions(value);
    };

    const submitForm = () => {
        handleClick(inputValue);
    };

    return (
        <>
        {!hasTopSearchBar &&
        <Box sx={{ display: { xs: 'block', md: 'none' }}}>
            <Form
                onSubmit={submitForm}
                render={
                    ({ handleSubmit }) => (
                        <form
                            noValidate
                            onSubmit={handleSubmit}
                        >
                    <Box className={classes.search}>
                        <Box className={classes.searchIcon}>
                            <SearchIcon />
                        </Box>
                        <InputBase
                            classes={{
                                root: classes.searchBox,
                                input: classes.searchInput,
                            }}
                            inputProps={{ "aria-label": translate("newshub.action.search") }}
                            placeholder={translate("newshub.action.search")}
                            autoFocus={true}
                            onChange={onChange}
                            value={inputValue}
                        />
                    </Box>

                        </form>
                    )}
            />
        </Box>
        }

            {filters.oldSearches.length > 0  &&
                <Box className={classes.searchModuleContainer}>
                    <Typography variant="h2" component="p">{translate("newshub.search.oldSearches")}</Typography>
                    <Box className={classes.suggestedWordsContainer}>
                        {
                            filters.oldSearches.map((old,index) =>
                                <Chip component={"button"} clickable label={old} onClick={()=>{ handleClick(old) }} onDelete={() => {handleDelete(old)}} deleteIcon={<CloseIcon />} className={classes.searchModuleChip} color={undefined} />
                            )
                        }
                    </Box>
                </Box>
            }

            {filters.oldSearches.length > 0 && suggestionWords.length > 0 &&
                <Divider orientation="horizontal" className={classes.searchDivider} />
            }


            {suggestionWords.length > 0 &&
            <Box className={classes.searchModuleContainer}>
                <Typography variant="h2" component="p">{translate("newshub.search.suggestedWords")}</Typography>
                <Box className={classes.suggestedWordsContainer}>
                    <MenuList className={classes.searchModuleMenuList} tabIndex={-1}>
                    {
                        suggestionWords.map((suggestion,index) =>
                            <MenuItem tabIndex={0} className={classes.searchModuleMenuItem} onClick={()=>{ handleClick(suggestion.label) }}>
                                {suggestion.label}{/*<Chip component={"button"} clickable label={suggestion.label} className={classes.searchModuleChip} onClick={()=>{ handleClick(suggestion.label) }}  color={undefined} />*/}
                            </MenuItem>
                        )
                    }
                    </MenuList>
                </Box>
            </Box>
            }

            { (filters.oldSearches.length > 0 || suggestionWords.length > 0) && suggestionnChannels.length > 0 &&
                <Divider orientation="horizontal" className={classes.searchDivider} />
            }


            { suggestionnChannels.length > 0 &&
            <Box className={classes.searchModuleContainer}>
                <Grid container>
                    <Grid item xs={8} >
                        <Typography variant="h2" component="p">{translate("newshub.search.suggestedChannels")}</Typography>
                    </Grid>
                    <Grid container xs={4} alignContent={"flex-end"} alignItems={"center"} justifyContent={"flex-end"}>
                        <ViewAllButton
                            /*onClick={handleViewAllChannels}*/
                            href={"/channels"}
                        />
                    </Grid>
                </Grid>

                <Box className={classes.mepListContainer}>
                    <MepCardListSearchBar
                        data={suggestionnChannels}
                        total={suggestionnChannels.length}
                    />
                </Box>
            </Box>
            }
        </>
    );
};


export default SearchModule;
