import React, { useState, MouseEvent} from "react";
import SearchIcon from "@material-ui/icons/Search";
import {InputBase, makeStyles, Box, Popover, Button, IconButton,ClickAwayListener} from "@material-ui/core";
import { useTranslate } from "../../service/translation";
import SearchModule from "./SearchModule";
import { FiltersContext,appDefaultFilters } from "../../service/filters";
import CloseIcon from "@material-ui/icons/Close";
import {apiProvider,ApiParamsType} from "../../service/data/apiProvider";

const useStyles = makeStyles(theme => ({
    popover: {
        "& .MuiPopover-paper": {
            outline: 0,
            position: 'absolute',
            maxWidth: '480px',
            width: '100%',
            /*top: '16px !important',*/
            borderRadius: "16px",
            boxShadow: "0px 0px 10px -6px rgb(0 0 0 / 20%), 0px 6px 10px 3px rgb(0 0 0 / 14%), 0px -9px 10px -19px rgb(0 0 0 / 12%)",
            top: "57px !important",
            minHeight: 0,
        }
    },
    search: {
        height: 40,
        borderRadius: 30,
        position: "relative",
        maxWidth: 480,
        width: "100%",
        margin: "0 auto",
        "&.active":{
            borderRadius: "16px 16px 0 0",
            backgroundColor: theme.palette.type === "light" ? theme.palette.background.paper : theme.palette.background.paper,
            boxShadow: theme.palette.type === "light" ? "0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)" : 'inset 0px 2px 3px #111',
        },
    },
    searchBox: {
        color: "inherit",
        width: "100%",
        height: "100%",
        borderRadius: 30,
            "& input":{
                borderRadius: "20px !important",
                "&:focus-visible": {
                    outline: theme.palette.type === "light" ? "1px solid #bbb !important" : "1px solid " + theme.palette.primary.dark + " !important",
                    outlineOffset: "0",
                    backgroundColor: theme.palette.background.default,
                    boxShadow: theme.palette.type === "light" ? "inset 0px 2px 3px #bbb" : "inset 0px 0px 0px " + theme.palette.primary.dark,
                    borderRadius: "20px !important",
                },
                "&:-moz-focusring": {
                    outline: theme.palette.type === "light" ? "1px solid #bbb !important" : "1px solid " + theme.palette.primary.dark + " !important",
                    outlineOffset: "0",
                    backgroundColor: theme.palette.background.default,
                    boxShadow: theme.palette.type === "light" ? "inset 0px 2px 3px #bbb" : "inset 0px 0px 0px " + theme.palette.primary.dark,
                    borderRadius: "20px !important",
                },
            },
    },
    searchIcon: {
        alignItems: "center",
        display: "flex",
        height: "100%",
        justifyContent: "center",
        padding: theme.spacing(0, 2),
        position: "absolute",
        pointerEvents: "none",
        color: theme.palette.primary.main,
        zIndex: 1,
    },
    clearSearchButton:{
        alignItems: "center",
        display: "flex",
        height: "100%",
        justifyContent: "center",
        padding: theme.spacing(0, 2),
        position: "absolute",
        right: 0,
        top: 0,
        color: theme.palette.type === "light" ? "#999" : theme.palette.secondary.dark,
        zIndex: 1,
    },
    searchInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create("width"),
        height: "100%",
        maxWidth: 480,
        width: "100%",
        boxSizing: "border-box",
    },
}));

export default function SearchBar() {
    const classes = useStyles();
    const translate = useTranslate();
    const [filters,setFilters] = React.useContext(FiltersContext);
    const [anchorEl, setAnchorEl] = useState<any>(null);
    const [inputValue,setInputValue] = React.useState('');

    const isSuggestion = React.useRef(false);
    var timer:any = React.useRef(false);
    var inputElem = React.useRef<any>(null);

    const updateValue = (value:string) => {
        setInputValue(value);
        setFilters({
            ...filters,
            SearchBar: value
        })
    };


    React.useEffect(() => {
        switch(filters.crossActions.searchBarEvent.action){
            case 'searchText':
                handleClose();
                isSuggestion.current = true;
                let value = filters.crossActions.searchBarEvent.payload.value || '';
                updateValue(value);
                searchText(value);
                break;
        }
    },[filters.crossActions.searchBarEvent]);

    React.useEffect(() => {
        updateValue(filters.searchBar);

    },[filters.searchBar]);

    React.useEffect(() => {
        if(filters.advancedSearch.showPanel || filters.openFilters) handleClose();
    }, [filters.advancedSearch.showPanel,filters.openFilters]);




    const searchText = (search:string) => {
        //on search text reset filters;
        let newFilters = {
            ...appDefaultFilters,
            searchBar:search,
            form: {
                ...appDefaultFilters.form,
                searchText: search
            },
            advancedSearch: {
                ...appDefaultFilters.advancedSearch,
                showToggleButton: (search.length > 0)? true : false,
                showPanel: false,
            },
            oldSearches: [...filters.oldSearches]
        };

        if(search.length > 0) {
            newFilters['oldSearches'] = [search,...filters.oldSearches.filter((item) => item !== search )];
        }

        setFilters(newFilters);
    }


    const openAgain = (event:any) => {
        if(typeof filters.currentSuggestions === 'undefined' 
                    || filters.currentSuggestions.length <1
                    ) return;

        setAnchorEl(inputElem.current);
        setFilters({
            ...filters,
            advancedSearch:{
                ...filters.advancedSearch,
                showPanel:false
            },
            openFilters: false,
            crossActions: {
                ...filters.crossActions,
                searchBarEvent:{action:'openAgain',payload:{}}
            }
        });
    }


    const fecthSuggestions = (text:string,currentTarget:any) => {
        if(timer.current) clearTimeout(timer.current);
        timer.current = setTimeout(() => {
            text = text.split(" ").filter( value => value.length > 0).join(',');
            apiProvider.getSuggestion(text)
            .then((values) => {
               
                setFilters({
                    ...filters,
                    currentSuggestions: values,
                    crossActions: {
                        ...filters.crossActions,
                        searchBarEvent:{action:'onChange',payload:{value:text}}
                    }
                });
                
            })
            .catch((error) => {
                console.log('Error', error);
            });
        }, 1000);
    };

    

    const resetInput = () => {
        updateValue('');
        searchText('');
        handleClose();
    };

    const onChange = (event:any) => {
        if(isSuggestion.current){
            isSuggestion.current = false;
            return;
        }

        let searchValue = event.currentTarget.value as string;
        updateValue(searchValue);

        if(searchValue.length < 2 ) {
            console.log('TEST 6');
            setFilters({...filters,currentSuggestions:[]});
            handleClose();
            return;
        }
        setAnchorEl(inputElem.current);
        fecthSuggestions(searchValue,event.currentTarge);
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    const checkTab = (event:any) => {
        let key = event.key;
        if(key !== 'Tab' || !open) return;
        event.preventDefault();
        let elem:any = document.querySelector('.MuiPopover-root .MuiPopover-paper') || null;
        
        if(elem) elem.focus();
    };

    const onSubmit = () => {
        handleClose();
        searchText(inputValue);
    }

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <>
            <Box sx={{ display: { xs: 'none', md: 'block' }}}>
                <Box className={classes.searchIcon}>
                    <SearchIcon />
                </Box>
                <form onSubmit={(e:any) => {e.preventDefault(); onSubmit(); }}>
                    <InputBase
                        ref = {node => inputElem.current = node}
                        classes={{
                            root: classes.searchBox,
                            input: classes.searchInput,
                        }}
                        inputProps={{ "aria-label": translate("newshub.action.search") }}
                        placeholder={translate("newshub.action.search")}

                        /*REMOVE ME*/
                        /*onBlur={handleBlur}*/
                        value = {inputValue}
                        onChange={onChange}
                        onKeyDown={checkTab}
                        onClick={openAgain}
                        /*readOnly={true}*/
                        /*tabIndex={0}*/

                    />
                </form>
                { typeof inputValue === "string" && inputValue.length > 0 &&
                    <IconButton onClick={resetInput} aria-label="delete" className={classes.clearSearchButton}>
                        <CloseIcon fontSize="small" />
                    </IconButton>
                }
            </Box>
            
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    className={classes.popover}
                    disableAutoFocus={true}
                    disableEnforceFocus={true}
                    tabIndex={5}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <SearchModule onSubmit={onSubmit} />
                </Popover>
            
        </>
    );
};
