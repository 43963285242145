import { useEffect } from "react";
import { useHistory } from "react-router-dom";

interface HistoryLocationState {
    _scrollToTop: boolean;
}

const useScrollToTop = () => {
    const history = useHistory<HistoryLocationState>();

    useEffect(() => history.listen(({ location, action}) => {
        if (action !== "POP" && location.state?._scrollToTop && typeof window !== "undefined") {
            window.scrollTo(0, 0);
        }
    }), [history]);
};

export { useScrollToTop }
