import {makeStyles} from "@material-ui/core";

const useMepCardListStyles = makeStyles(theme => ({
    list: {
        display: "flex",
        flexWrap: "wrap",
        margin: theme.spacing(1, "auto"),
        width: "100%",
        height: "100%",
        paddingBottom: theme.spacing(1),
        alignContent: "flex-start",
        "& *": {
            textDecoration: "none",
        },
        [theme.breakpoints.down("sm")]: {
            margin: theme.spacing(0, "auto"),
            paddingBottom: "50px",
        },
        [theme.breakpoints.down("xs")]: {
            margin: theme.spacing(0, "auto"),
            paddingBottom: "40px",
                "& .MuiGrid-root": {
                    display: "block",
                    "&.gridItem": {
                        maxWidth: "100%",
                        flexBasis: "100%",
                        padding: 0,
                    },
                    "& .MuiCard-root": {
                        boxShadow: "none",
                        margin: theme.spacing(0),
                        borderBottom: theme.palette.type === "light" ? "1px solid #CCC" : "1px solid" + theme.palette.text.hint,
                        borderRadius: 0,
                        "& .MuiCardHeader-root": {
                            padding: theme.spacing(2),
                        },
                    },
                }

        },
    },
    counterContainer:{
        [theme.breakpoints.down("xs")]: {
            margin: "0px auto",
            maxWidth: "480px",
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
            paddingLeft: theme.spacing(1),
        },
        "@media (width: 768px)": {
            margin: "0px",
            maxWidth: "480px",
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
            paddingLeft: theme.spacing(1),
        },
    },
    searchModuleMenuList:{
        padding: 0,
        "& > li":{
            padding: 0,
            "& > div":{
                margin: 0,
            },
        },
        "& > a":{
            padding: 0,
            "& > div":{
                margin: 0,
            },
            "&:focus-visible": {
                backgroundColor: "transparent !important",
                "& *":{
                    color: theme.palette.type === "light" ? theme.palette.primary.dark + " !important" : theme.palette.primary.dark + " !important",
                    backgroundColor: "transparent",
                },
                "& .MuiTypography-h2":{
                    color: theme.palette.type === "light" ? theme.palette.primary.main + " !important" : theme.palette.primary.main + " !important",
                    backgroundColor: "transparent",
                },
            },
            "&:-moz-focusring": {
                backgroundColor: "transparent !important",
                "& *":{
                    color: theme.palette.type === "light" ? theme.palette.primary.dark + " !important" : theme.palette.primary.dark + " !important",
                    backgroundColor: "transparent",
                },
                "& .MuiTypography-h2":{
                    color: theme.palette.type === "light" ? theme.palette.primary.main + " !important" : theme.palette.primary.main + " !important",
                    backgroundColor: "transparent",
                },
            },
            "&:hover":{
                backgroundColor: "transparent",
            },
        },
    },
    card: {
        display: "flex",
        flexShrink: 0,
        margin: theme.spacing(1, 0),
        width: "100%",
        height: "100%",
        "& .MuiCardHeader-root": {
            alignItems: "center",
            width: "100%",
            boxSizing: "border-box",
            padding: theme.spacing(1, 2),
            "& .MuiCardHeader-avatar": {
                "&>div": {
                    boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
                },
            },
            "& .MuiCardHeader-content": {
                marginRight: theme.spacing(2),
                "& p": {
                    maxWidth: "100%",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                },
            },
            "& .MuiCardHeader-action": {
                alignSelf: "center",
                margin: "0",
                "&>a,&>button": {
                    backgroundColor: theme.palette.type === "light" ? theme.palette.background.paper : theme.palette.background.paper,
                    border: "1px solid",
                    borderColor: theme.palette.type === "light" ? theme.palette.primary.main : theme.palette.primary.main,
                    color: theme.palette.type === "light" ? theme.palette.primary.main : theme.palette.primary.main,
                    borderRadius: "50px",
                    fontSize: "16px",
                    fontWeight: "bold",
                    textTransform: "none",
                    boxShadow: "none",
                    "&:hover":{
                        borderColor: theme.palette.type === "light" ? theme.palette.primary.dark : theme.palette.primary.dark,
                        color: theme.palette.type === "light" ? theme.palette.primary.dark : theme.palette.primary.dark,
                    },
                },
            },
        },
    },
}), { name: "NewshubMepCardList" });

export { useMepCardListStyles };
