import AnchorIcon from "@material-ui/icons/Link";
import EmailIcon from "@material-ui/icons/Email";
import EmbedIcon from "@material-ui/icons/Code";
import MuiAlert, {AlertProps} from "@material-ui/lab/Alert";
import React, { FunctionComponent } from "react";
import SignalIcon from "@material-ui/icons/ChatBubble";
import Snackbar from '@material-ui/core/Snackbar';
import TelegramIcon from "@material-ui/icons/Telegram";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import classNames from "classnames";
import { Box, Toolbar, Typography } from "@material-ui/core";
import { CopyToClipboard} from "react-copy-to-clipboard";
import { SocialItem } from "../../service/data";
import { useTranslate } from "../../service/translation";

interface SocialCardShareToolbarProps {
    classes: {
        toolbar: string;
        toolbarCollapsed: string;
        toolbarContent: string;
        toolbarItem: string;
        snackbar: string;
    };
    collapsed: boolean;
    socialHandler?:(type:string) => {};
    record: SocialItem;
}

function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const SNACKBAR_AUTO_HIDE_DURATION = 6000;

const SocialCardShareToolbar: FunctionComponent<SocialCardShareToolbarProps> = (props: SocialCardShareToolbarProps) => {
    const translate = useTranslate();
    const { classes, collapsed,socialHandler,record } = props;
    const [snackbarPermalink, setSnackbarPermalink] = React.useState(false);
    const [snackbarEmbed, setSnackbarEmbed] = React.useState(false);
    const [snackbarSignal, setSnackbarSignal] = React.useState(false);

    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarPermalink(false);
        setSnackbarEmbed(false);
        setSnackbarSignal(false);

    };

    const onClick = (type:string) => {
        if(typeof socialHandler === 'function' ) {
            socialHandler(type);
        }
    };

    const removeTags = (content:string) => {
        if ((content===null) || (content==='')) return content;
        return content.replace( /(<([^>]+)>)/ig, '');
    };

    const renderItem = (label: string, children: any,type:string) => (
        <button className={classes.toolbarItem} onClick={() => { onClick(type); }}>
            {children}
            <Typography>
                {label}
            </Typography>
        </button>
    );
    const loc = window.location;
    const permalink = `${loc.protocol}//${loc.host}/tw/${record.id}`;
    const embedIframe = `<iframe width='100%' height='100%' src="${loc.protocol}//${loc.host}/embed/tw/${record.id}"></iframe>`;
    const whatsapp = 'https://api.whatsapp.com/send?text='+ encodeURIComponent(`${permalink} ` + removeTags(record.content));
    const telegram = `https://t.me/share/url?url=${permalink}`;
    const mailTo = 'mailto:test@example.com?subject=From:'
            + encodeURIComponent(loc.host)
            +'&body='+encodeURIComponent(permalink);


    return (
        <Toolbar className={classNames(classes.toolbar, {
            [classes.toolbarCollapsed]: collapsed,
        })}>
            <Box className={classes.toolbarContent}>

                <CopyToClipboard text={permalink} onCopy={() => setSnackbarPermalink(true) } >
                    <button className={classes.toolbarItem} >
                        <Box>
                            <AnchorIcon />
                        </Box>
                        <Typography>
                            {translate("newshub.action.anchor")}
                        </Typography>
                    </button>
                </CopyToClipboard>

                <Snackbar
                    autoHideDuration={SNACKBAR_AUTO_HIDE_DURATION}
                    className={classes.snackbar}
                    open={snackbarPermalink}
                    onClose={handleClose}
                >
                    <Alert onClose={() => setSnackbarPermalink(false)  } severity="success">
                        {translate("newshub.action.anchor")} {translate("newshub.action.copied")}
                    </Alert>
                </Snackbar>


                <CopyToClipboard text={embedIframe} onCopy={() => setSnackbarEmbed(true) } >
                    <button className={classes.toolbarItem} >
                        <Box>
                            <EmbedIcon />
                        </Box>
                        <Typography>
                            {translate("newshub.action.embed")}
                        </Typography>
                    </button>
                </CopyToClipboard>

                <Snackbar
                    autoHideDuration={SNACKBAR_AUTO_HIDE_DURATION}
                    className={classes.snackbar}
                    open={snackbarEmbed}
                    onClose={handleClose}
                >
                    <Alert onClose={() => setSnackbarEmbed(false)  } severity="success">
                        {translate("newshub.action.embed")} {translate("newshub.action.copied")}
                    </Alert>
                </Snackbar>

                <a href={mailTo} className={classes.toolbarItem} >
                    <Box>
                        <EmailIcon />
                    </Box>
                    <Typography>
                        {translate("newshub.action.email")}
                    </Typography>
                </a>


                <a href={whatsapp} target="_blank" rel="noreferrer noopener" className={classes.toolbarItem} >
                    <Box>
                        <WhatsAppIcon />
                    </Box>
                    <Typography>
                        {translate("newshub.action.whatsapp")}
                    </Typography>
                </a>

                <a href={telegram} target="_blank" rel="noreferrer noopener" className={classes.toolbarItem} >
                    <Box>
                        <TelegramIcon />
                    </Box>
                    <Typography>
                        {translate("newshub.action.telegram")}
                    </Typography>
                </a>

                <CopyToClipboard text={permalink} onCopy={() => setSnackbarSignal(true) } >
                    <button className={classes.toolbarItem} >
                        <Box>
                             <SignalIcon />
                        </Box>
                        <Typography>
                            {translate("newshub.action.signal")}
                        </Typography>
                    </button>
                </CopyToClipboard>

                <Snackbar
                    autoHideDuration={SNACKBAR_AUTO_HIDE_DURATION}
                    className={classes.snackbar}
                    open={snackbarSignal}
                    onClose={handleClose}
                >
                    <Alert onClose={() => setSnackbarSignal(false)  } severity="success">
                        {translate("newshub.action.signalCopied")}
                    </Alert>
                </Snackbar>
            </Box>
        </Toolbar>
    );
};

SocialCardShareToolbar.defaultProps = {
    collapsed: false,
};

export default SocialCardShareToolbar;
