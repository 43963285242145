import React, { ErrorInfo, FunctionComponent } from "react";
import {Accordion, AccordionDetails, AccordionSummary, Box, Grid, Typography} from "@material-ui/core";
import { HistoryBackButton } from "../button";
import { useTranslate } from "../../service/translation";
import { useContentStyles } from "./useContentStyles";
import { Link } from "@material-ui/core";
import classNames from "classnames";

interface ErrorProps {
    errorMessage?: string;
    errorInfo?: ErrorInfo;
}

const ErrorContent: FunctionComponent<ErrorProps> = (props: ErrorProps) => {
    const classes = useContentStyles();
    const translate = useTranslate();
    const { errorMessage, errorInfo } = props;

    return (
        <Grid container className={classes.page} id="main-content">
            <Grid container className={classNames(classes.pageWrapper, 'alignCenterImp')} justifyContent={"center"} alignItems={"center"}>
                <Grid item className={classes.message}>
                    <Typography component={"h1"} className={classes.error404}>{translate("newshub.error.error404.title")}</Typography>
                    <Typography component={"p"} variant={"h6"} gutterBottom>{translate("newshub.error.error404.description1")}</Typography>
                    <Typography component={"p"} variant={"h6"}>{translate("newshub.error.error404.description2")}<Link href="/">{translate("newshub.error.error404.link")}</Link>{translate("newshub.error.error404.description3")}</Typography>
                    {/*{
                        process.env.NODE_ENV !== "production" && (
                            <Accordion className={classes.trace}>
                                <AccordionSummary>
                                    {translate("newshub.message.details")}
                                </AccordionSummary>
                                <AccordionDetails className={classes.details}>
                                    <Box>
                                        <h2>{errorMessage ? translate(errorMessage) : null}</h2>
                                        {errorInfo && errorInfo.componentStack}
                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                        )
                    }*/}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default ErrorContent;
