import React, { FunctionComponent } from "react";
import { useTranslate } from "../../service/translation";
import { useContentStyles } from "./useContentStyles";
import {Box, Grid, Link, Typography} from "@material-ui/core";
import classNames from "classnames";

const NotFoundContentTimeline: FunctionComponent = () => {
    const classes = useContentStyles();
    const translate = useTranslate();

 
    return (

        <Grid container justifyContent={"center"} alignItems={"center"} className={classNames(classes.notFoundContentContainer, 'notFoundContent')}>
            <Grid item>
                <Box mb={4}>
                    <Typography component={"h1"} variant={"h1"} gutterBottom>{translate("newshub.error.notFoundContentTimeline.title")}</Typography>
               </Box>
                <Typography component={"h2"} gutterBottom>{translate("newshub.error.notFoundContentTipsTimeline.title")}</Typography>
                <Typography component={"p"} variant={"h6"} gutterBottom>{translate("newshub.error.notFoundContentTipsTimeline.tip1")}</Typography>
                <Typography component={"p"} variant={"h6"} gutterBottom>{translate("newshub.error.notFoundContentTipsTimeline.tip2")}</Typography>
                <Typography component={"p"} variant={"h6"} gutterBottom>{translate("newshub.error.notFoundContentTipsTimeline.tip3")}</Typography>
                <Typography component={"p"} variant={"h6"} gutterBottom>{translate("newshub.error.notFoundContentTipsTimeline.tip4")}</Typography>
                
             </Grid>
        </Grid>

    );
};

export default NotFoundContentTimeline;
