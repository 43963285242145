import React, { FunctionComponent } from "react";
import TopicChip from "./TopicChip";
import classNames from "classnames";
import { ClearTopicsButton } from "../button";
import { ResourceList, Topic } from "../../service/data";
import {Box, Grid, Typography} from "@material-ui/core";
import { useTopicStyles } from "./useTopicStyles";
import ClearAllButton from "../button/ClearAllButton";
import {FiltersContext} from "../../service/filters";
interface TopicChipListProps {
    data: ResourceList<Topic>;
    justifyContent?: string;
    title?: string;
    onClear?: () => void;
}

const TopicChipList: FunctionComponent<TopicChipListProps> = (props: TopicChipListProps) => {
    const classes = useTopicStyles();
    const { data, justifyContent, title, onClear } = props;
    const [filters,setFilters] = React.useContext(FiltersContext)
    return (
        <Box className={classes.topics}>
            {
                title && (
                    <>
                        <Grid container className={classes.topicsTitle} justifyContent={"space-between"}>
                            <Grid item xs={12}>
                                <Typography
                                    className={classNames(classes.title, {
                                        [classes.alignCenter]: justifyContent === "center",
                                        [classes.alignLeft]: justifyContent === "left",
                                    })}
                                    gutterBottom
                                    variant="h1"
                                    component="p"
                                >
                                    {title}
                                </Typography>
                            </Grid>
                            {filters.activeTopics.length > 0 &&
                                <Grid item className={classes.topicsClearAll}>
                                    <ClearAllButton onClick={onClear} className={classes.clearAllButton} />
                                </Grid>
                            }
                        </Grid>


                    </>
                )
            }
            <Box className={classNames(classes.chips, {
                [classes.justifyCenter]: justifyContent === "center",
                [classes.justifyLeft]: justifyContent === "left",
            })}>
                {
                    data.map(record => (
                        <TopicChip
                            classes={classes}
                            className={classes.chip}
                            key={record.id}
                            record={record}

                        />
                    ))
                }
            </Box>
        </Box>
    );
};

TopicChipList.defaultProps = {
    justifyContent: "center",
};

export default TopicChipList;
