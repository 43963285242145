import { combineReducers } from "redux";
import { Mep } from "../Mep";
import { PoliticalGroup } from "../PoliticalGroup";
import { SocialItem } from "../SocialItem";
import { Topic } from "../Topic";
import { createDataReducer } from "./createDataReducer";

const dataFetchReducer = combineReducers({
    mep: createDataReducer<Mep>("mep"),
    politicalGroup: createDataReducer<PoliticalGroup>("politicalGroup"),
    socialItem: createDataReducer<SocialItem>("socialItem"),
    topic: createDataReducer<Topic>("topic"),
});

export { dataFetchReducer }
