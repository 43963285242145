import React from "react";
import CloseIcon from '@material-ui/icons/Close';
import { IconButton, IconButtonProps, Tooltip } from "@material-ui/core";
import { useTranslate } from "../../service/translation";

const CloseIconButton = (props: IconButtonProps) => {
    const translate = useTranslate();

    return (
        <Tooltip title={translate("newshub.action.close")}>
            <IconButton
                aria-label={translate("newshub.action.close")}
                color="primary"
                size="small"
                {...props}
            >
                <CloseIcon />
            </IconButton>
        </Tooltip>
    )
};

export default CloseIconButton;
