import React, { FunctionComponent } from "react";
import MepAvatar from "./MepAvatar";
import { Typography, Box, makeStyles } from "@material-ui/core";
import { Mep } from "../../service/data";
import { Link } from "react-router-dom";
import {Helmet} from "react-helmet-async";
import {useTranslate} from "../../service/translation";
const useStyles = makeStyles(theme => ({
    root: {
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
    },
}));

interface MepDetailsProps {
    record: Mep;
}

const MepDetails: FunctionComponent<MepDetailsProps> = (props: MepDetailsProps) => {
    const classes = useStyles();
    const translate = useTranslate();
    const { record } = props;
    const { country, firstName, lastName, politicalGroup, type} = record;

    return (
        <Box className= {classes.root + " accountDetails"} mb={4}>
            <Helmet>
                <title>{(record.firstName +  " " + record.lastName ) + " social media profile on EPNewshub.eu "}</title>
                <meta name='description' content={"Social Media Profile of MEP " + (record.firstName +  " " + record.lastName) + " on EPNewshub.eu"} />
                <link rel='canonical' href={window.location.href} />
            </Helmet>
            <Box mb={2}>
                <MepAvatar record={record} size="large" />
            </Box>
            <Box mb={1}>
                <Typography component="h1" variant="h1">
                    {firstName}<br/>
                    {lastName.toUpperCase()}
                </Typography>
            </Box>
            <Box mb={1}>
                <Typography component="p" variant="body1" color="textSecondary">
                    {politicalGroup.name}
                </Typography>
            </Box>
            { record.type  === 'mep' ?
                <Box mb={1}>
                    <Typography component="p" variant="body1" color={"primary"} gutterBottom={true}>
                        { politicalGroup.id != 0 && <Link to={`/contributors/${politicalGroup.id}`} className="nodeco">
                            {politicalGroup.party}
                        </Link>}

                    </Typography>
                    <Typography component="p" variant="subtitle2">
                        {country}
                    </Typography>
                    <Typography component="p" variant="subtitle2">
                        {politicalGroup.localParty}
                    </Typography>
                </Box>
            : null }
        </Box>
    );
};

export default MepDetails;
