import React, { FunctionComponent } from "react";
import { MepList } from "../mep";
import { useContentStyles } from "./useContentStyles";
import {Box, Grid} from "@material-ui/core";
import MepGroupFilter from "../mep/MepGroupFilter";
import MepTopFilter from "../mep/MepTopFilter";
import classNames from "classnames";
import { useTranslate } from "../../service/translation";
import ChannelsTutorial from "./JoyrideChannelsTutorial";
import ChannelsTutorialXs from "./JoyrideChannelsTutorialXs";

interface ChannelsContentProps {
}

const ChannelsContent: FunctionComponent<ChannelsContentProps> = (props: ChannelsContentProps) => {
    const classes = useContentStyles();
    const translate = useTranslate();

    return (
        <Grid container className={classes.page} id="main-content">
            {/*<Box sx={{ display: { xs: 'none', md: 'block' }}}>
                <ChannelsTutorial
                    breakpoint={""}
                    translate={translate}
                />
            </Box>
            <Box sx={{ display: { xs: 'block', md: 'none' }}}>
                <ChannelsTutorialXs
                    breakpoint={""}
                    translate={translate}
                />
            </Box>*/}


            <Grid container className={classes.topFilterContainer}>
                <Grid container className={classNames(classes.topFilterWrapper, 'relative')}>
                    {/*<Box className={classNames('joyrideChannels00')} sx={{ display: { xs: 'none', md: 'block' }}}></Box>
                    <Box className={classNames('joyrideChannelsXs00')} sx={{ display: { xs: 'block', md: 'none' }}}></Box>*/}
                    <MepTopFilter />
                </Grid>
            </Grid>
            <Grid container className={classNames(classes.pageWrapper, 'dblockImp')}>
                <Grid container className={classNames(classes.topFilterContainer, 'bgTrans')}>
                    <Grid container className={classes.topFilterWrapper}>
                        <MepGroupFilter />
                    </Grid>
                </Grid>
                <MepList />
            </Grid>
        </Grid>
    );
};

export default ChannelsContent;
