import { DataProvider } from "./DataProvider";
import { GetCollectionResult } from "./GetCollectionResult";
import { GetInstanceParams } from "./GetInstanceParams";
import { GetInstanceResult } from "./GetInstanceResult";
import { Identifier } from "./Identifier";
import { Mep } from "./Mep";
import { PoliticalGroup } from "./PoliticalGroup";
import { Resource } from "./Resource";
import { SocialItem } from "./SocialItem";
import { Topic } from "./Topic";

const emptyCollection = <R extends Resource>(): GetCollectionResult<R> => ({ status: 0, data: [], total: 0 });
const emptyInstance = <R extends Resource>(id: Identifier): GetInstanceResult<R> => ({ status: 0, data: { id } as R});

/**
 * A {@link DataProvider} implementation which always return
 * empty results.
 */
const noDataProvider: DataProvider = {
    getPoliticalGroups: () => Promise.resolve(emptyCollection<PoliticalGroup>()),
    getPoliticalGroup: (params: GetInstanceParams) => Promise.resolve(emptyInstance<PoliticalGroup>(params.id)),
    getMeps: () => Promise.resolve(emptyCollection<Mep>()),
    getMep: (params: GetInstanceParams) => Promise.resolve(emptyInstance<Mep>(params.id)),
    getSocialItems: () => Promise.resolve(emptyCollection<SocialItem>()),
    getTopics: () => Promise.resolve(emptyCollection<Topic>()),
}

export default noDataProvider;
