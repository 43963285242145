import React, { Component } from 'react';
import Joyride, { CallBackProps, STATUS, Step, StoreHelpers } from 'react-joyride';
import {Typography} from "@material-ui/core";
import { AnyARecord } from 'dns';

interface Props {
    breakpoint: string;
    translate: any;
    skipOrEndCallback: () => void;
}

interface State {
    run: boolean;
    steps: Step[];
}

class HomeTutorial extends Component<Props, State> {
    private helpers?: StoreHelpers;
    private skipOrEndCallback: any;

    constructor(props: Props) {
        super(props);

        const { translate ,skipOrEndCallback} = props;

        this.skipOrEndCallback = skipOrEndCallback;

        this.state = {
            run: true,
            steps: [
                {
                    content:<>
                        <Typography variant={"h1"} component={"p"} color={"secondary"}>{translate("tutorial.homeTutorial.title.step0")}</Typography>
                        <br/>
                        <Typography component={"p"}>{translate("tutorial.homeTutorial.message.step0")}</Typography>
                        </>
                    ,
                    disableBeacon: true,
                    locale: { back: 'Previous', close: 'Close', last: 'Close', next: 'Next', open: 'Open the dialog', skip: 'Skip tutorial' },
                    floaterProps: {
                        disableAnimation: true,
                    },
                    spotlightPadding: 0,
                    placement: 'left-start',
                    target: '.joyrideHome00',
                },{
                    content:<>
                        <Typography variant={"h1"} component={"p"} color={"secondary"}>{translate("tutorial.homeTutorial.title.step1")}</Typography>
                        <br/>
                        <Typography component={"p"}>{translate("tutorial.homeTutorial.message.step1")}</Typography>
                        </>
                    ,
                    disableBeacon: true,
                    locale: { back: 'Previous', close: 'Close', last: 'Close', next: 'Next', open: 'Open the dialog', skip: 'Skip tutorial' },
                    floaterProps: {
                        disableAnimation: true,
                    },
                    spotlightPadding: 10,
                    placement: 'bottom-start',
                    target: '.joyrideHome01',
                },
                {
                    content:<>
                        <Typography variant={"h1"} component={"p"} color={"secondary"}>{translate("tutorial.homeTutorial.title.step2")}</Typography>
                        <br/>
                        <Typography component={"p"}>{translate("tutorial.homeTutorial.message.step2")}</Typography>
                        </>
                    ,
                    disableBeacon: true,
                    locale: { back: 'Previous', close: 'Close', last: 'Close', next: 'Next', open: 'Open the dialog', skip: 'Skip tutorial' },
                    floaterProps: {
                        disableAnimation: true,
                    },
                    spotlightPadding: 10,
                    placement: 'bottom',
                    target: '.joyrideHome02',
                },
                {
                    content:<>
                        <Typography variant={"h1"} component={"p"} color={"secondary"}>{translate("tutorial.homeTutorial.title.step3")}</Typography>
                        <br/>
                        <Typography component={"p"}>{translate("tutorial.homeTutorial.message.step3")}</Typography>
                        </>
                    ,
                    disableBeacon: true,
                    locale: { back: 'Previous', close: 'Close', last: 'Close', next: 'Next', open: 'Open the dialog', skip: 'Skip tutorial' },
                    floaterProps: {
                        disableAnimation: true,
                    },
                    spotlightPadding: 0,
                    placement: 'right-start',
                    disableScrollParentFix: true,
                    target: '.joyrideHome03',
                },
                {
                    content:<>
                        <Typography variant={"h1"} component={"p"} color={"secondary"}>{translate("tutorial.homeTutorial.title.step4")}</Typography>
                        <br/>
                        <Typography component={"p"}>{translate("tutorial.homeTutorial.message.step4")}</Typography>
                        </>
                    ,
                    disableBeacon: true,
                    locale: { back: 'Previous', close: 'Close', last: 'Close', next: 'Next', open: 'Open the dialog', skip: 'Skip tutorial' },
                    floaterProps: {
                        disableAnimation: true,
                    },
                    spotlightPadding: 0,
                    disableScrollParentFix: true,
                    placement: 'right-start',
                    target: '.joyrideHome04',
                }
            ],
        };
    }

    private getHelpers = (helpers: StoreHelpers) => {
        this.helpers = helpers;
    };

    private handleClickStart = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();

        this.setState({
            run: true,
        });
    };

    private handleJoyrideCallback = (data: CallBackProps) => {
        const { status, type } = data;
        const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

        if (finishedStatuses.includes(status)) {
            this.setState({ run: false });
            this.skipOrEndCallback();
        }

        console.groupCollapsed(type);
        console.log(data);
        console.groupEnd();
    };

    public render() {
        const { run, steps } = this.state;
        const { breakpoint } = this.props;

        return (
            <Joyride
                callback={this.handleJoyrideCallback}
                continuous={true}
                getHelpers={this.getHelpers}
                run={run}
                disableScrolling={true}
                disableOverlayClose={true}
                scrollToFirstStep={false}
                showProgress={true}
                showSkipButton={true}
                steps={steps}
                styles={{
                    options: {
                        arrowColor: 'transparent',
                        backgroundColor: 'transparent',
                        primaryColor: '#f4cc47',
                        textColor: '#FFF',
                        overlayColor: 'rgba(0, 0, 0, 0.8)',
                        spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
                        zIndex: 10000,
                    },
                }}
            />
        );
    }
}

export default HomeTutorial;
