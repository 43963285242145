import React, { FunctionComponent, useState } from "react";
import TimelineChart from "./TimelineChart";
import TimelineSkeleton from "./TimelineSkeleton";
import {Box, Button, Grid, Typography} from "@material-ui/core";
import { SocialItem } from "../../service/data";
import { TimelineFilterType } from "./TimelineFilterType";
import { useTranslate } from "../../service/translation";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import Scrollbar from "react-scrollbars-custom";
import ClearTimelineFilterButton from "../button/ClearTimelineFilterButton";
import InfoTimelineFilterIconButton from "../button/InfoTimelineFilterIconButton";
import {apiProvider} from "../../service/data/apiProvider";
import {appDefaultFilters, FiltersContext} from "../../service/filters";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        topFilters: {
            [theme.breakpoints.down("sm")]: {
                marginTop: theme.spacing(2),
            },
        },
        buttonContainer: {
            "&>button": {
                marginRight: theme.spacing(1),
                fontSize: "12px",
                "&.MuiButton-containedSecondary":{
                  backgroundColor:  theme.palette.type === "light" ? theme.palette.background.paper : theme.palette.secondary.dark,
                },
                "&.MuiButton-containedPrimary":{
                    "&:hover":{
                        backgroundColor:  theme.palette.type === "light" ? theme.palette.primary.main : theme.palette.primary.main,
                    },
                },
            },
        },
        timelineContainer: {
            height: '210px !important',
            width: "100%",
            marginBottom:  theme.spacing(3),
        },
        timelineBox: {
            height: '250px',
            width: "100%",
        },
        resultsContainer: {
            [theme.breakpoints.down("xs")]: {
                marginTop: theme.spacing(2),
                justifyContent: "space-between",
            },
        },
        "@global":{
            ".notFoundContent": {
                height: "auto !important",
                padding: "50px 0",
            },
            /*TIMELINE SLIDER*/
            ".timeLineSliderContainer":{
                position: 'relative',
                height: '170px',
                width: '100%',
                overflow: 'hidden',
            },
            "#slider-container": {
                position: 'absolute',
                height: '120px',
                width: '100%',
                "&+div": {
                    pointerEvents: "none",
                },
                "&.active": {
                    "& .slider": {
                        border: theme.palette.type === "light" ? "2px dashed " + theme.palette.secondary.main : "2px dashed " + theme.palette.secondary.main,
                    },
                    "& .slider .handle": {
                        background: theme.palette.type === "light" ? theme.palette.secondary.main : theme.palette.secondary.main,
                    },
                },

            },
            ".slider-container": {
                /*opacity: "0.5",*/
                /*zIndex: "1000",*/
            },
            ".slider": {
                position: "absolute",
                height: "100%",
                width: "58px",
                top: "0px",
                cursor: "move",
                border: theme.palette.type === "light" ? "2px dashed #CCC" : "2px dashed " + theme.palette.text.hint,
                background: theme.palette.type === "light" ? theme.palette.background.paper : theme.palette.background.paper,
                borderRadius: "10px",
            },
            ".slider .handle": {
                position: "absolute",
                height: "22px",
                width: "22px",
                background: theme.palette.type === "light" ? "#CCC" : theme.palette.text.hint,
                boxSizing: "border-box",
                borderRadius: "50%",
            },
            ".slider .EE": {
                right: "-12px",
                cursor: "e-resize",
                "&:before": {
                    content: "''",
                    backgroundImage: theme.palette.type === "light" ? "url(data:image/svg+xml;base64,PHN2ZyBpZD0iU3ZnanNTdmcxMDMxIiB3aWR0aD0iMjg4IiBoZWlnaHQ9IjI4OCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2ZXJzaW9uPSIxLjEiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4bWxuczpzdmdqcz0iaHR0cDovL3N2Z2pzLmNvbS9zdmdqcyI+PGRlZnMgaWQ9IlN2Z2pzRGVmczEwMzIiPjwvZGVmcz48ZyBpZD0iU3ZnanNHMTAzMyI+PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyODgiIGhlaWdodD0iMjg4IiB2aWV3Qm94PSIwIDAgNzY4IDc2OCI+PHBhdGggZD0iTTI3NC41IDUzMWwxNDctMTQ3LTE0Ny0xNDcgNDUtNDUgMTkyIDE5Mi0xOTIgMTkyeiIgZmlsbD0iI2ZmZmZmZiIgY2xhc3M9ImNvbG9yMDAwIHN2Z1NoYXBlIj48L3BhdGg+PC9zdmc+PC9nPjwvc3ZnPg==)" : "url(data:image/svg+xml;base64,PCEtLSBHZW5lcmF0ZWQgYnkgSWNvTW9vbi5pbyAtLT4KPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9Ijc2OCIgaGVpZ2h0PSI3NjgiIHZpZXdCb3g9IjAgMCA3NjggNzY4Ij4KPHRpdGxlPjwvdGl0bGU+CjxnIGlkPSJpY29tb29uLWlnbm9yZSI+CjwvZz4KPHBhdGggZD0iTTI3NC41IDUzMWwxNDctMTQ3LTE0Ny0xNDcgNDUtNDUgMTkyIDE5Mi0xOTIgMTkyeiI+PC9wYXRoPgo8L3N2Zz4K)",
                    height: "22px",
                    width: "22px",
                    position: "absolute",
                    backgroundSize: "cover",
                },
            },
            ".slider .WW": {
                cursor: "w-resize",
                left: "-12px",
                "&:before": {
                    content: "''",
                    backgroundImage: theme.palette.type === "light" ? "url(data:image/svg+xml;base64,PHN2ZyBpZD0iU3ZnanNTdmcxMDAxIiB3aWR0aD0iMjg4IiBoZWlnaHQ9IjI4OCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2ZXJzaW9uPSIxLjEiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4bWxuczpzdmdqcz0iaHR0cDovL3N2Z2pzLmNvbS9zdmdqcyI+PGRlZnMgaWQ9IlN2Z2pzRGVmczEwMDIiPjwvZGVmcz48ZyBpZD0iU3ZnanNHMTAwOCIgdHJhbnNmb3JtPSJtYXRyaXgoMSwwLDAsMSwwLDApIj48c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjI4OCIgaGVpZ2h0PSIyODgiIHZpZXdCb3g9IjAgMCA3NjggNzY4Ij48cGF0aCBkPSJNNDkzLjUgNTMxbC00NSA0NS0xOTItMTkyIDE5Mi0xOTIgNDUgNDUtMTQ3IDE0N3oiIGZpbGw9IiNmZmZmZmYiIGNsYXNzPSJjb2xvcjAwMCBzdmdTaGFwZSI+PC9wYXRoPjwvc3ZnPjwvZz48L3N2Zz4=)" : "url(data:image/svg+xml;base64,PCEtLSBHZW5lcmF0ZWQgYnkgSWNvTW9vbi5pbyAtLT4KPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9Ijc2OCIgaGVpZ2h0PSI3NjgiIHZpZXdCb3g9IjAgMCA3NjggNzY4Ij4KPHRpdGxlPjwvdGl0bGU+CjxnIGlkPSJpY29tb29uLWlnbm9yZSI+CjwvZz4KPHBhdGggZD0iTTQ5My41IDUzMWwtNDUgNDUtMTkyLTE5MiAxOTItMTkyIDQ1IDQ1LTE0NyAxNDd6Ij48L3BhdGg+Cjwvc3ZnPgo=)",
                    height: "22px",
                    width: "22px",
                    position: "absolute",
                    backgroundSize: "cover",
                },
            },
            ".slider .EE, .slider .WW": {
                top: "50%",
                marginTop: "-11px",
            },
        },
    }),
);

interface TimelineProps {
    data: SocialItem[];
    defaultFilter?: TimelineFilterType;
    onFilter: (filter: TimelineFilterType) => void;
    onRangeSelect: () => void;
}

const Timeline: FunctionComponent<TimelineProps> = (props: TimelineProps) => {
    const classes = useStyles();
    const { onFilter } = props;
    const translate = useTranslate();
    //filter:  day ,week , month
    const [filterday, setFilterDay] = useState<any>(null);
    const [timeLineData,setTimeLineData] = useState<any>([]);
    const [maxValue, setMaxValue] =  useState(10);
    const [filters,setFilters] = React.useContext(FiltersContext);
    const [range,setRange] = React.useState({begin:0,end:100});
    const [total, setTotal] = React.useState<any>(0);
    const [minWidth,setMinWidth] = React.useState('1180px');


    const rangeInitialBegin = Math.round(window.innerWidth / 50);
    const rangeInitialEnd =  rangeInitialBegin + 20;


    const handleFilter = (newFilter: TimelineFilterType) => () => {
        setFilterDay(newFilter);
        onFilter(newFilter);
        let newWidth:string = '1180px';
        switch(newFilter) {
           case 'day':
                newWidth = '1180px';
                break;
            case 'week':
                newWidth = '0';
                break;
            case 'month':
                newWidth = '1300px';
                break;
        }
        setMinWidth(newWidth);
    };

    var timerId:any = null;

    React.useEffect(() => {
        setFilterDay(filters.timeline.filter);
    },[]);

    React.useEffect(() => {
        switch(filters.crossActions.timeLineEvent.action){
            case 'reset':
                setFilterDay('defaultFilter');
                break;
        }
    },[filters.crossActions.timeLineEvent]);

    React.useEffect(() => {
        setTotal(filters.counterResults);
        console.log('counterResults time line',filters.counterResults);
    },[filters.counterResults]);

    React.useEffect(() => {
        let rangeCorrection = 1;
        if(timeLineData.length === 0) return;
        let dataNum:number = timeLineData[0].data.length -1 ;// last index
        let initialTime  =  timeLineData[0].data[0].time;
        let finalTime = timeLineData[0].data[dataNum].time;

       
        let totalTime = finalTime - initialTime;

        let RangeBegin = range.begin - rangeCorrection;
        if(RangeBegin < 0 )RangeBegin = 0;

        let RangeEnd = range.end + rangeCorrection;
        if(RangeEnd >100 )RangeEnd = 100;

        let fromTime = initialTime + Math.floor( totalTime * RangeBegin /100);
        let toTime = initialTime + Math.ceil( totalTime * RangeEnd /100);
        
        setFilters({
            ...filters,
            form: {
                ...filters.form,
                from: Math.floor(fromTime/1000),
                to: Math.floor(toTime/1000)
            },
            timeline:{
                filter: filterday,
                from: Math.floor(fromTime/1000),
                to: Math.floor(toTime/1000),
                begin:range.begin,
                end : range.end
            }
        });
    },[range]);

    const changeSelected = (begin:number,end:number,active:boolean) => {
        if(!active) return;
        if(timerId) clearTimeout(timerId);
        timerId = setTimeout(() => {
            setRange({begin:begin,end:end});
        },700)
    };


    React.useEffect(() => {
        if(!filterday) return;
        if(filterday === 'defaultFilter'){
            setFilterDay('day');
            return;
        }
        console.log('effect filterdays on timeline',filterday);
        apiProvider.getTimeline(filterday)
        .then((response) => {
            let maxValue = 10;
            if(typeof response.timeline === 'undefined') return;
            response.timeline.map((item:any,index:number) => {
                if(item.y > maxValue) maxValue = item.y;
            });

            setMaxValue(maxValue+100);
            setTimeLineData(  [
                {
                    "id": "japan",
                    "color": '#006CE8',
                    "data": response.timeline
                }
            ]);

            setFilters({
                ...filters,
                form: {
                    ...filters.form,
                    to: response.to,
                    from: response.from
                },
                timeline: {
                    ...filters.timeline,
                    filter: filterday,
                    from: response.from,
                    to: response.to
                },
                crossActions:{
                    ...filters.crossActions,
                    timeLineEvent: {
                        action:'resetRange',
                        payload:{}
                    }
                }
            });
        });

    }, [filterday]);

    return (
        <Box display="flex" flexDirection="column">
            <Grid container alignItems="center" justifyContent={"space-between"} className={classes.topFilters}>
                <Grid xs={12} sm={6} container item alignItems="center" className={classes.buttonContainer}>
                    <Button
                        color={filterday === "day" ? "primary" : "secondary"}
                        variant="contained"
                        onClick={handleFilter("day")}
                        size="small"
                    >
                        {translate("timeline.filter.lastDay")}
                    </Button>
                    <Button
                        color={filterday === "week" ? "primary" : "secondary"}
                        variant="contained"
                        onClick={handleFilter("week")}
                        size="small"
                    >
                        {translate("timeline.filter.lastWeek")}
                    </Button>
                    <Button
                        color={filterday === "month" ? "primary" : "secondary"}
                        variant="contained"
                        onClick={handleFilter("month")}
                        size="small"
                    >
                        {translate("timeline.filter.lastMonth")}
                    </Button>
                    <InfoTimelineFilterIconButton />
                </Grid>
                <Grid xs={12} sm={6} container item justifyContent={"flex-end"} alignItems="center" className={classes.resultsContainer}>
                    <Box mr={2}>
                       <ClearTimelineFilterButton/>
                    </Box>
                </Grid>
            </Grid>
            <Scrollbar className={classes.timelineContainer} noScrollY={true}>
                <Box display="flex" marginBottom={5} marginTop={4} style={{ height: "150px", minWidth:minWidth }} className={classes.timelineBox}>
                    {
                        setTimeLineData.length > 0 &&
                        <TimelineChart data={timeLineData} maxValue={maxValue} changeSelected={changeSelected} initialBegin={rangeInitialBegin} initialEnd={rangeInitialEnd} />
                    }
                </Box>
            </Scrollbar>

        </Box>
    );
};

Timeline.defaultProps = {
    defaultFilter: "day",
};

export default Timeline;
