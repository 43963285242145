import React, { FunctionComponent } from "react";
import { Skeleton, SkeletonProps } from "@material-ui/lab";

const TimelineSkeleton: FunctionComponent<SkeletonProps> = (props: SkeletonProps) => (
    <Skeleton
        height={150}
        variant="rect"
        width="100%"
        {...props}
    />
);

TimelineSkeleton.defaultProps = {
    animation: "wave",
};

export default TimelineSkeleton;
