import React, { FunctionComponent } from "react";
import ResultsCounter from "./ResultsCounter";
import SocialCard from "./SocialCard";
import { ResourceList, SocialItem } from "../../service/data";
import { useSocialCardListStyles } from "./useSocialCardListStyles";
import {Box, Card, makeStyles} from "@material-ui/core";
import {Route} from "react-router";
import ShowNewContentButton from "../button/ShowNewContentButton";
import classNames from "classnames";
import Masonry from "react-masonry-component";
import {useContentStyles} from "../layout/useContentStyles";

interface SocialCardListProps {
    data: ResourceList<SocialItem>;
    page: number;
    pageSize: number;
    showCounter?: boolean;
    title?: string;
    total: number;
}

const SocialCardList: FunctionComponent<SocialCardListProps> = (props: SocialCardListProps) => {
    const classes = useSocialCardListStyles();
    const { data, page, pageSize, showCounter, total } = props;
    const masonryOptions = {
        transitionDuration: 0,
        itemSelector: '.masonryItem',
        gutter: 16,
        percentPosition: true,
    };


    return (
        <>
            <Route exact path={["/", "/contributors/:id","/tw/:id", "/embed/tw/:id"]}>
                <Box className={classNames(classes.list)}>
                    <ShowNewContentButton />
                    {
                        data.map(record => (
                            <SocialCard
                                key={record.id}
                                record={record}
                            />
                        ))
                    }
                </Box>
            </Route>

            <Route exact path="/timeline">
                <Box className={classNames(classes.list, 'masonryWrapper')}>
                    <ShowNewContentButton />
                    <Masonry
                        enableResizableChildren={true}
                        className={classNames(classes.masonryLayout, 'masonryContainer')} // default ''
                        elementType={'div'} // default 'div'
                        options={masonryOptions} // default {}
                        disableImagesLoaded={false} // default false
                        updateOnEachImageLoad={false} // default false and works only if disableImagesLoaded is false
                    >
                    {
                        data.map(record => (
                            <SocialCard
                                key={record.id}
                                record={record}
                            />
                        ))
                    }
                    </Masonry>
                </Box>
            </Route>

        </>
    );
};

SocialCardList.defaultProps = {
    showCounter: false,
};

export default SocialCardList;
