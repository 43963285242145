import React, { FunctionComponent } from "react";
import { Skeleton, SkeletonProps } from "@material-ui/lab";

const TopicChipSkeleton: FunctionComponent<SkeletonProps> = (props: SkeletonProps) => (
    <Skeleton
        variant="rect"
        height={32}
        width={96}
        style={{ borderRadius: 24 }}
        {...props}
    />
);

TopicChipSkeleton.defaultProps = {
    animation: "wave",
};

export default TopicChipSkeleton;
