import React, { useState } from "react";
import { useTranslate } from "../../service/translation";
import {
    Box,
    Button, ButtonGroup,
    Drawer, makeStyles,
} from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import { FilterForm } from "../layout";
import CloseIconButton from "./CloseIconButton";
import {FiltersContext,appDefaultFilters} from "../../service/filters";
import classNames from "classnames";

const useStyles = makeStyles(theme => ({
    filterFormContainer: {
        padding: theme.spacing(2, 2, 7),
    },
    closeButton: {
        position: "absolute",
        right: 6,
        top: 14,
        zIndex: 1,
        '& svg':{
            width: 20,
            height: 20,
            color:  theme.palette.type === "light" ? theme.palette.text.hint : theme.palette.text.hint,
        }
    },
    bottomButtonsContainer: {
        position: "fixed",
        width: "100%",
        bottom: 0,
        '& button':{
            width: "100%",
            borderRadius: "0 !important",
            fontSize: "14px",
        },
    },
    outlinedButton:{
        '&.MuiButton-root': {
            borderColor: theme.palette.type === "light" ? '#CCC' : theme.palette.text.hint,
            '&:hover': {
                backgroundColor: theme.palette.type === "light" ? 'white' : theme.palette.background.paper,
            },
        },
        fontWeight: 400,
        color: theme.palette.type === "light" ? theme.palette.text.hint : theme.palette.text.hint,
        backgroundColor: theme.palette.type === "light" ? theme.palette.background.paper : theme.palette.background.paper,

    },
    form:{
      backgroundColor: theme.palette.type === "light" ? theme.palette.background.paper : theme.palette.background.paper,
    },
    active:{
        "& .MuiBottomNavigationAction-label": {
            position: "relative",
            "&:before": {
                position: "absolute",
                top: 0,
                left: "100%",
                width: "5px",
                height: "5px",
                borderRadius: "50%",
                content: "''",
                backgroundColor: theme.palette.type === "light" ? theme.palette.primary.main : theme.palette.secondary.main,
            },
        },
    },
}));

export default function ToggleFilterBottomNavButton() {
    const classes = useStyles();
    const translate = useTranslate();
    const [open, setOpen] = useState(false);
    const [filters,setFilters] = React.useContext(FiltersContext);
    const [buttonClasses, setButtonClasses] = React.useState<any[]>([]);


    const handleToggle = () => setOpen(! open);
    const clearAllHandler = () => {

        setFilters({
            ...filters,
            crossActions:{
                ...filters.crossActions,
                clearSearchFilters:true,
            }
        });


    };

    React.useEffect(() => {
        let active = false;
        let fields = ['president','byCountry','byEparty','grouppress',
            'commission','eppress','eye','photos','onlyText','videos'];

        for(let i in fields){
            //@ts-ignore
            active = active || filters.form[fields[i]];
        }

        if(active) setButtonClasses([classes.active]);
        else setButtonClasses([]);

    },[filters.form]);

    const applyHandler = () => {
        setFilters({
            ...filters,
            crossActions:{
                ...filters.crossActions,
                applySearchFilters:true,
            }
        });
        setOpen(false);
    };

    const handleSubmit = (values: object): void => {};

    return (
        <>
            <BottomNavigationAction showLabel={true} onClick={handleToggle} label={translate("newshub.bottomBar.filters")} className={classNames( ...buttonClasses,'joyrideHomeXs01')} icon={<FilterListIcon />} />
            <Drawer

                variant="temporary"
                anchor={"bottom"}
                open={open}
            >
                <CloseIconButton onClick={handleToggle} className={classes.closeButton} />
                <Box className={classes.filterFormContainer}>
                    <FilterForm
                        onSubmit={handleSubmit}
                    />
                </Box>
                <ButtonGroup disableElevation variant="contained" color="primary" className={classes.bottomButtonsContainer}>
                    <Button onClick={clearAllHandler} variant="outlined" className={classes.outlinedButton}>{translate("newshub.action.clearAll")}</Button>
                    <Button onClick={applyHandler} >{translate("newshub.action.close")}</Button>
                </ButtonGroup>
            </Drawer>
        </>
    );
};

