import { ThemeOptions, createTheme } from "@material-ui/core";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import {createContext} from "react";
const breakpoints = createBreakpoints({
    values: {
        xs: 0,
        sm: 768,
        md: 1025,
        lg: 1200,
        xl: 1600
    }
});
const baseTheme :ThemeOptions= {
    typography: {
        fontSize: 16,
        fontFamily: 'Open Sans',
        h1: {
            fontSize: 20,
            fontWeight: 700,
        },
        h2: {
            fontSize: 16,
            fontWeight: 700,
            [breakpoints.down("sm")]: {
                fontSize: 12,
            },
            lineHeight: 1.5,
        },
        h3: {
            fontSize: 16,
            fontWeight: 700,
        },
        h4: {
            fontSize: 16,
            color: '#555555',
            textTransform: "none",
        },
        h5: {
            fontSize: 14,
        },
        h6: {
            fontSize: 14,
            color: '#555555',
            textTransform: "none",
        },
        overline: {
            fontSize: 20,
            fontWeight: 700,
        },
        subtitle1: {
            fontSize: 14,
        },
        subtitle2: {
            fontSize: 12,
            fontWeight: 400,
            color: '#555555',
            textTransform: "none",
            [breakpoints.down("sm")]: {
                fontSize: 12,
                lineHeight: "15px",
            },
        },
        body1: {
            fontSize: 16,
            [breakpoints.down("sm")]: {
                fontSize: 14,
            },
        },
        body2: {
            fontSize: 12,
            textTransform: "none",
        },
        button: {
            fontSize: 16,
            fontWeight: 700,
        },
        caption: {
            fontSize: 12,
        },

    },
    shape: {
        borderRadius: 10,
    },
    props: {
        MuiAppBar: {
            color: 'inherit',
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 768,
            md: 1025,
            lg: 1200,
            xl: 1600
        },
    },
};

const lightOptions:ThemeOptions =  {
    ...baseTheme,
    palette: {
        type: 'light',
        primary: {
            main: '#006CE8',
            dark: '#0052a3',
        },
        secondary: {
            main: '#f4cc47',
            dark: '#0d244a',
        },
        background: {
            default: '#f6f8fa',
            paper: '#ffffff',
        },
        text: {
            primary: '#050505',
            secondary: '#2e73ea',
            disabled: 'rgba(0,0,0,0.5)',
            hint: '#555555',
        },
        divider: '#f4cc47',
    },
    typography: {
        fontSize: 16,
        fontFamily: 'Open Sans',
        h1: {
            fontSize: 20,
            fontWeight: 700,
            color: '#0d244a',
            lineHeight: 1.5,
        },
        h2: {
            fontSize: 16,
            fontWeight: 700,
            color: '#0d244a',
            lineHeight: 1.5,
            [breakpoints.down("sm")]: {
                fontSize: 12,
            },
        },
        h3: {
            fontSize: 16,
            fontWeight: 700,
        },
        h4: {
            fontSize: 16,
            color: '#555555',
            textTransform: "none",
        },
        h5: {
            fontSize: 14,
        },
        h6: {
            fontSize: 14,
            color: '#555555',
            textTransform: "none",
        },
        overline: {
            fontSize: 20,
            fontWeight: 700,
        },
        subtitle1: {
            fontSize: 14,
        },
        subtitle2: {
            fontSize: 12,
            fontWeight: 400,
            color: '#555555',
            textTransform: "none",
            [breakpoints.down("sm")]: {
                fontSize: 12,
                lineHeight: "15px",
            },
        },
        body1: {
            fontSize: 16,
            [breakpoints.down("sm")]: {
                fontSize: 14,
            },
        },
        body2: {
            fontSize: 12,
            textTransform: "none",
        },
        button: {
            fontSize: 16,
            fontWeight: 700,
        },
        caption: {
            fontSize: 12,
        },

    },
};

const darkOptions:ThemeOptions = {
    ...baseTheme,
    palette: {
        type: 'dark',
        primary: {
            main: '#8ab4f8',
            dark: '#7b9ed8',
        },
        secondary: {
            main: '#f4cc47',
            dark: '#bdc1c6',
        },
        background: {
            default: '#202124',
            paper: '#303134',
        },
        text: {
            primary: '#fff',
            secondary: '#8ab4f8',
            disabled: 'rgba(0,0,0,0.5)',
            hint: '#bdc1c6',
        },
        divider: '#f4cc47',
    },
    typography: {
        fontSize: 16,
        fontFamily: 'Open Sans',
        h1: {
            fontSize: 20,
            fontWeight: 700,
            color: '#8ab4f8',
            lineHeight: 1.5,
        },
        h2: {
            fontSize: 16,
            fontWeight: 700,
            color: '#8ab4f8',
            lineHeight: 1.5,
            [breakpoints.down("sm")]: {
                fontSize: 12,
            },
        },
        h3: {
            fontSize: 16,
            fontWeight: 700,
        },
        h4: {
            fontSize: 16,
            color: '#bdc1c6',
            textTransform: "none",
        },
        h5: {
            fontSize: 14,
        },
        h6: {
            fontSize: 14,
            color: '#bdc1c6',
            textTransform: "none",
        },
        overline: {
            fontSize: 20,
            fontWeight: 700,
        },
        subtitle1: {
            fontSize: 14,
        },
        subtitle2: {
            fontSize: 12,
            fontWeight: 400,
            color: '#bdc1c6',
            textTransform: "none",
            [breakpoints.down("sm")]: {
                fontSize: 12,
                lineHeight: "15px",
            },
        },
        body1: {
            fontSize: 16,
            [breakpoints.down("sm")]: {
                fontSize: 14,
            },
        },
        body2: {
            fontSize: 12,
            textTransform: "none",
        },
        button: {
            fontSize: 16,
            fontWeight: 700,
        },
        caption: {
            fontSize: 12,
        },

    },
};


const lightTheme = createTheme(lightOptions);
const darkTheme = createTheme(darkOptions);
const ThemeContext = createContext<[any,Function]>([lightTheme,() => {}]);

export { darkTheme, lightTheme, ThemeContext}

//export default theme;
