import {roundCurrentTime} from '../tools/Tools';
import {appDefaultFilters} from './FiltersContext'
import {allowedContributorTypes} from '../data/apiProvider';

export type SocialParamsType = {
    [key: string]: string | number ;
}


function getFormParams (filtersForm:any): SocialParamsType {
    let params:SocialParamsType = {};

    let institutionChanels:string[] =[];

    for(let i in filtersForm){
        switch(i){
            case 'president':
                if(filtersForm['president']) params['contributorType']= ((typeof params['contributorType'] === 'undefined')? "" : params['contributorType'] + "," ) + 'president' ;
                break;
            case 'byCountry':
                if(filtersForm['byCountry']) params['country'] = filtersForm.countries.join(',');
                break;
            case 'byEparty':
                if(filtersForm['byEparty']) params['eParty'] = filtersForm.eParties.join(',');
                break;
            case 'from':
                if(filtersForm[i] && filtersForm[i]>0) params[i] = filtersForm[i];
                break;
            case 'to':
                params[i] = filtersForm[i];
                break;
            case 'photos':
                if(filtersForm['photos']) params['media']= ((typeof params['media'] === 'undefined')? "" : params['media'] +"," ) + 'photo' ;
                break;
            case 'onlyText':
                if(filtersForm['onlyText']) params['media'] = ((typeof params['media'] === 'undefined')? "" : params['media'] +"," ) + 'onlytext' ;
                break;
            case 'videos': 
                if(filtersForm['videos']) params['media']= ((typeof params['media'] === 'undefined')? "" :params['media'] + "," ) + 'video' ;
                break;
            case 'pageNo':
            case 'pageSize':
                params[i] = filtersForm[i];
                break;
            case 'grouppress':
                if(filtersForm[i]) {
                    institutionChanels.push('grouppress');
                    //institutionChanels.push('politicalgroups');
                }
                break;
            case 'commission':
                if(filtersForm[i]) institutionChanels.push('commission');
                break;
            case 'eppress':
                if(filtersForm[i]) institutionChanels.push('eppress');
                break;
            case 'eye':
                if(filtersForm[i]) institutionChanels.push('mep');
                break;

            case 'itemId':
                if(filtersForm[i] && window.location.pathname.indexOf('/tw/') !== -1) params['id'] = filtersForm[i];
        }
    }

    if(institutionChanels.length > 0 ) params['contributorType'] = institutionChanels.join(',');

    //set default contributorTypes
    if(typeof params['contributorType'] === 'undefined'){
        params['contributorType'] = allowedContributorTypes.join(',');
    }
 
    return params;
}

function applySearchBarFilter (params:SocialParamsType, searchBar:string): SocialParamsType{
    let newParams:SocialParamsType = {...params};

    if(searchBar.length > 0){
        searchBar = searchBar.split(' ').filter(item => item.length > 0).join('|');
    }

    newParams['search-value'] =  searchBar;
    newParams['search-type']  =  params['search-type'] || 'all';
   
    return newParams;
}


function applyActiveTopicFilters (params:SocialParamsType, activeTopics:string[]): SocialParamsType {
    let newParams = {...params};
    if(activeTopics && activeTopics.length > 0) {
        let items:string[] = [...activeTopics.map( item => '#'+item)] ;
        if((''+ params['search-value']).length > 0) items.push(''+ params['search-value']);
        
        newParams['search-value'] = items.join('|');
        newParams['search-type'] = 'all';
    }
    
    return newParams;
}

function applyAdvancedSearchFilters(params:SocialParamsType,advancedSearchFilters:any ) {
    let newParams =  {
        ...params,
    };
    let anyWords = advancedSearchFilters.anyWords.filter((item:any,i:number) => item.length > 0);
    let hashtags = advancedSearchFilters.hashtags.filter((item:any,i:number) => item.length > 0);
    let mentions = advancedSearchFilters.mentions.filter((item:any,i:number) => item.length > 0);

    let searchText = advancedSearchFilters.allWords + '|' + (params.searchText || '');
   
    if(anyWords.length > 0) searchText+= '|' + anyWords.join('|');
    if(hashtags.length > 0) searchText+= '|'+ hashtags.map((item:any,i:number) => {
        return (item.indexOf('#') === -1)? '#'+item : item; 
    }).join('|');
    if(mentions.length > 0) searchText+= '|'+ mentions.map((item:any,i:number) => {
        return (item.indexOf('@') === -1)? '@'+item : item; 
    }).join('|');

    searchText = ((searchText.split('|')).filter( (w:string,i:number) => (w.length) > 0)).join('|');
    
    
    if (searchText.length > 0) newParams['search-value'] = searchText;
    if(advancedSearchFilters.to && advancedSearchFilters.to !== appDefaultFilters.advancedSearch.filters.to) newParams['to'] = advancedSearchFilters.to;
    if(advancedSearchFilters.from && advancedSearchFilters.from  !== appDefaultFilters.advancedSearch.filters.from) newParams['from'] =  advancedSearchFilters.from;

    return newParams;
}




function applyAndWordsFilters(params:SocialParamsType,advancedSearchFilters:any ) {
    let newParams =  {
        ...params,
    };
    let andWords = advancedSearchFilters.andWords.filter((item:any,i:number) => item.length > 0);
    if(andWords.length === 0) return newParams;

    
    if(advancedSearchFilters.allWords.length > 0){
        andWords.push(advancedSearchFilters.allWords);
    }

    delete newParams['search-value'];
    newParams['search-and'] = andWords.join('|');

    return newParams;
}

function applyExcludeFilters(params:SocialParamsType,advancedSearchFilters:any ) {
    let newParams =  {
        ...params,
    };
    let excludeWords = advancedSearchFilters.excludeWords.filter((item:any,i:number) => item.length > 0);
    if(excludeWords.length === 0) return newParams;

    newParams['search-not'] = excludeWords.join('|');

    return newParams;
}


function applyTimeLineFilters(params:SocialParamsType,timeLineFilters:any ) {
    let newParams =  {
        ...params,
    };

    newParams['to'] =  timeLineFilters.to;
    newParams['from'] =  timeLineFilters.from;


    return newParams;
}





export function MapSocialParams(filters:any): SocialParamsType {
    console.log('FILTERS TO API',filters);
    let params:SocialParamsType = {...getFormParams(filters.form)};
    
    params = applySearchBarFilter(params,filters.searchBar);
    console.log('params SearchBar', params);
    
    params = applyActiveTopicFilters(params,filters.activeTopics);
    console.log('params topic', params);
    
    params = applyAdvancedSearchFilters(params,filters.advancedSearch.filters);
    console.log('params advanced', params);

    if(window.location.pathname.indexOf('timeline') !== -1){
        params = applyTimeLineFilters(params,filters.timeline);
        console.log('params timeline', params);
    }
    
    params = applyAndWordsFilters(params,filters.advancedSearch.filters);
    console.log('params and', params);
    
    params = applyExcludeFilters(params,filters.advancedSearch.filters);
    console.log('params exclude', params);


    ////for efective cache add  always a roud time
    if(!params.to || params.to <= 0) params.to = roundCurrentTime(2);

    return params;
}