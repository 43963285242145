const messages = {
    cookies: {
        message:{
            title: "Use of cookies by the European Parliament Newshub",
            description1A: "Cookies are small text files that are placed on your computer by websites that you visit. They are widely used in order to make websites work, or work more efficiently, as well as to provide information to the owners of the site. The table below explains the cookies we use and why.",
            tableHead1: "Cookie",
            tableHead2: "Name",
            tableHead3: "Purpose",
            tableHead4: "Duration",
            tableRow1A: "Cookie preference",
            tableRow1B: "europarlcookiepolicysagreement",
            tableRow1C1: "This cookie is used to remember a user’s choice about cookies on ",
            tableRow1CLink: "epnewshub.eu",
            tableRow1CUrl: "https://www.epnewshub.eu/",
            tableRow1C2: ". Where users have previously indicated a preference, that user’s preference will be stored in this cookie.",
            tableRow1D: "6 months",
            tableRow2A: "At Internet",
            tableRow2B: "Atidvisitor",
            tableRow2C: "Used to monitor the performance of pages visited by users of Parliament's websites.",
            tableRow2D: "13 months",
            tableRow3A: "",
            tableRow3B: "Idrxvr",
            tableRow3C: "Used to monitor traffic and highlight issues that may arise by people browsing our websites.",
            tableRow3D: "13 months",
            tableRow4A: "",
            tableRow4B: "Atredir",
            tableRow4C: "Used to keep of information in case of a JS redirection.",
            tableRow4D: "13 months",
            tableRow5A: "",
            tableRow5B: "Atuserid",
            tableRow5C: "Used to store the visitor anonymous ID on the Parliament's websites.",
            tableRow5D: "13 months",
            tableRow6A: "",
            tableRow6B: "Atreman",
            tableRow6C: "Used to keep a prior attribution campaign in memory.",
            tableRow6D: "30 days",
            tableRow7A: "",
            tableRow7B: "atsession",
            tableRow7C: "Used to record the list of new campaigns encountered during a visit, in order to avoid measuring the same campaign several times.",
            tableRow7D: "30 minutes",
            subtitle2: "How do I change my cookie settings?",
            description2A: "You can change your cookie preferences at any time by clicking “Cookie management” in the footer of the website, changing your preferences, then clicking ‘Save and close’. You may need to refresh your page for your new cookie preferences to take effect.",
            description2B: "Alternatively, most web browsers allow some control of most cookies through the browser settings.",
            description2C: "Find out how to manage cookies on popular browsers:",
            description2ListA: "Google Chrome",
            description2ListALink: "https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&hl=en",
            description2ListB: "Microsoft Edge",
            description2ListBLink: "https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy",
            description2ListC: "Apple Safari",
            description2ListCLink: "https://support.apple.com/en-gb/safari",
            description2ListD: "Mozilla Firefox",
            description2ListDLink: "https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences",
            description2ListE: "Microsoft Internet Explorer",
            description2ListELink: "https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies",
            description2ListF: "Opera",
            description2ListFLink: "https://www.opera.com/help/tutorials/security/privacy/",
            subtitle3: "At Internet",
            description3A: "AT Internet is the web analytics tool we selected to monitor our websites for statistical purposes.",
            description3subtitle: "AT Internet data protection policy",
            description3B: "We have configured AT Internet  to limit the collection of personal data of our users as much as possible. For instance, we have implemented the Internet Protocol (IP) address de-identification by automatically truncating the last octet (the last 3 digits) of each visitor's IP address, making it impossible to identify a visitor via their sole IP address.",
            description3C: "The only data we track is with the aim of improving our websites via aggregated and anonymous statistics reports on visitors’ activities and including the following data:",
            description3ListA: "IP address (anonymised)",
            description3ListB: "Sources (types, campaigns, domains, …)",
            description3ListC: "Contents being viewed (title of the page, editorial pages settings, path of pages, URL and canonical URL, language, ...)",
            description3ListD: "Geolocation (country, region, town)",
            description3ListE: "Technical profiles (devices, OS, browsers, screen size of user, ...)",
            description3ListF: "Periods (date and time of the visit to the site, user’s time zone)",
            description3ListG: "Campaigns",
            description3ListH: "Site search",
            description3ListI: "Events (clicks on our contents, on action buttons, ...)",
            description3ListJ: "Files that were clicked and downloaded",
            description3ListK: "Domains (links to an external website, referrer website)",
            description3D: "To opt out of being tracked by AT Internet on European Parliament Newshub, follow this link: ",
            description3DLink: "Loremipsum",
            description3DUrl: "https://loremipsum.com",
            cookiesWidgetAccepted: "Based on the information at our disposal, it seems you accepted our analytics cookies. If you change your mind, you may activate the following choice:",
            cookiesWidgetRefused: "Based on the information at our disposal, it seems you refused our analytics cookies. If you change your mind, you may activate the following choice:",
            cookiesWidgetNeither: "Based on the information at our disposal, it seems you neither accepted nor refused our analytics cookies. Consequently, we did not place any on your device. Could you please explicitly express your choice:",
            cookiesWidgetRadio1: "I refuse analytics cookies",
            cookiesWidgetRadio2: "I accept analytics cookies",
        },
    },
    privacy: {
        message:{
            title: "The European Parliament Newshub Privacy Statement",
            subtitle1: "1. Introduction",
            description1A: "Since 11 December 2018, the protection of individuals with regard to the processing of personal data by the Union institutions is governed by Regulation (EU) 2018/1725 of the European Parliament and of the Council of 23 October 2018[1]. This Regulation follows the same principles and rules as those established in the General Data Protection Regulation[2].",
            description1B: "We provide you with the information that follows based on Articles 15 and 16 of Regulation (EU) 2018/1725.",
            subtitle2: "2. Why do we process your data?",
            description2A: "The European Parliament Newshub (EP Newshub) is a platform that gathers the social media activity of the members of the European Parliament, the liaison offices, the committees, the political groups and the European Parliament (those are the contributors). It allows citizens to consult this social media activity from different platforms of the European Parliament (EP Newshub website, European Parliament website, Parlamentarium). At the same time, the European Parliament Newshub allows the contributors to add their social media channels for which they want to share the content.",
            description2B: "The collection and further processing of your personal data is necessary to provide the following functionalities:",
            description2ListA: "Gather the social media activity",
            description2ListB: "Display the social media activity",
            description2ListC: "Manage the link to the social media account from where the activity will be gathered",
            description2ListD: "Statistical and analytical data collection",
            description2ListE: "Secure and maintain the platform",
            description2ListF: "Collect feedback about the online platform from the users",
            subtitle3: "3. What is the legal basis for the processing?",
            description3A: "Article 5, Paragraph 1. point a) of Regulation (EU) 2018/1725. The tasks related to the European Parliament’s institutional information and communication campaign are carried out in the public interest and are based on the Strategic Execution Framework Social Media in Post-Covid Times, of the Secretariat-General of the European Parliament.",
            description3B: "Article 5(1)(d) of Regulation (EU) 2018/1725 of the European Parliament and of the Council of 23 October 2018 on the protection of natural persons with regard to the processing of personal data by the Union institutions, bodies, offices and agencies and on the free movement of such data: the data subject has given consent to the processing of their personal data for one or more specific purposes.",
            subtitle4: "4. Who is responsible for the processing of your data (controller)?",
            description4A: "Your data is processed under the responsibility of the Web Communication Unit from the Directorate for Campaigns of the Directorate-General for Communication of the European Parliament represented by the Head of Unit of the Web Communication Unit.",
            description4B: "You can contact the controller at ",
            description4mail: "DGCOMM-data-analysts@europarl.europa.eu",
            description4mailto: "mailto:DGCOMM-data-analysts@europarl.europa.eu",
            subtitle5: "5. What personal data do we collect?",
            description5A: "The information collected will depend on the type of the user which are defined as the following:",
            description5subtitle1: "Visitors",
            description5B: "For all the visitors that are browsing the website only the IP address can be collected for security and maintenance reason, the IP address will be keep in the log for a maximum of 30 days. The data collected are:",
            description5C1: "First name",
            description5C2: "Last name",
            description5C3: "Email",
            description5C4: "Feedback",
            description5subtitle2: "Members of the European Parliament",
            description5D: "Below is the information that we may collect:",
            description5listSeparator1: "Required",
            description5ListA: "Name and Surname",
            description5ListB: "Email",
            description5ListC: "Nationality",
            description5ListD: "Political Party",
            description5ListE: "Picture",
            description5listSeparator2: "Optional",
            description5ListF: "Social media link(s)",
            description5ListG: "Social media activity",
            description5listSeparator3: "EP staff",
            description5ListH: "Working email address",
            subtitle6: "6. Who has access to your personal data and to whom is it disclosed?",
            description6A: "Access to your personal data is provided to the authorised personnel of the European Parliament and its contractors responsible for carrying out this processing operation according to the “need to know” principle. Such staff abide by statutory, and when required, additional confidentiality agreements.",
            description6B: "Mandated staff of the European Parliament services involved in the EP Newshub project have access to personal data collected via a dedicated admin interface platform for the purpose of the operation of the project as well as for follow-up action that might be necessary for further processing purposes.",
            description6C: "The European Parliament IT team will have access to the data that is strictly necessary to provide an appropriate technical and logistical support (e.g. helpdesk, management of access rights, maintenance of the platform).",
            description6D: "Administrator users will have access to all the personal data in order to manage accounts.",
            description6E: "The European Parliament does not transfer any data to third parties which are outside these recipients. It does not share personal data with third parties for direct marketing.",
            description6F: "Only if requested by law, or in case of an audit or judicial procedures, could your data be transferred to the competent authorities (European Court of Auditors, Court of Justice of the European Union, European Data Protection Supervisor, and European Anti-Fraud Office (OLAF)).",
            description6G: "This website may contain links to other websites. Since the European Parliament has no control over such sites, we recommend you to review the Privacy Policies of these websites.",
            subtitle7: "7. For how long is your personal data kept?",
            description7A: "The retention period will depend on the data and the profile of the data subject.",
            description7subtitle1: "Visitors",
            description7B: "All the visitors of the website will have their IP address processed. After 30 days, the logs containing these data will be deleted.In the case of Feedback form users, the First name, Last name, Email, and Feedback content is kept for a maximum of 90 days.",
            description7subtitle2: "Member of the European Parliament",
            description7D: "The information collected will be kept for the duration of their mandate in the European Parliament or until there is a data subject request to delete their data.",
            description7subtitle3: "EP staff",
            description7E: "The information is stored for as long as the user needs to have access to the administration interface.",
            description7subtitle4: "If personal data have not been obtained by us, what is their source?",
            description7F: "As specified at point 4, part of the personal data have been collected under the responsibility of DGITEC and are transmitted via Codict web service.",
            subtitle8: "8. What are your rights when we process your personal data?",
            description8A: "You have specific rights as a ‘data subject’ under Chapter III (Articles 14-25) of Regulation (EU) 2018/1725. As regards this processing operation, you can exercise the following rights:",
            description8ListA: "the right to access your personal data",
            description8ListB: "the right to rectification in the case that your personal data is inaccurate or incomplete",
            description8ListC: "the right to erasure of your personal data",
            description8ListD: "where applicable, the right to restrict the processing of your personal data",
            description8ListE: "the right to data portability",
            description8ListF: "and the right to object to the processing of your personal data, which is lawfully carried out pursuant to Article 5(1)(a).",
            description8ListG: "If you have provided your consent for the present processing operation, you can withdraw it at any time by notifying the Data Controller. The withdrawal will not affect the lawfulness of the processing carried out before you have withdrawn your consent.",
            description8B: "Data subjects who wish to exercise their rights under Regulation (EU) 2018/1725 can send an email to ",
            description8mail: "DGCOMM-data-analysts@europarl.europa.eu",
            description8mailto: "mailto:DGCOMM-data-analysts@europarl.europa.eu",
            subtitle9: "9. Recourse",
            description9A: "Your first point of contact is ",
            description9mailA: "DGCOMM-data-analysts@europarl.europa.eu",
            description9mailtoA: "mailto:DGCOMM-data-analysts@europarl.europa.eu",
            description9B1: "You may contact, at any time, the Data Protection Officer of the European Parliament ",
            description9mailB: "Data-Protection@europarl.europa.eu",
            description9mailtoB: "mailto:Data-Protection@europarl.europa.eu",
            description9B2: " if you have any concerns/complaints about the processing of your personal data.",
            description9C: "The Data Protection Officer of the European Parliament ensures the internal application of Regulation (EU) 2018/1725.",
            description9D: "Data Protection Officer",
            description9E: "European Parliament",
            description9F: "2, rue Alcide De Gasperi",
            description9G: "L-1615 - Luxembourg",
            description9H1: "You have the right to lodge a complaint with the European Data Protection Supervisor ",
            description9mailC: "edps@edps.europa.eu",
            description9mailtoC: "mailto:edps@edps.europa.eu",
            description9H2: " at any time concerning the processing of your personal data.",
            description9I: "The European Data Protection Supervisor (EDPS) is acting as an independent supervisory authority. The EDPS makes sure that all EU institutions and bodies respect people's right to privacy when processing their personal data.",
            references1: "[1] Regulation (EU) 2018/1725 of the European Parliament and of the Council of 23 October 2018 on the protection of natural persons with regard to the processing of personal data by the Union institutions, bodies, offices and agencies and on the free movement of such data, and repealing Regulation (EC) No 45/2001 and Decision No 1247/2002/EC.",
            references2: "[2] Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data.",
        },
    },
    newshub: {
        description: "EP Newshub is a real time news aggregator gathering all items published online by Members, political groups and news services from the European Parliament.",
        action: {
            advancedsearch: "Advanced Search",
            anchor: "Permalink",
            apply: "Apply",
            back: "Back",
            chooseFiles: "Choose files",
            clear: "Clear",
            clearAll: "Clear all",
            clearAllFilters: "Clear all filters",
            close: "Close",
            copied: "copied to clipboard",
            facebook: "Facebook",
            filter: "Filter",
            formSended: "Feedback message sent",
            goBack: "Back",
            email: "Email",
            embed: "Embed",
            instagram: "Instagram",
            next: "Next",
            prev: "Previous",
            done: "Done",
            refresh: "Refresh",
            rss: "RSS Feed",
            search: "Search",
            send: "Send",
            share: "Share",
            showNewContent: "Show new content",
            signal: "Signal",
            signalCopied: "Copied to clipboard; share this link on your Signal app",
            skipToContent: "Skip to main content",
            switchTheme: "Switch theme",
            telegram: "Telegram",
            toggleMenu: "Toggle menu",
            translateContent: "Translate content",
            hideTranslation: "Hide translation",
            twitter: "Twitter",
            viewAll: "View all",
            viewChannel: "View",
            visitSource: "Visit source",
            whatsapp: "WhatsApp",
            www: "WWW",
            mainSearch: "Exact words or phrase",
            mainSearchPlaceholder: "Enter the exact words or phrase",
            includeWords: "ANY of these words",
            includeWordsPlaceholder: "Include ANY of these words in the search",
            withHashtags :"Search #hashtags",
            withHashtagsPlaceholder: "Search results containing these #hashtags",
            excludeWords: "Exclude words",
            excludeWordsPlaceholder: "Search results without these words",
            allWords: "All these words",
            mentions: "Search @mentions",
            mentionsPlaceholder: "Search results containing these @mentions",
            allWordsPlaceholder:"Search results with all these words",
            clearAllTimeLine: "Clear selection"
        },
        error:{
            error404:{
                title:"404",
                description1: "The page you’re trying to visit doesn’t exist or has changed",
                description2: "Please visit the ",
                link: "Homepage",
                description3: " to continue",
            },
            error503:{
                title:"503",
                description1: "Service unavailable",
                description2: "Please try again later",

            },
            notFoundContent:{
                title:"No results",
                description1: "There where no items matching your criteria.",
            },
            notFoundContentTips:{
                title:"Search tips",
                tip1: "Check your spelling",
                tip2: "Try searching by topic or channel",
                tip3: "Broaden your search by using few words or more general terms",
                tip4: "You can also make use of the ",
                link: "Timeline",
            },

            notFoundContentHome: {
                title:"Sorry, we couldn’t find any results for your search",
            },

            notFoundContentTipsHome:{
                title:"Here are a few search tips:",
                tip1: "Broaden your search by using fewer or less specific terms",
                tip2: "Check the keywords for spelling",
                tip3: "Alternatively, you can try out the ‘Advanced Search’ or use the ‘Trending topics’ and ‘Filters’ features. You can also browse content using the %{link1} or look-up contributors on the %{link2} page",
                link1: "Timeline",
                link2: "Channels"

            },

            notFoundContentTimeline: {
                title:"Sorry, we couldn’t find any results for your search",
            },

            notFoundContentTipsTimeline:{
                title:"Here are a few search tips:",
                tip1: "Expand the time/date range selected",
                tip2: "Broaden your search by using fewer or less specific terms",
                tip3: "Check the keywords for spelling ",
                tip4: "Alternatively, you can try out the ‘Advanced Search’ or use the ‘Trending topics’ and ‘Filters’ features.",
            },

            notFoundContentChannels: {
                title:"Sorry, we couldn’t find any channel with that name",
                description1: "Please check the keywords for spelling or filter channels using the top navigation bar.",
            },

            ApiErrorContent:{
                title:"Something went wrong",
                description1: "Try refreshing the page to see if things are back in order.",
                button: "Reload",
            },
        },
        bottomBar: {
            top: "Top",
            topics: "Topics",
            filters: "Filters",
        },
        channel: {
            country: "By country",
            politicalGroup: "By political group",
            president: "President",
        },
        filter: {
            channels: "MEP Channels",
            currentFilters: "Filters",
            institutions: "Institution Channels",
            mediaTypes: "Media Types",
        },
        institution: {
            accounts: "accounts",
            committees: "Committees",
            liaisonOffices: "EP Liaison Offices",
            politicalGroups: "Political Groups",
            eppress: "EP Accounts",
            commission: "Committees",
            eye: "Eye",
            grouppress: "Political Groups",
            parlementariumTwitteroPolis: "Parlementarium Twitter o Polis",
            president: "President",
            meps: "MEPs"

        },
        mediaType: {
            photo: "Photos",
            text: "Text only",
            video: "Videos",
        },
        menu: {
            channels: "Channels",
            help: "Help",
            home: "Home",
            timeline: "Timeline",
            darkmode: "Dark mode",
            lightmode: "Light mode",
            tutorial: "Tutorial",
            privacyPolicy: "Privacy statement",
            legalNote: "Cookies policy",
            sendFeedback: "Send feedback",
            claim:{
                title: "European Parliament's Newshub",
                description: "aggregates information from the Parliament's President, MEPs, political groups and news services in a single place. Elected every five years, Parliament is the EU's only directly-elected body.",
            },
        },
        pagination: {
            results: "Showing %{total} results",
            resultsOne: "Showing %{total} result"
        },
        search: {
            suggestedWords: "Suggested words",
            suggestedChannels: "Suggested channels",
            oldSearches:"Previous searches"
        },
        validation: {
        },
    },
    social: {
        action: {
            twitter: "Posted a tweet",
        },
        message: {
            postedBy: "Posted by %{firstName} %{lastName}",
        },
    },
    sendFeedback: {
        label: {
            name: "Name",
            lastName: "Last name",
            email: "Email",
            comments: "Comments",
        },
        placeholder: {
            name: "Enter name",
            lastName: "Enter last name",
            email: "Enter email",
            comments: "Briefly explain what the issue is or if something was not working as expected",
        },
        message: {
            acceptPolicy1: "I accept the ",
            acceptPolicy2: "Privacy statement",
            acceptPolicy3: " and the ",
            acceptPolicy4: "Terms of use",
        },
        snackbar: {
            message: "A message has been sent to our support team",
        },
        email: {
            notValid:"It must be a valid email "
        }
    },
    timeline: {
        filter: {
            lastDay: "24 hours",
            lastWeek: "7 days",
            lastMonth: "30 days",
        },
        tooltip: "The timeline allows you to select results for a specific period of time. The chart is displayed in a 24-hour, 7-day, 30-day format and the results can be narrowed using the selector. You can adjust the selection using the side arrows or you can move it left or right by dragging it. To return to the default view, use the ‘Clear all’ button."
    },
    topic: {
        message: {
            title: "Trending topics",
        },
    },
    tutorial: {
        title: {
            step0: "Get started with Filters",
            step1: "Boosting your Search",
            step2: "Dynamic Timeline",
            step3: "Channels",
            step4: "Topics at your fingertips",
        },
        message: {
            step0: "Use the Filters to quickly find results for specific Channels or Media type. Combine multiple options for a more accurate result. Once applied, they remain active throughout the session; simply hit the ‘Clear all’ to start over.",
            step1: "Searching on Newshub is now more intuitive as it provides suggestions for keywords and channels as soon as you start typing your query. In addition, the advanced search module allows you to define key parameters to ensure you find the most relevant content.",
            step2: "The Timeline allows you to select results for a specific period. The chart is displayed in a 24-hour/ 7-day/ 30-day format and the results can be narrowed or expanded using the selector. You can adjust the selection using the side arrows or move it by dragging left or right. Return to the default view using the ‘Clear all’ button.",
            step3: "All EP Newshub contributors are available on the Channels page, which allows you to browse through the list of MEPs, Political Groups, Committees and more. Click ‘View’ button to see details for each contributor and their recent activity.",
            step4: "The most popular and recent topics are displayed on all content pages, including individual channels or specific timeline. You can filter results relative to one or more topics by simply clicking on them.",
        },
        homeTutorial: {
            title: {
                step0: "Welcome to the EP Newshub!",
                step1: "Get started with Filters",
                step2: "Advanced Search",
                step3: "Dynamic Timeline",
                step4: "Channels",
            },
            message: {
                step0: "The only platform that aggregates real time information from the Parliament President, MEPs, political groups and news services in a single place.\n" +
                    "Check out the latest results or use the intuitive search function to find the most relevant content and contributors.\n",
                step1: "Use the Filters to narrow down results for specific Channels, Countries or Media type.\n" +
                    "Combine multiple options for an even more accurate result.\n" +
                    "Once applied, they remain active throughout the session; simply hit \"Clear all\" to start over.\n",
                step2: "Every time you perform a search, the advanced module is enabled to allow you to fine-tune various parameters.\n" +
                    "To narrow-down the results, combine these options to include or exclude words, search #hashtags or @mentions and to set the date range (maximum 30 days backwards)\n",
                step3: "The Timeline offers a great perspective on the evolution of the results and gives you various options to select a specific range.\n" +
                    "Toggle between 24-hour/ 7-day/ 30-day pre-sets.\n" +
                    "Use the selector to narrow or expand the selection range as you wish by using the side arrows or by dragging left or right.\n",
                step4: "All EP Newshub contributors are now available in the dedicated Channels section.\n" +
                    "Browse through categories - President, MEPs, Political Groups, Committees and our News services - or use the search on top.\n" +
                    "Click on the View button to see details for each contributor and their recent activity.\n",
            },
        },
        timelineTutorial: {
            title: {
                step0: "Dynamic Timeline",
                step1: "Get starting with Filters",
                step2: "Advanced Search",
                step3: "Topics at your fingertips",
            },
            message: {
                step0: "The Timeline offers a great perspective on the evolution of the results and gives you various options to select a specific range.\n" +
                    "Toggle between 24-hour/ 7-day/ 30-day pre-sets.\n" +
                    "Use the selector to narrow or expand the selection range as you wish by using the side arrows or by dragging left or right.\n",
                step1: "Use the Filters to narrow down results for specific Channels, Countries or Media type.\n" +
                    "Combine multiple options for an even more accurate result.\n" +
                    "Once applied, they remain active throughout the session; simply hit \"Clear all\" to start over.\n",
                step2: "Every time you perform a search, the advanced module is enabled to allow you to fine-tune various parameters.\n" +
                    "To narrow-down the results, combine these options to include or exclude words, search #hashtags or @mentions and to set the date range (maximum 30 days backwards)\n",
                step3: "The most popular and recent topics are displayed on all content pages, including individual channels or specific timeline. You can filter results relative to one or more topics by simply clicking on them.",
            },
        },
        channelsTutorial: {
            title: {
                step0: "Channels",
                step1: "Get starting with Filters",
                step2: "Advanced Search",
                step3: "Topics at your fingertips",
            },
            message: {
                step0: "All EP Newshub contributors are now available in the dedicated Channels section.\n" +
                    "Browse through categories - President, MEPs, Political Groups, Committees and our News services - or use the search on top.\n" +
                    "Click on the View button to see details for each contributor and their recent activity.\n",
                step1: "Use the Filters to narrow down results for specific Channels, Countries or Media type.\n" +
                    "Combine multiple options for an even more accurate result.\n" +
                    "Once applied, they remain active throughout the session; simply hit \"Clear all\" to start over.\n",
                step2: "Every time you perform a search, the advanced module is enabled to allow you to fine-tune various parameters.\n" +
                    "To narrow-down the results, combine these options to include or exclude words, search #hashtags or @mentions and to set the date range (maximum 30 days backwards)\n",
                step3: "The most popular and recent topics are displayed on all content pages, including individual channels or specific timeline. You can filter results relative to one or more topics by simply clicking on them.",
            },
        },
    },
};

export default messages;
