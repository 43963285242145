import React from "react";
import ToggleThemeIcon from "@material-ui/icons/Brightness4";
import {IconButton, IconButtonProps, Tooltip, makeStyles} from "@material-ui/core";
import { useTranslate } from "../../service/translation";

const useStyles = makeStyles(theme => ({
    root: {

    },
    button: {

    },
}), { name: "NewshubSwitchThemeIconButton" });

const SwitchThemeIconButton = (props: IconButtonProps) => {
    const classes = useStyles();
    const translate = useTranslate();

    return (
        <IconButton aria-label={translate("newshub.action.switchTheme")} className={classes.button} {...props}>
            <Tooltip placement="right" title={translate("newshub.action.switchTheme")}>
                <ToggleThemeIcon />
            </Tooltip>
        </IconButton>
    )
};

export default SwitchThemeIconButton;
