import { RedirectionSideEffect } from "./RedirectionSideEffect";
import { put, takeEvery } from "redux-saga/effects";
import { push } from "connected-react-router";
import { refreshView } from "../../refresh";

interface ActionWithSideEffect {
    type: string;
    payload?: any;
    requestPayload?: any;
    meta: {
        redirectTo: RedirectionSideEffect;
        path?: string;
    };
}

function* handleRedirection({ payload, requestPayload, meta: { path, redirectTo }}: ActionWithSideEffect) {
    if (! redirectTo) {
        yield put(refreshView());
    } else {
        yield put(push(typeof redirectTo === "function" ? redirectTo(path || "", payload) : redirectTo));
    }
}

const redirectionSaga = function* () {
    // @ts-ignore
    yield takeEvery(action => action.meta && typeof action.meta.redirectTo !== "undefined", handleRedirection);
};

export { redirectionSaga };
