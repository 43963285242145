import React, {FunctionComponent, SyntheticEvent} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import {FiltersContext} from "../../service/filters"

import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    FormHelperText,
    FormControl,
    FormGroup,
    Grid,
    ListItemText,
    TextField,
    Typography,
} from "@material-ui/core";

import {Link} from "react-router-dom";
import {useTranslate} from "../../service/translation/hooks";
import CloseIconButton from "./CloseIconButton";
import {apiProvider} from "../../service/data/apiProvider";
import { json } from 'd3-fetch';
import classNames from "classnames";


function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.type === "light" ? theme.palette.background.paper : theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2),
        maxWidth: '380px',
        width: '100%',
        borderRadius: '10px',
    },
    closeButton: {
        marginRight: '-8px',
        '& svg':{
            color: theme.palette.type === "light" ? theme.palette.text.hint : theme.palette.text.hint,
        },
    },
    checkboxLabel: {
        '& span': {
            color: theme.palette.type === "light" ? theme.palette.text.hint : theme.palette.text.hint,
            fontSize: '12px',
        },
    },
    formContainer: {
        "& label": {
            transform: "none !important",
            fontSize: "12px",
            fontWeight: "bold",
            color: theme.palette.type === "light" ? theme.palette.text.hint : theme.palette.text.hint,
            position: "static",
            marginBottom:theme.spacing(1),
        },
        "& legend": {
            display: "none",
        },
        "& fieldset": {
            top: 0,
            borderColor: theme.palette.type === "light" ? '#CCC' : theme.palette.text.hint,
        },
        "& input[type='text']": {
            height: "40px",
            padding: theme.spacing(0,2),
            fontSize: "12px",
        },
        "& textarea": {
            height: "90px !important",
            fontSize: "12px",
        },
        '& .MuiCheckbox-root':{
            color: theme.palette.type === "light" ? theme.palette.text.hint : theme.palette.text.hint,
            '&.Mui-checked': {
                color: theme.palette.type === "light" ? theme.palette.primary.main : theme.palette.primary.main,
                '& svg':{
                    stroke: 'transparent',
                    color: theme.palette.type === "light" ? theme.palette.primary.main : theme.palette.primary.main,
                },
            },
            '& svg':{
                stroke: theme.palette.type === "light" ? theme.palette.background.paper : theme.palette.background.paper,
            },
        },
        "& a": {
            color: theme.palette.type === "light" ? theme.palette.primary.main : theme.palette.primary.main,
        },
    },
    expandGrid:{
        margin: theme.spacing(0,-1),
    },
    input: {
        display: "none",
    },
    inputFileName: {
        position: "absolute",
        left: 0,
        top: "50%",
        transform: "translateY(-50%)",
        marginLeft: "130px",
    },
    fileInputButton:{
        width: 128,
    },
    fakeFileInputButton:{
        border: 0,
        padding: "0 10px",
        pointerEvents: "none",
        textOverflow: "ellipsis",
        backgroundColor: theme.palette.type === "light" ? theme.palette.background.paper : theme.palette.background.paper,
    }
}));

const CustomCheckbox = (Checkbox);

interface MenuSendFeedbackButtonProps {
    open: boolean;
    onOpen: () => void;
    onClose: () => void;
}

type SelectedFileState = File | null;

const MenuSendFeedbackButton: FunctionComponent<MenuSendFeedbackButtonProps> = (props: MenuSendFeedbackButtonProps) => {
    const { open, onOpen, onClose } = props;
    const classes = useStyles();
    const translate = useTranslate();
    const [formSended,setFormSended] = React.useState(false);
    const [name, setName] = React.useState('');
    const [lastname, setLastame] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [comment, setComment] = React.useState('');
    const [accept, setAccept] = React.useState(false);
    const [selectedFile, setSelectedFile] = React.useState<SelectedFileState>(null);
    const [filters,setFilters] = React.useContext(FiltersContext);
    const [validEmail, setValidEmail] = React.useState(true);

    const mailHelpText = React.useRef('');

    const checkEmail = (email:string) => {
        const res = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let isValid =  res.test(String(email).toLowerCase());
        if(isValid){
            setValidEmail(true);
            mailHelpText.current = '';
         }else {
             setValidEmail(false);
             mailHelpText.current = translate("sendFeedback.email.notValid");
         }

         return isValid;

    }

    const handleFileSelected = (e: SyntheticEvent<HTMLInputElement, Event>) => {
        const target = e.nativeEvent.target as HTMLInputElement;

        console.log(e.nativeEvent);

        if (target && "files" in target) {
            const { files } = target;

            if ((files as FileList) && files && files.length) {
                setSelectedFile(files[0]);
            } else {
                setSelectedFile(null);
            }
        } else {
            setSelectedFile(null);
        }
    };

    const onSubmit = (values:any) => {handleSend();};

     const onEmailChanged = (email:string) => {
        checkEmail(email);
        setEmail(email);
    }

    const getFileBase64 = () => {
        return new Promise<any> ((resolve, reject) => {
            if(!selectedFile) resolve(null);
            let reader:any = new FileReader();
            reader.readAsDataURL(selectedFile);
            reader.onload = function () {
                //@ts-ignore
                resolve({name:selectedFile.name ,content:reader.result})
            };
            reader.onerror = (error:any) => {
                resolve(null);
            };
        });
    };

    const handleSend = () => {
        if(!accept) return;
        if(!checkEmail(email)) return;

        getFileBase64().then((fileResult:any) => {
            let formData:any = {
                name:name,
                surname:lastname,
                email:email,
                comment:comment,
            }

            if(fileResult) formData['files'] = [fileResult];

            console.log('FORMDATA', JSON.stringify(formData),fileResult);

            apiProvider.sendFeedBack(formData)
            .then( (data) => {

                setFormSended(true);
                onClose();
                setName('');
                setComment('');
                setLastame('');
                setComment('');
                setAccept(false);
            }).catch((error) => {
                console.log('Error ',error);
            });


        }).catch(() => {});

    };

    const handleSendedClose = () => {
        setFormSended(false);
    }


    return (
        <>
            <Button disableRipple color="inherit" onClick={onOpen}>
                <ListItemText primary={translate("newshub.menu.sendFeedback")} />
            </Button>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={onClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <Grid container alignItems={"center"}>
                            <Grid item xs={6} >
                                <Typography variant="h2" component="p">{translate("newshub.menu.sendFeedback")}</Typography>
                            </Grid>
                            <Grid container justifyContent={"flex-end"} item xs={6} >
                                <CloseIconButton className={classes.closeButton} onClick={onClose} />
                            </Grid>
                        </Grid>

                        <Grid container alignItems={"center"}  className={classNames(classes.formContainer, 'sendFeedbackForm')}>
                            <form
                                onSubmit={onSubmit}
                            >
                                <FormGroup className={classes.expandGrid}>
                                    <Grid container>
                                        <Grid item xs={6} className="gridItem">
                                            <TextField
                                                id="outlined-full-width"
                                                label={translate("sendFeedback.label.name")}
                                                placeholder={translate("sendFeedback.placeholder.name")}
                                                /*helperText="Full width!"*/
                                                fullWidth
                                                margin="normal"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                variant="outlined"
                                                value={name}
                                                onChange = {(e) => {setName(e.target.value)} }
                                            />
                                        </Grid>
                                        <Grid item xs={6} className="gridItem">
                                            <TextField
                                                id="outlined-full-width"
                                                label={translate("sendFeedback.label.lastName")}
                                                placeholder={translate("sendFeedback.placeholder.lastName")}
                                                /*helperText="Full width!"*/
                                                fullWidth
                                                margin="normal"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                variant="outlined"
                                                value={lastname}
                                                onChange = {(e) => {setLastame(e.target.value)} }
                                            />
                                        </Grid>
                                    </Grid>
                                </FormGroup>
                                <TextField
                                    id="outlined-full-width"
                                    label={translate("sendFeedback.label.email")}
                                    placeholder={translate("sendFeedback.placeholder.email")}
                                    helperText={mailHelpText.current}
                                    error={!validEmail}
                                    fullWidth
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="outlined"
                                    value={email}
                                    onChange = {(e) => {onEmailChanged(e.target.value)} }
                                />
                                <TextField
                                    id="outlined-full-width"
                                    label={translate("sendFeedback.label.comments")}
                                    placeholder={translate("sendFeedback.placeholder.comments")}
                                    /*helperText="Full width!"*/
                                    fullWidth
                                    multiline
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="outlined"
                                    value={comment}
                                    onChange = {(e) => {setComment(e.target.value)} }
                                />

                                <Box mt={2} mb={2} position={"relative"}>
                                    <input
                                        accept="image/*"
                                        className={classes.input}
                                        id="contained-button-file"
                                        multiple
                                        type="file"
                                        onChange={handleFileSelected}
                                    />
                                    <label htmlFor="contained-button-file">
                                        <Button variant="contained" color="primary" component="span" className={classes.fileInputButton}   >
                                            {translate("newshub.action.chooseFiles")}
                                        </Button>
                                    </label>
                                    <input value={selectedFile ? selectedFile.name : ""} readOnly={true} tabIndex={-1} className={classes.fakeFileInputButton}/>
                                </Box>


                                <FormGroup>
                                    <FormControlLabel
                                        className={classes.checkboxLabel}
                                        control={
                                            <CustomCheckbox
                                                name="acceptPolicy"
                                                size="small"
                                                checked={accept}
                                                onChange = {(e) => {setAccept(!accept)} }
                                            />
                                        }
                                        label={
                                            <span>
                                                {translate("sendFeedback.message.acceptPolicy1")}
                                                <Link to={'/privacy/?theme='+filters.theme} target="_blank" rel={"noreferrer noopener"}>{translate("sendFeedback.message.acceptPolicy2")}</Link>
                                            </span>
                                        }
                                    />
                                </FormGroup>

                                <Grid container item xs={12} justifyContent="flex-end" >
                                <>
                                    <Button variant="contained" color="primary" size={"medium"} onClick={handleSend}>
                                        {translate("newshub.action.send")}
                                    </Button>


                                </>
                                </Grid>

                            </form>
                        </Grid>
                    </div>
                </Fade>
            </Modal>
            <Snackbar open={formSended} autoHideDuration={6000} onClose={handleSendedClose}>
                <Alert onClose={handleSendedClose} severity="success">
                    {translate("sendFeedback.snackbar.message")}
                </Alert>
            </Snackbar>
        </>
    );
}

export default MenuSendFeedbackButton;
