import React, {useEffect} from "react";
import {Button, makeStyles} from "@material-ui/core";
import { useTranslate } from "../../service/translation";
import {FiltersContext} from "../../service/filters";
import classNames from "classnames";
import {useLocation} from "react-router-dom";

const useStyles = makeStyles(theme => ({
    showNewContentButton:{
        top: "100px",
        left: "calc(50% + 55px)",
        zIndex: 1200,
        position: "fixed",
        transform: "translateX(-50%)",
        marginLeft: "calc(-55px / 2)",
        whiteSpace: "nowrap",
        fontSize: "14px",
        fontWeight: "normal",
        [theme.breakpoints.down("sm")]: {
            top: "64px",
            left: "50%",
            transform: "translateX(-50%)",
            marginLeft: "0",
        },
    },
}));

export default function ShowNewContentButton() {
    const classes = useStyles();
    const translate = useTranslate();
    const [filters,setFilters] = React.useContext(FiltersContext);

    const loadNewContent = () => {
        setFilters({...filters, newContent:2});
        window.scrollTo(0, 0);
    };

    React.useEffect(()=>{
        setFilters({...filters, newContent:0});
    },[filters.form])

    return (
       <>
       {filters.newContent === 1 &&
        <Button variant="contained" color="primary"  className={classNames(classes.showNewContentButton, 'pulse')} onClick={loadNewContent}>
            {translate("newshub.action.showNewContent")}
        </Button>
        }
        </>

    );
};
