import React from "react";
import FacebookIcon from "@material-ui/icons/Facebook";
import { IconButton, IconButtonProps, Tooltip } from "@material-ui/core";
import { useTranslate } from "../../service/translation";

const FacebookIconButton = (props: IconButtonProps) => {
    const translate = useTranslate();

    return (
        <Tooltip title={translate("newshub.action.facebook")}>
            <IconButton
                aria-label={translate("newshub.action.facebook")}
                color="primary"
                size="small"
                {...props}
            >
                <FacebookIcon />
            </IconButton>
        </Tooltip>
    )
};

export default FacebookIconButton;
