import React, { FunctionComponent } from "react";
import { useContentStyles } from "./useContentStyles";
import { useTranslate } from "../../service/translation";
import { useCookies } from "react-cookie";

import {
    Box, FormControl, FormControlLabel,
    Grid, Link, List, ListItem, ListItemIcon, ListItemText,
    Paper, Radio, RadioGroup,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@material-ui/core';
import Bullet from "@material-ui/icons/FiberManualRecord";

interface CookiesContentProps {
}

const CookiesContent: FunctionComponent<CookiesContentProps> = (props: CookiesContentProps) => {
    const cookieName = 'europarlcookiepolicysagreement';
    const classes = useContentStyles();
    const translate = useTranslate();
    const [cookies, setCookie] = useCookies<any>([cookieName]);

    

    const handleCookies = (event:any) => {
        const expiryTime = (new Date()).getTime() + (1000 * 3600 * 24 * 180 );
        const expiryDate = new Date(expiryTime);
        setCookie(cookieName, event.target.value , { path: "/", expires: expiryDate  });
    }

    return (
        <Grid container className={classes.page} id="main-content">
            <Grid container className={classes.pageWrapper}>
                <Grid item className={classes.staticPageContainer}>
                    <Typography gutterBottom variant={"h1"} component={"h1"}>
                        {translate("cookies.message.title")}
                    </Typography>
                    <Typography variant={"body1"} component={"p"}>
                        {translate("cookies.message.description1A")}
                    </Typography>
                    <TableContainer component={Paper} className={classes.tableContainer}>
                        <Table aria-label="table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>{translate("cookies.message.tableHead1")}</TableCell>
                                    <TableCell>{translate("cookies.message.tableHead2")}</TableCell>
                                    <TableCell>{translate("cookies.message.tableHead3")}</TableCell>
                                    <TableCell className={classes.tableDuration}>{translate("cookies.message.tableHead4")}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>{translate("cookies.message.tableRow1A")}</TableCell>
                                    <TableCell>{translate("cookies.message.tableRow1B")}</TableCell>
                                    <TableCell>
                                        {translate("cookies.message.tableRow1C1")}
                                        <Link href={translate("cookies.message.tableRow1CUrl")} target={"_blank"} rel="noopener noreferrer">{translate("cookies.message.tableRow1CLink")}</Link>
                                        {translate("cookies.message.tableRow1C2")}
                                    </TableCell>
                                    <TableCell>{translate("cookies.message.tableRow1D")}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>{translate("cookies.message.tableRow2A")}</TableCell>
                                    <TableCell>{translate("cookies.message.tableRow2B")}</TableCell>
                                    <TableCell>{translate("cookies.message.tableRow2C")}</TableCell>
                                    <TableCell>{translate("cookies.message.tableRow2D")}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>{translate("cookies.message.tableRow3A")}</TableCell>
                                    <TableCell>{translate("cookies.message.tableRow3B")}</TableCell>
                                    <TableCell>{translate("cookies.message.tableRow3C")}</TableCell>
                                    <TableCell>{translate("cookies.message.tableRow3D")}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>{translate("cookies.message.tableRow4A")}</TableCell>
                                    <TableCell>{translate("cookies.message.tableRow4B")}</TableCell>
                                    <TableCell>{translate("cookies.message.tableRow4C")}</TableCell>
                                    <TableCell>{translate("cookies.message.tableRow4D")}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>{translate("cookies.message.tableRow5A")}</TableCell>
                                    <TableCell>{translate("cookies.message.tableRow5B")}</TableCell>
                                    <TableCell>{translate("cookies.message.tableRow5C")}</TableCell>
                                    <TableCell>{translate("cookies.message.tableRow5D")}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>{translate("cookies.message.tableRow6A")}</TableCell>
                                    <TableCell>{translate("cookies.message.tableRow6B")}</TableCell>
                                    <TableCell>{translate("cookies.message.tableRow6C")}</TableCell>
                                    <TableCell>{translate("cookies.message.tableRow6D")}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>{translate("cookies.message.tableRow7A")}</TableCell>
                                    <TableCell>{translate("cookies.message.tableRow7B")}</TableCell>
                                    <TableCell>{translate("cookies.message.tableRow7C")}</TableCell>
                                    <TableCell>{translate("cookies.message.tableRow7D")}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Typography gutterBottom variant={"h2"} component={"h2"}>
                        {translate("cookies.message.subtitle2")}
                    </Typography>
                    <Typography variant={"body1"} component={"p"}>
                        {translate("cookies.message.description2A")}
                    </Typography>
                    <Typography variant={"body1"} component={"p"}>
                        {translate("cookies.message.description2B")}
                    </Typography>
                    <Typography variant={"body1"} component={"p"}>
                        {translate("cookies.message.description2C")}
                    </Typography>
                    <List>
                        <ListItem><ListItemIcon><Bullet /></ListItemIcon><a href={translate("cookies.message.description2ListALink")} target="_blank" rel="noopener noreferrer"><ListItemText primary={translate("cookies.message.description2ListA")}/></a></ListItem>
                        <ListItem><ListItemIcon><Bullet /></ListItemIcon><a href={translate("cookies.message.description2ListBLink")} target="_blank" rel="noopener noreferrer"><ListItemText primary={translate("cookies.message.description2ListB")}/></a></ListItem>
                        <ListItem><ListItemIcon><Bullet /></ListItemIcon><a href={translate("cookies.message.description2ListCLink")} target="_blank" rel="noopener noreferrer"><ListItemText primary={translate("cookies.message.description2ListC")}/></a></ListItem>
                        <ListItem><ListItemIcon><Bullet /></ListItemIcon><a href={translate("cookies.message.description2ListDLink")} target="_blank" rel="noopener noreferrer"><ListItemText primary={translate("cookies.message.description2ListD")}/></a></ListItem>
                        <ListItem><ListItemIcon><Bullet /></ListItemIcon><a href={translate("cookies.message.description2ListELink")} target="_blank" rel="noopener noreferrer"><ListItemText primary={translate("cookies.message.description2ListE")}/></a></ListItem>
                        <ListItem><ListItemIcon><Bullet /></ListItemIcon><a href={translate("cookies.message.description2ListFLink")} target="_blank" rel="noopener noreferrer"><ListItemText primary={translate("cookies.message.description2ListF")}/></a></ListItem>
                    </List>
                    <Typography gutterBottom variant={"h2"} component={"h2"}>
                        {translate("cookies.message.subtitle3")}
                    </Typography>
                    <Typography variant={"body1"} component={"p"}>
                        {translate("cookies.message.description3A")}
                    </Typography>
                    <Typography variant={"h3"} component={"h3"}>
                        {translate("cookies.message.description3subtitle")}
                    </Typography>
                    <Typography variant={"body1"} component={"p"}>
                        {translate("cookies.message.description3B")}
                    </Typography>
                    <Typography variant={"body1"} component={"p"}>
                        {translate("cookies.message.description3C")}
                    </Typography>
                    <List>
                        <ListItem><ListItemIcon><Bullet /></ListItemIcon><ListItemText primary={translate("cookies.message.description3ListA")}/></ListItem>
                        <ListItem><ListItemIcon><Bullet /></ListItemIcon><ListItemText primary={translate("cookies.message.description3ListB")}/></ListItem>
                        <ListItem><ListItemIcon><Bullet /></ListItemIcon><ListItemText primary={translate("cookies.message.description3ListC")}/></ListItem>
                        <ListItem><ListItemIcon><Bullet /></ListItemIcon><ListItemText primary={translate("cookies.message.description3ListD")}/></ListItem>
                        <ListItem><ListItemIcon><Bullet /></ListItemIcon><ListItemText primary={translate("cookies.message.description3ListE")}/></ListItem>
                        <ListItem><ListItemIcon><Bullet /></ListItemIcon><ListItemText primary={translate("cookies.message.description3ListF")}/></ListItem>
                        <ListItem><ListItemIcon><Bullet /></ListItemIcon><ListItemText primary={translate("cookies.message.description3ListG")}/></ListItem>
                        <ListItem><ListItemIcon><Bullet /></ListItemIcon><ListItemText primary={translate("cookies.message.description3ListH")}/></ListItem>
                        <ListItem><ListItemIcon><Bullet /></ListItemIcon><ListItemText primary={translate("cookies.message.description3ListI")}/></ListItem>
                        <ListItem><ListItemIcon><Bullet /></ListItemIcon><ListItemText primary={translate("cookies.message.description3ListJ")}/></ListItem>
                        <ListItem><ListItemIcon><Bullet /></ListItemIcon><ListItemText primary={translate("cookies.message.description3ListK")}/></ListItem>
                    </List>
                    {/*<Typography variant={"body1"} component={"p"}>
                        {translate("cookies.message.description3D")}
                        <Link href={translate("cookies.message.description3DUrl")} target={"_blank"} rel="noopener noreferrer">{translate("cookies.message.description3DLink")}</Link>
                    </Typography>*/}
                    <Box className={classes.cookiesWidget}>
                        { cookies[cookieName] === "1" &&
                            <Typography variant={"body1"} component={"p"}>
                                {translate("cookies.message.cookiesWidgetAccepted")}
                            </Typography>
                        }

                        { cookies[cookieName] === "0"  &&
                            <Typography variant={"body1"} component={"p"}>
                                {translate("cookies.message.cookiesWidgetRefused")}
                            </Typography>
                        }

                        { cookies[cookieName] !== "0"  && cookies[cookieName] !== "1" &&
                            <Typography variant={"body1"} component={"p"}>
                                {translate("cookies.message.cookiesWidgetNeither")}
                            </Typography>
                        }
                       
                        <FormControl component="fieldset">
                            <RadioGroup aria-label={translate("cookies.message.cookiesWidgetAccepted")} name="cookiesAcceptance" defaultValue={cookies[cookieName]} onChange={handleCookies}>
                                <FormControlLabel value="0" control={<Radio color="primary" />} label={translate("cookies.message.cookiesWidgetRadio1")} />
                                <FormControlLabel value="1" control={<Radio color="primary" />} label={translate("cookies.message.cookiesWidgetRadio2")} />
                            </RadioGroup>
                        </FormControl>
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default CookiesContent;
