import { TopicVirality } from "./TopicVirality";
import { PropTypes } from "@material-ui/core";

const getTopicColor = (virality: TopicVirality): Exclude<PropTypes.Color, "inherit"> => {
    switch (virality) {
        case "low":
        case "medium":
        case "high":
        case "trending":
            return "primary";
    }

    const error = new Error();
    error.name = "IllegalViralityLevel";
    error.message = `
        Illegal virality level "${virality}". Allowed levels are 
        "low", "medium", "hight", and "trending"
    `;

    throw error;
};

export {
    getTopicColor,
}
