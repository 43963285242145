
interface isoCountry {
    key: string;
    name: string;

};

const countries:isoCountry[] = [
   { key:'AT', name: "Austria"},
   { key:'BE', name: "Belgium"},
   { key:'BG', name: "Bulgaria"},
   { key:'CY', name: "Republic of Cyprus"},
   { key:'CZ', name: "Czech Republic"},
   { key:'DE', name: "Germany"},
   { key: 'DK', name: "Denmark"},
   { key: 'EE', name: "Estonia"},
   { key:'ES', name: "Spain"},
   { key: 'FI', name: "Finland"},
   { key: 'FR', name: "France"},
   { key: 'GR', name: "Greece"},
   { key:'HR', name: "Croatia"},
   { key:'HU', name: "Hungary"},
   { key:'IE', name: "Ireland"},
   { key: 'IT', name: "Italy"},
   { key: 'LT', name: "Lithuania"},
   { key: 'LU', name: "Luxembourg"},
   { key:'LV', name: "Latvia"},
   { key:'MT', name: "Malta"},
   { key: 'NL', name: "Netherlands"},
   { key: 'PL', name: "Poland"},
   { key: 'PT', name: "Portugal"},
   { key: 'RO', name: "Romania"},
   { key: 'SE', name: "Sweden"},
   { key: 'SK', name: "Slovakia"},
   { key: 'SL', name: "Slovenia"},
];

const languages:isoCountry[] = [
    {key:"bg", name:"Bulgarian"},
    {key:"cz", name:"Czech "},
    {key:"hr", name:"Croatian "},
    {key:"da", name:"Danish "},
    {key:"nl", name:"Dutch"},
    {key:"en", name:"English"},
    {key:"et", name:"Estonian"},
    {key:"fi", name:"Finnish"},
    {key:"fr", name:"French"},
    {key:"de", name:"German"},
    {key:"el", name:"Greek"},
    {key:"hu", name:"Hungarian"},
    {key:"ga", name:"Irish"},
    {key:"it", name:"Italian"},
    {key:"lv", name:"Latvian"},
    {key:"lt", name:"Lithuanian"},
    {key:"mt", name:"Maltese"},
    {key:"pl", name:"Polish"},
    {key:"pt", name:"Portuguese"},
    {key:"ro", name:"Romanian"},
    {key:"es", name:"Spanish "},
    {key:"sk", name:"Slovak"},
    {key:"sl", name:"Slovenian"},
    {key:"se", name:"Swedish"},
    {key:"ol", name:"Other languages"}
];



export {countries,languages};