import React, { FunctionComponent } from "react";
import { SocialItemsList } from "../social";
import { useContentStyles } from "./useContentStyles";
/*import { useTranslate } from "../../service/translation";*/
import {Grid} from '@material-ui/core';

interface SearchContentProps {
}

const SearchContent: FunctionComponent<SearchContentProps> = (props: SearchContentProps) => {
    const classes = useContentStyles();
    /*const translate = useTranslate();*/

    return (
        <Grid container className={classes.page} id="main-content">
            <Grid container className={classes.pageWrapper}>
                <Grid item xs={12} sm={6} md className="gridItem"></Grid>
                <Grid item xs={12} sm={12} md={5} className="gridItem">
                    <SocialItemsList />
                </Grid>
                <Grid item xs={12} sm={6} md className="gridItem"></Grid>
            </Grid>
        </Grid>
    );
};

export default SearchContent;
