import React, { FunctionComponent } from "react";
import classNames from "classnames";
import { CircularProgress, makeStyles, useTheme } from "@material-ui/core";
import { RefreshIconButton } from "../button";
import { useLoading } from "../../service/newshub";

interface LoadingIndicatorProps {
    className?: string;
}

const useStyles = makeStyles(theme => ({
    loader: {
        margin: theme.spacing(2),
    },
}));

const LoadingIndicator: FunctionComponent<LoadingIndicatorProps> = (props: LoadingIndicatorProps) => {
    const classes = useStyles();
    const loading = useLoading();
    const theme = useTheme();
    const { className } = props;

    return loading ? (
        <CircularProgress
            className={classNames(classes.loader, className)}
            color="inherit"
            size={theme.spacing(2)}
            thickness={6}
        />
    ) : (
        <RefreshIconButton className={className} />
    );
};

export default LoadingIndicator;
