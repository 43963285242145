export const CLEAR_STATE = "EP/CLEAR_STATE";

/**
 * The CLEAR_STATE action will completely reset the Newshub redux state to
 * its initial value. This should only be called once the user has been
 * redirected to a page which do not use the state, such as an evential
 * login page.
 */
export interface ClearStateAction {
    type: typeof CLEAR_STATE;
}

export const clearState = () => ({
    type: CLEAR_STATE,
});
