import { makeStyles } from "@material-ui/core";

const useSocialCardStyles = makeStyles(theme => ({
    root: {
        marginBottom: 10,
        [theme.breakpoints.down("xs")]: {
            marginBottom: 0,
            borderBottom: "1px solid #CCC",
            borderRadius: 0,
            marginLeft: theme.spacing(-1),
            marginRight: theme.spacing(-1),
        },
    },
    header: {
        alignItems: "flex-start",
        display: "flex",
        padding: theme.spacing(2,2,0),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(2,1,0),
        },
    },
    avatar: {
        display: "flex",
        flex: "0 0 auto",
        marginRight: theme.spacing(2),
        [theme.breakpoints.down("sm")]: {
            marginRight: theme.spacing(1.5),
        },
    },
    avatarImage: {
        height: theme.spacing(7),
        width: theme.spacing(7),
        boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
        color: theme.palette.type === "light" ? theme.palette.background.paper : theme.palette.background.default,
        backgroundColor: theme.palette.type === "light" ? "#757575" : theme.palette.secondary.dark,
    },
    content: {
        flex: "1 1 auto",
        marginRight: theme.spacing(2),
        [theme.breakpoints.down("sm")]: {
            marginRight: theme.spacing(1.5),
            "& .preWrap": {
                fontSize: 0,
            },
        },
    },
    source: {
        alignItems: "center",
        alignSelf: "flex-start",
        display: "flex",
        flex: "0 0 auto",
        [theme.breakpoints.down("sm")]: {
            marginRight: 0,
            flexDirection: 'column-reverse',
        },
    },
    sourceIcon: {
        marginLeft: theme.spacing(2),
        height: theme.spacing(4),
        width: theme.spacing(4),
        color: theme.palette.type === "light" ? '#FFF' : '#303134',
        backgroundColor: theme.palette.type === "light" ? '#999' : '#bdc1c6',
        "& svg": {
            height: theme.spacing(3),
            width: theme.spacing(3),
        },
        [theme.breakpoints.down("sm")]: {
            marginLeft: theme.spacing(0),
            height: theme.spacing(3),
            width: theme.spacing(3),
            "& svg": {
                height: theme.spacing(2),
                width: theme.spacing(2),
            },
        },
    },
    counter: {
        margin: theme.spacing(0, 0, 1),
    },
    media: {
        borderRadius: theme.shape.borderRadius,
        maxWidth: "100%",
        width: "100%",
    },
    image: {
        height: "auto !important",
    },
    audio: {
    },
    video: {
        height: "auto !important",
    },
    toolbar: {
        backgroundColor: theme.palette.type === "light" ? theme.palette.primary.main : theme.palette.primary.main,
        color: theme.palette.type === "light" ? theme.palette.primary.main : theme.palette.primary.main,
        display: "flex",
        height: 87,
        justifyContent: "center",
        overflowY: "hidden",
        transition: `${theme.transitions.create("height")}, ${theme.transitions.create("min-height")}`,
        zIndex: 3,
        "& *":{
            "&:focus-visible": {
                /*outlineOffset: "1px",
                outline:"2px solid black",*/
                borderRadius: "0px !important",
                outline: "3px dotted #d9d9d9",
                outlineOffset: "-3px",
            },
            "&:-moz-focusring": {
                /*outlineOffset: "1px",
                outline:"2px solid black",*/
                borderRadius: "0px !important",
                outline: "3px dotted #d9d9d9",
                outlineOffset: "-3px",
            },
        },
    },
    toolbarCollapsed: {
        height: 0,
        minHeight: 0,
        padding: 0,
    },
    toolbarContent: {
        display: "flex",
        justifyContent: "space-between",
        overflow: "hidden",
        flexGrow: 1,
    },
    toolbarItem: {
        display: "flex",
        flexDirection: "column",
        justfyContent: "center",
        alignItems: "center",
        width: "100%",
        textAlign: "center",
        padding: theme.spacing(2, 0),
        border: 0,
        cursor: "pointer",
        textDecoration: "none",
        backgroundColor: theme.palette.type === "light" ? theme.palette.primary.main : theme.palette.primary.main,
        [theme.breakpoints.up("md")]: {
            "&:focus-visible": {
                borderRadius: "0px !important",
                outline: "3px dotted #d9d9d9 !important",
                outlineOffset: "-3px",
            },
            "&:-moz-focusring": {
                borderRadius: "0px !important",
                outline: "3px dotted #d9d9d9 !important",
                outlineOffset: "-3px",
            },
        },
        "&:hover": {
            backgroundColor:  theme.palette.type === "light" ? theme.palette.primary.dark : theme.palette.secondary.dark,
            "& svg": {
                color:  theme.palette.type === "light" ? theme.palette.primary.dark : theme.palette.secondary.dark,
            },
        },
        "& *": {
            textDecoration: "none",
        },
        "& p": {
            fontSize: 12,
            color: theme.palette.type === "light" ? theme.palette.background.paper : theme.palette.background.paper,
            [theme.breakpoints.down("sm")]: {
                fontSize: 10,
            },
        },
        "&>div": {
            width: 32,
            height: 32,
            borderRadius: "50%",
            backgroundColor: theme.palette.type === "light" ? theme.palette.background.paper : theme.palette.background.paper,
            marginBottom: 5,
            "&>svg": {
                color: theme.palette.type === "light" ? theme.palette.primary.main : theme.palette.primary.main,
                width: 18,
                height: 32
            },
        },
    },
    cardActions: {
        "& .MuiButton-label": {
            textTransform: "none",
            fontSize: 14,
            fontWeight: 400,
            color: theme.palette.type === "light" ? theme.palette.secondary.dark : theme.palette.text.primary,
            [theme.breakpoints.down("sm")]: {
                fontSize: 12,
            },
        },
        "& a":{
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
            textDecoration: "none !important",
            "&:hover":{
                backgroundColor: "transparent !important",
                "& .MuiButton-label": {
                    color: theme.palette.type === "light" ? theme.palette.primary.main + " !important" : theme.palette.primary.main + " !important",
                },
            },
        },
        "& button":{
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
            "&:hover":{
                backgroundColor: "transparent !important",
                "& .MuiButton-label": {
                    color: theme.palette.type === "light" ? theme.palette.primary.main + " !important" : theme.palette.primary.main + " !important",
                },
            },
        },
    },
    contentContainer: {
        padding: theme.spacing(0, 2),
        margin: theme.spacing(2, 0),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(0, 1),
            margin: theme.spacing(2, 0),
        },
    },
    cardMedia: {
        "& .MuiToolbar-gutters": {
            padding: 0,
        },
    },
    mediaContainer: {
        position: "relative",
        "& .MuiCardContent-root": {
            padding: theme.spacing(0, 2, 0),
            [theme.breakpoints.down("sm")]: {
                padding: theme.spacing(0, 1, 0),
            },
        },
        '& a':{
            /*backgroundColor: "blue",*/

        },
        '& svg':{
            position: "absolute",
            right: 0,
            left: 0,
            bottom: 0,
            top: 0,
            margin: "auto",
            width: 90,
            height: 90,
            cursor: "pointer",
            color: theme.palette.type === "light" ? "white" : "white",
            "-webkit-filter": "drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7))",
            filter: "drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7))",
            [theme.breakpoints.down("sm")]: {
                width: 50,
                height: 50,
            },
        }
    },
    cardText: {
        "& > a": {
            color: theme.palette.type === "light" ? theme.palette.primary.main : theme.palette.primary.main,
            "&:focus-visible": {
                outlineOffset: "0px",
            },
            "&:-moz-focusring": {
                outlineOffset: "0px",
            },
        }
    },
    cardTextTranslation: {
        padding: theme.spacing(0, 2),
        margin: theme.spacing(2, 0, 2, 2),
        borderLeft: theme.palette.type === "light" ? "4px solid #cecece " : "4px solid" + theme.palette.secondary.dark,
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(0, 1),
            margin: theme.spacing(2, 0),
        },
    },
    translateButton: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
    active: {
        "&.MuiButton-root":{
            "& .MuiButton-label":{
                color: theme.palette.type === "light" ? theme.palette.primary.dark + " !important"  : theme.palette.primary.dark + " !important",
                "&:hover":{
                    color: theme.palette.type === "light" ? theme.palette.primary.dark + " !important" : theme.palette.primary.dark + " !important",
                },
            },
        },
    },
    snackbar: {
        [theme.breakpoints.down("sm")]: {
            bottom: "56px",
        },
    },
}));

export { useSocialCardStyles };
