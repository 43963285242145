import { DataProvider } from "../../data";

const GET_MEP_COLLECTION = "GET_MEP_COLLECTION";
const GET_MEP_INSTANCE = "GET_MEP_INSTANCE";
const GET_POLITICAL_GROUP_COLLECTION = "GET_POLITICAL_GROUP_COLLECTION";
const GET_POLITICAL_GROUP_INSTANCE = "GET_POLITICAL_GROUP_INSTANCE";
const GET_SOCIAL_ITEM_COLLECTION = "GET_SOCIAL_ITEM_COLLECTION";

const sanitizeFetchType = (fetchType: string): keyof DataProvider => {
    switch (fetchType) {
        case GET_MEP_COLLECTION:
            return "getMeps";
        case GET_MEP_INSTANCE:
            return "getMep";
        case GET_POLITICAL_GROUP_COLLECTION:
            return "getPoliticalGroups";
        case GET_POLITICAL_GROUP_INSTANCE:
            return "getPoliticalGroup";
        case GET_SOCIAL_ITEM_COLLECTION:
            return "getSocialItems";
        default:
            throw new Error(`Invalid fetchType ${fetchType}`);
    }
};

export {
    sanitizeFetchType,
}
