import { COMPLETE_UNDOABLE, UNDO, startOptimisticMode, stopOptimisticMode } from "../actions";
import { put, race, take, takeEvery } from "redux-saga/effects";
import { refreshView } from "../../refresh";
import { showNotification } from "../../notification";

function* handleUndoRace(undoableAction: { payload: { action: any }}) {
    const { payload: { action }} = undoableAction;
    const { onFailure, onSuccess, ...metaWithoutSideEffects } = action.meta;

    yield put(startOptimisticMode());

    // Dispatch action in optimistic mode (no fetch),
    // with success side effects
    yield put({
        ...action,
        type: `${action.type}_OPTIMISTIC`,
        meta: {
            ...metaWithoutSideEffects,
            ...onSuccess,
            optimistic: true,
        },
    });

    // wait for undo or delay
    const { complete } = yield race({
        undo: take(UNDO),
        complete: take(COMPLETE_UNDOABLE),
    });

    yield put(stopOptimisticMode());

    if (complete) {
        // If not cancelled, redispatch the action, this time
        // immediate, and without the success side effect
        yield put({
            ...action,
            meta: {
                ...metaWithoutSideEffects,
                onFailure: { ...onFailure, refresh: true },
                onSuccess: { refresh: true },
            },
        });
    } else {
        yield put(showNotification("info", "newshub.notification.cancelled"));
        yield put(refreshView());
    }
}

const undoSaga = function* () {
    // @ts-ignore
    yield takeEvery(action => action.meta && action.meta.refresh, handleUndoRace);
};

export { undoSaga };
