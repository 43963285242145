import React, { FunctionComponent } from "react";
import SocialCardSkeleton from "./SocialCardSkeleton";
import { Skeleton, SkeletonProps } from "@material-ui/lab";
import { useSocialCardListStyles } from "./useSocialCardListStyles";
import {Box} from "@material-ui/core";
import classNames from "classnames";
import ShowNewContentButton from "../button/ShowNewContentButton";
import Masonry from "react-masonry-component";
import SocialCard from "./SocialCard";
import {Route} from "react-router";

interface SocialCardListSkeletonProps extends SkeletonProps {
    numItems: number;
    showCounter?: boolean;
}

const SocialCardListSkeleton: FunctionComponent<SocialCardListSkeletonProps> = (props: SocialCardListSkeletonProps) => {
    const classes = useSocialCardListStyles();
    const { numItems, showCounter, ...rest } = props;

    const mod = parseInt(((numItems / 2) * (1 + Math.random())).toFixed(0), 10) - 1;
    const items = Array.from({ length: numItems }, (_, i) => i + 1);
    const masonryOptions = {
        transitionDuration: 0,
        itemSelector: '.masonryItem',
        gutter: 16,
        percentPosition: true,
    };

    return (
        <>
            <Route exact path={["/", "/contributors/:id"]}>
                <Box className={classes.list}>
                    {
                        showCounter && (
                            <Skeleton
                                variant="text"
                                width={128}
                                {...rest}
                            />
                        )
                    }
                    <span />
                    {
                        items.map((i: number) => (
                            <SocialCardSkeleton
                                key={`social-card-skeleton-${i}`}
                                withImage={i % mod === 0}
                                {...rest}
                            />
                        ))
                    }
                </Box>
            </Route>
            <Route exact path="/timeline">
                <Box className={classNames(classes.list, 'masonryWrapper')}>
                    <Masonry
                        className={classNames(classes.masonryLayout, 'masonryContainer')} // default ''
                        elementType={'div'} // default 'div'
                        options={masonryOptions} // default {}
                        disableImagesLoaded={false} // default false
                        updateOnEachImageLoad={false} // default false and works only if disableImagesLoaded is false
                    >
                        {
                            items.map((i: number) => (
                                <SocialCardSkeleton
                                    key={`social-card-skeleton-${i}`}
                                    withImage={i % mod === 0}
                                    {...rest}
                                />
                            ))
                        }
                    </Masonry>
                </Box>
            </Route>
        </>
    );
};

SocialCardListSkeleton.defaultProps = {
    animation: "wave",
    numItems: 5,
    showCounter: false,
};

export default SocialCardListSkeleton;
