import { FunctionComponent } from "react";
import { Mep } from "../../service/data";
import {Box, makeStyles,Button,Tooltip} from "@material-ui/core";
import AnchorIcon from "@material-ui/icons/Link";
import WwwIcon from "@material-ui/icons/Person";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import RssFeedIcon from "@material-ui/icons/RssFeed";

import { useTranslate } from "../../service/translation";

import {
    AnchorIconButton,
    FacebookIconButton,
    InstagramIconButton,
    RssFeedIconButton,
    TwitterIconButton,
    WwwIconButton
} from "../button";
import XIconSVG from "./XIconSVG";

interface SocialLinksProps {
    record: Mep,
}

const useStyles = makeStyles(theme => ({
    links: {
        display: "flex",
        justifyContent: "center",
        margin: theme.spacing(6, 0),
        "& > a":{
            boxShadow: "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
            margin: theme.spacing(0, 0.5),
            height: theme.spacing(4),
            width: theme.spacing(4),
            minWidth: "0px !important",
            backgroundColor: theme.palette.type === "light" ? "white" : "#333",
            "&:hover": {
                boxShadow: "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
            },
            "& .MuiSvgIcon-root": {
                height: theme.spacing(2.5),
                width: theme.spacing(2.5),
            },
        },
    },
    link: {
        boxShadow: "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
        margin: theme.spacing(0, 0.5),
        height: theme.spacing(4),
        width: theme.spacing(4),
        backgroundColor: theme.palette.type === "light" ? "white" : "#333",
        "&:hover": {
            boxShadow: "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
        },
        "& .MuiSvgIcon-root": {
            height: theme.spacing(2.5),
            width: theme.spacing(2.5),
        },
    },
}));

const SocialLinks: FunctionComponent<SocialLinksProps> = (props: SocialLinksProps) => {
    const classes = useStyles();
    const { record: { socialNetworks }} = props;
    const translate = useTranslate();

    return (
        <Box className={classes.links + " socialMediaIcons"}>

            {socialNetworks.profileLink &&
                <Tooltip title={translate("newshub.action.anchor")}>
                    <Button
                        href= {socialNetworks.profileLink.url}
                        target="_blank"
                        aria-label={translate("newshub.action.anchor")}
                        color="primary"
                        size="small"
                        rel="noopener noreferrer"
                    >
                        <AnchorIcon />
                    </Button>
                </Tooltip>
            }

            {
                socialNetworks.www && (
                    <Tooltip title={translate("newshub.action.anchor")}>
                        <Button
                            href= {socialNetworks.www.url}
                            target="_blank"
                            aria-label={translate("newshub.action.www")}
                            color="primary"
                            size="small"
                            rel="noopener noreferrer"
                        >
                            <WwwIcon />
                        </Button>
                    </Tooltip>
                )
            }
            {
                socialNetworks.twitter && (
                    <Tooltip title={translate("newshub.action.twitter")}>
                        <Button
                            href= {socialNetworks.twitter.url}
                            target="_blank"
                            aria-label={translate("newshub.action.twitter")}
                            color="primary"
                            size="small"
                            rel="noopener noreferrer"
                        >
                            <XIconSVG />
                        </Button>
                    </Tooltip>
                )
            }
            {
                socialNetworks.facebook && (
                    <Tooltip title={translate("newshub.action.facebook")}>
                        <Button
                            href= {socialNetworks.facebook.url}
                            target="_blank"
                            aria-label={translate("newshub.action.facebook")}
                            color="primary"
                            size="small"
                            rel="noopener noreferrer"
                        >
                            <FacebookIcon />
                        </Button>
                    </Tooltip>
                )
            }
            {
                socialNetworks.instagram && (
                    <Tooltip title={translate("newshub.action.instagram")}>
                        <Button
                            href= {socialNetworks.instagram.url}
                            target="_blank"
                            aria-label={translate("newshub.action.instagram")}
                            color="primary"
                            size="small"
                            rel="noopener noreferrer"
                        >
                            <InstagramIcon />
                        </Button>
                    </Tooltip>
                )
            }
            {
                socialNetworks.rss && (
                    <Tooltip title={translate("newshub.action.rss")}>
                        <Button
                            href= {socialNetworks.rss.url}
                            target="_blank"
                            aria-label={translate("newshub.action.rss")}
                            color="primary"
                            size="small"
                            rel="noopener noreferrer"
                        >
                            <RssFeedIcon />
                        </Button>
                    </Tooltip>
                )
            }
        </Box>
    );
};

export default SocialLinks;
