import React, { FunctionComponent } from "react";
import MepCard from "./MepCard";
import { Mep, ResourceList } from "../../service/data";
import { useMepCardListStyles } from "./useMepCardListStyles";
import {Box, Grid, MenuItem, MenuList, Typography} from "@material-ui/core";
import {useTranslate} from "../../service/translation/hooks";

interface MepCardListProps {
    data: ResourceList<Mep>;
    title?: string;
    total: number;
}

const MepCardList: FunctionComponent<MepCardListProps> = (props: MepCardListProps) => {
    const classes = useMepCardListStyles();
    const translate = useTranslate();
    const { data,total } = props;

    return (
        <>
            <Box mb={1} ml={1} className={classes.counterContainer}>
              {<Typography variant={"h6"} component={"p"}>{translate( (total ===1)?  "newshub.pagination.resultsOne": "newshub.pagination.results",{total:total})}</Typography>}
            </Box>
            <Grid container className={classes.list}>
                {
                    data.map(record => (
                        <MepCard
                            className={classes.card}
                            key={record.id}
                            record={record}
                        />
                    ))
                }
            </Grid>
        </>
    );
};

export default MepCardList;
