
const h1Styles = {
    verticalAlign: 'middle',
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
};
//TODO: replace me with MUI XIcon when we migrate to mui 5
// used to be import TwitterIcon from "@material-ui/icons/Twitter";
const XIconSVG = () => {
    return (
        <svg className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true" style={ h1Styles } >
            <path

                d="m 17.71 4 h 2.85 l -6.27 7.14 l 7.32 9.68 h -5.75 l -4.5 -5.88 l -5.15 5.88 h -2.84 l 6.64 -7.63 l -7.01 -9.19 h 5.89 l 4.06 5.37 l 4.76 -5.37 z m -1 15.14 h 1.58 l -10.23 -13.53 h -1.7 l 10.36 13.53 z"
            />
        </svg>
    );
};
export default XIconSVG;
