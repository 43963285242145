import { FETCH_CANCEL, FETCH_END, FETCH_ERROR, FETCH_START } from "../actions";
import { FetchState } from "../FetchState";
import { Reducer } from "redux";

const fetchReducer: Reducer<FetchState> = (previousState = 0, { type }) => {
    switch (type) {
        case FETCH_START:
            return previousState + 1;
        case FETCH_END:
        case FETCH_ERROR:
        case FETCH_CANCEL:
            return Math.max(previousState - 1, 0);
        default:
            return previousState;
    }
};

export { fetchReducer };
