import { FETCH_END } from "../../fetch";
import { Reducer } from "redux";
import { getResourceName, Resource, ResourceCacheEntry, ResourceName, ResourceState } from "../../data";

const initialState = {
    data: [],
    cache: {},
    collectionParams: {},
    instanceParams: { id: "" },
    total: -1,
};

const createDataReducer = <T extends Resource>(resource: ResourceName): Reducer<ResourceState<T>> =>
    (previousState: ResourceState<T> = initialState, { payload, meta }) => {
        if (meta) {
            const { type, status, signature } = meta;

            if (getResourceName(type) === resource && status === FETCH_END && signature) {
                const { cache } = previousState;
                const { data } = payload;

                return { ...previousState, cache: { ...cache, [signature]: new ResourceCacheEntry(data) } };
            }
        }

        return previousState;
    };

export { createDataReducer }
