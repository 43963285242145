import { Button, ButtonProps } from "@material-ui/core";
import { FunctionComponent } from "react";
import { useTranslate } from "../../service/translation";

const ShareButton: FunctionComponent<ButtonProps> = (props: ButtonProps) => {
    const translate = useTranslate();

    // No button variant by default, so a text button is used
    // See https://material-ui.com/components/buttons/#text-buttons
    return (
        <Button
            aria-label={translate("newshub.action.share")}
            color="primary"
            size="small"
            {...props}
        >
            {translate("newshub.action.share")}
        </Button>
    );
};

export default ShareButton;
