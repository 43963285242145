import React, { FunctionComponent, useCallback, useEffect, useState } from "react";
import classNames from "classnames";
import { NotificationOptions, NotificationPayload, hideNotification, selectNotification } from "../../service/notification";
import { Snackbar, SnackbarProps, makeStyles } from "@material-ui/core";
import { UndoButton } from "../button";
import { completeUndoable, undo, undoableEventEmitter } from "../../service/undo";
import { useDispatch, useSelector } from "react-redux";
import { useTranslate } from "../../service/translation";

const useStyles = makeStyles(theme => ({
    info: {
        backgroundColor: theme.palette.success.main,
        color: theme.palette.success.contrastText,
    },
    error: {
        backgroundColor: theme.palette.error.dark,
        color: theme.palette.error.contrastText,
    },
    success: {
        backgroundColor: theme.palette.success.main,
        color: theme.palette.success.contrastText,
    },
    warning: {
        backgroundColor: theme.palette.error.light,
        color: theme.palette.error.contrastText,
    },
    undoOnError: {
        color: theme.palette.primary.light,
    },
    undoOnSuccess: {
        color: theme.palette.success.contrastText,
    },
}));

const Notification: FunctionComponent<Omit<SnackbarProps, "open">> = props => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const notification: NotificationPayload | undefined = useSelector(selectNotification);
    const translate = useTranslate();
    const [open, setOpen] = useState(false);
    const { className, ...rest } = props;

    const handleClose = useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    const handleExit = useCallback(() => {
        if (notification && notification.notificationOptions && notification.notificationOptions.undoable) {
            dispatch(completeUndoable());
            undoableEventEmitter.emit("end", { isUndo: false });
        }

        dispatch(hideNotification());

    }, [dispatch, notification]);

    const handleUndo = useCallback(() => {
        dispatch(undo());
        undoableEventEmitter.emit("end", { isUndo: true });
    }, [dispatch]);

    useEffect(() => {
        setOpen(!! notification);
    }, [notification]);

    if (! notification) {
        return null;
    }

    const { type, message, notificationOptions = {}} = notification;
    const { autoHideDuration, messageArgs, translatable, undoable } = notificationOptions as NotificationOptions;

    return (
        <Snackbar
            action={
                undoable ? (
                    <UndoButton
                        className={type === "info" || type === "success" ? classes.undoOnSuccess : classes.undoOnError}
                        onClick={handleUndo}
                    />
                ) : null
            }
            autoHideDuration={autoHideDuration}
            disableWindowBlurListener={undoable}
            message={translatable ? translate(message, messageArgs) : message}
            open={open}
            onClose={handleClose}
            onExited={handleExit}
            ContentProps={{
                className: classNames(className, classes[type]),
            }}
            {...rest}
        />
    );
};

export default Notification;
