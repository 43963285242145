import React, { FunctionComponent } from "react";
import { useParams } from "react-router-dom";
import { SocialItemsList } from "../social";
import { TopicList } from "../topic";
import { useContentStyles } from "./useContentStyles";
import { useTranslate } from "../../service/translation";
import {Box, Grid} from '@material-ui/core';
import {FiltersContext} from "../../service/filters";

interface EmbedContentProps {
}

const EmbedContent: FunctionComponent<EmbedContentProps> = (props: EmbedContentProps) => {
    const classes = useContentStyles();
    const translate = useTranslate();
    const routeParams:any = useParams();
    const [filters,setFilters] = React.useContext(FiltersContext);

    React.useEffect(() => {
        setFilters({
            ...filters,
            form:{
                ...filters.form,
                itemId:routeParams.id
            }
        })
    },[]);

    return (
        <Grid container className={classes.page} id="main-content">
            <Grid container className={classes.pageWrapper}>
                <Grid item xs={12} sm={12} md className="gridItem"></Grid>
                <Grid item xs={12} sm={12} md={5} className="gridItem homeCardList">
                    <SocialItemsList />
                </Grid>
                <Grid item xs={12} sm={4} md className="gridItem  "></Grid>
            </Grid>
        </Grid>
    );
};

export default EmbedContent;
