import React, { FunctionComponent } from "react";
import {Box, Card, CardActions, CardContent} from "@material-ui/core";
import { Skeleton, SkeletonProps } from "@material-ui/lab";
import { useSocialCardStyles } from "./useSocialCardStyles";
import classNames from "classnames";

interface SocialCardSkeletonProps extends SkeletonProps {
    withImage?: boolean;
}

const SocialCardSkeleton: FunctionComponent<SocialCardSkeletonProps> = (props: SocialCardSkeletonProps) => {
    const classes = useSocialCardStyles();
    const { withImage, ...rest } = props;

    return (
        <Card className={classNames(classes.root, 'masonryItem')}>
            <Box className={classes.header}>
                <Box className={classes.avatar}>
                    <Skeleton
                        className={classes.avatarImage}
                        variant="circle"
                        height={56}
                        width={56}
                        {...rest}
                    />
                </Box>
                <Box className={classes.content}>
                    <Skeleton
                        variant="text"
                        width="55%"
                        {...rest}
                    />
                    <Skeleton
                        variant="text"
                        width="75%"
                        {...rest}
                    />
                    <Skeleton
                        variant="text"
                        width="40%"
                        {...rest}
                    />
                </Box>
                <Box className={classes.source}>
                    <Skeleton
                        variant="text"
                        width={56}
                        {...rest}
                    />
                    <Skeleton
                        className={classes.sourceIcon}
                        variant="circle"
                        height={32}
                        width={32}
                        {...rest}
                    />
                </Box>
            </Box>
            <CardContent>
                <Skeleton
                    variant="text"
                    width="90%"
                    {...rest}
                />
                <Skeleton
                    variant="text"
                    width="95%"
                    {...rest}
                />
                <Skeleton
                    variant="text"
                    width="35%"
                    {...rest}
                />
            </CardContent>
            {
                withImage && (
                    <CardContent>
                        <Skeleton
                            variant="rect"
                            height={128}
                            width="100%"
                        />
                    </CardContent>
                )
            }
            <CardActions>
                <Skeleton
                    variant="text"
                    width={128}
                    {...rest}
                />
                <Skeleton
                    variant="text"
                    width={96}
                    {...rest}
                />
                <Skeleton
                    variant="text"
                    width={56}
                    {...rest}
                />
            </CardActions>
        </Card>
    );
};

SocialCardSkeleton.defaultProps = {
    animation: "wave",
    withImage: false,
};

export default SocialCardSkeleton;
