import React, { FunctionComponent } from "react";
import { useParams } from "react-router-dom";
import { Mep } from "../../service/data";
import { MepDetails } from "../mep";
import { SocialItemsList, SocialLinks } from "../social";
import { TopicList } from "../topic";
import { useContentStyles } from "./useContentStyles";
import { useTranslate } from "../../service/translation";
import {Box, Grid} from "@material-ui/core";
import HistoryBackButton from "../button/HistoryBackButton";
import {apiProvider} from "../../service/data/apiProvider";
import classNames from "classnames";
import {Helmet} from "react-helmet-async";

interface MepContentProps {
}

const MepContent: FunctionComponent<MepContentProps> = (props: MepContentProps) => {
    const classes = useContentStyles();
    const translate = useTranslate();
    const routeParams:any = useParams();
    const [mep,setMep] = React.useState<any>(null)


    React.useEffect(() => {
        apiProvider.getContributor(routeParams.id)
            .then((provider) => {
               if(provider) setMep(provider as Mep);
            });
    },[routeParams.id]);


    return (
        <Grid container className={classes.page} id="main-content">
            <Grid container className={classes.pageWrapper}>
                <Grid item xs={12} md className="gridItem order1 xs_order1">
                    <HistoryBackButton />
                </Grid>
                <Grid item xs={12} sm={6} md className="gridItem order3 xs_order2">
                    <Box className={classes.aside}>
                        {
                            mep && (
                                <MepDetails record={mep} />
                            )
                        }
                        <Box className={classNames(classes.aside, "alCenter")}  sx={{ display: { xs: 'none', md: 'block' }}}>
                            {mep &&
                                <TopicList providerId={mep.id} title={translate("topic.message.title")} />
                            }
                        </Box>
                        {
                            mep && (
                                <SocialLinks record={mep} />
                            )
                        }
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={5} className="gridItem order2 xs_order3">
                    { mep &&
                    <SocialItemsList providerId={mep.id} />
                    }
                </Grid>
            </Grid>
        </Grid>
    );
};

export default MepContent;
