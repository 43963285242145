import { Button, ButtonProps, Typography } from "@material-ui/core";
import React, { FunctionComponent } from "react";
import { useTranslate } from "../../service/translation";

interface ViewAllButtonProps extends ButtonProps {
    component?: any;
    to?: string;
}

const ClearAllButton: FunctionComponent<ViewAllButtonProps> = (props: ViewAllButtonProps) => {
    const translate = useTranslate();

    // No button variant by default, so a text button is used
    // See https://material-ui.com/components/buttons/#text-buttons
    return (
        <Button
            aria-label={translate("newshub.action.clearAll")}
            color="primary"
            size="small"
            {...props}
        >
            <Typography variant="body2" color="primary" component="span">{translate("newshub.action.clearAll")}</Typography>
        </Button>
    );
};

export default ClearAllButton;
