import { createContext,useState } from "react";
import {getDayStart,getDayEnd,roundCurrentTime} from "../tools/Tools";
export interface AppFiltersInterface {
    theme:string,
    openFilters: boolean,
    counterResults?: number,
    searchBar: string,
    topicsVersion: number,
    advancedSearch:{
        showToggleButton:boolean,
        showPanel: boolean,
        filters: {
            allWords: string,
            anyWords: string[],
            hashtags: string[],
            andWords: string[],
            mentions: string[],
            excludeWords: string[],
            from: number,
            to: number
        }
    },
    form : {
        president?:boolean,
        byCountry?: boolean,
        countries?: string[],
        languages?: string[]
        byEparty?: boolean,
        eParties?: string[],
        politicalGroups?: boolean,
        committees?: boolean,
        accounts?: boolean,
        liaisonOffices?:boolean,
        commission?:boolean,
        eppress?: boolean,
        eye?:boolean,
        grouppress?:boolean,
        parlementariumTwitteroPolis?:boolean
        photos?: boolean,
        onlyText?:boolean,
        videos?: boolean,
        searchText?: string,
        searchType?: string;
        from ?: number|null,
        to ?:number | null,
        pageNo : number,
        pageSize : number
        scrollInfinite?: boolean,
        itemId?: string|null
    },
    timeline:{
        filter:string,
        from:number|null,
        to:number|null,
        begin:number,
        end:number,
        rangeActive?:boolean,
        
    },
    newContent:number,
    activeTopics: string[],
    channels:{
        type: string,
        pageSize: number,
        eParty: string,
        search?: string
    },
    currentProvider: number | null,
    crossActions:{
        clearSearchFilters: boolean,
        applySearchFilters: boolean,
        clearAdvancedSearch: boolean,
        applyAdvancedSearch: boolean,
        clearTopics:boolean,
        closeTopDrawer:boolean,
        
        searchBarEvent: {
            action: string,
            payload?:any
        },
        timeLineEvent:{
            action: string,
            payload?:any
        },
        SocialFetchEvent:{
            action: string,
            payload?:any
        },
        TutorialEvent:{
            action: string,
            payload?:any
        }

    },
    oldSearches:string[],
    currentSuggestions?:any[]
}

const appDefaultFilters = {
    theme:'ligth',
    openFilters: false,
    searchBar:'',
    topicsVersion:0,
    advancedSearch:{
        showToggleButton: false,
        showPanel: false,
        filters: {
            allWords: '',
            anyWords: [],
            hashtags: [],
            mentions: [],
            andWords: [],
            excludeWords:[],
            from: getDayStart() - 3600 * 24 * 30,
            to: getDayEnd()
        }
    },
    
    form:{
        president: false,
        byCountry: false,
        countries:[],
        byEparty: false,
        eParties:[],
        politicalGroups: false,
        committees: false,
        accounts: false,
        liaisonOffices: false,
        commission:false,
        eppress: false,
        eye:false,
        grouppress:false,
        parlementariumTwitteroPolis:false,
        photos: false,
        onlyText: false,
        videos: false,
        searchText: '',
        searchType: 'content',
        pageNo: 0,
        pageSize: 10,
        scrollInfinite : true,
        itemId:null
              
    },
    timeline:{
        filter: 'day',
        from: 0,
        to: 0,
        begin:20,
        end:60,
        rangeActive:false
    },
    activeTopics :[],
    newContent: 0,  //0 default value, 1-new content 2-content refresh
    channels: {
        type: 'all',
        pageSize: 50,
        eParty: 'All',
        search: ''
    },
    currentProvider:null,
    crossActions:{
        clearSearchFilters: false,
        applySearchFilters: false,
        clearAdvancedSearch: false,
        applyAdvancedSearch: false,
        clearTopics: false,
        closeTopDrawer:false,
       
        searchBarEvent: {
            action: '',
            payload:{}
        },
        timeLineEvent: {
            action: '',
            payload:{}
        },
        SocialFetchEvent : {
            action: '',
            payload:{}
        },
        TutorialEvent:{
            action : '',
            payload : {}
        }

    },
    oldSearches:[],
    currentSuggestions:[]
};

const FiltersContext = createContext<[AppFiltersInterface,Function]>([appDefaultFilters,() => {}]);
export {appDefaultFilters,FiltersContext};
