import React, { FunctionComponent, useState } from "react";
import classNames from "classnames";
import { Chip, ChipProps } from "@material-ui/core";
import { Topic } from "../../service/data";
import {FiltersContext,appDefaultFilters} from "../../service/filters";

interface TopicChipProps {
    classes: {
        low: string;
        medium: string;
        high: string;
        trending: string;
    };
    record: Topic;
  
}

    const TopicChip: FunctionComponent<TopicChipProps & ChipProps> = (props: TopicChipProps & ChipProps) => {
    const { className, classes, record: { content, virality }, ...rest } = props;
    const [isActive, setIsActive] = useState(false);
    const [filters,setFilters] = React.useContext(FiltersContext);

 
    React.useEffect(() => {
        let activate:boolean = (filters.activeTopics.indexOf(content) !== -1)? true :  false;
        setIsActive(activate);
    },[filters.activeTopics])


    const toggleClass = () => {
        let newActiveTopics:string[];
        if(filters.activeTopics.indexOf(content) === -1){
            // not found --> add;
            newActiveTopics = [...filters.activeTopics, content];
        }else {
            // found --> removeIt;
            newActiveTopics = filters.activeTopics.filter((topic) => topic !== content);
        }

        setFilters({...filters,activeTopics:newActiveTopics});
    };

    return (
        <Chip
            className={classNames(
                className, {
                    [classes.low]: virality === "low",
                    [classes.medium]: virality === "medium",
                    [classes.high]: virality === "high",
                    [classes.trending]: virality === "trending",
                    "active":isActive,
                }
                )}
            clickable
            onClick={toggleClass}
            label={(content.length > 20)? (content.substring(0,20) + '...') : content }
            {...rest}
        />
    );
};

export default TopicChip;
