import React, {FunctionComponent} from "react";
import {useTranslate} from "../../service/translation";
import {useContentStyles} from "./useContentStyles";
import {Box, Card, Grid, Link, Typography} from "@material-ui/core";
import classNames from "classnames";
import {useSocialCardStyles} from "../social";

const NotFoundContent: FunctionComponent = () => {
    const classes = useContentStyles();
    const cardClasses = useSocialCardStyles();
    const translate = useTranslate();

    return (

        <Grid container justifyContent={"center"} alignItems={"center"}
              className={classNames(classes.notFoundContentContainer, 'notFoundContent')}>
            <Card className={classNames(cardClasses.root, 'masonryItem')} aria-live="polite">
                <Grid item>

                    <Box mb={4} my={2}>
                        <Typography component={"h2"} variant={"h2"}
                                    gutterBottom>{translate("newshub.error.notFoundContent.title")}</Typography>
                        <Typography component={"p"} variant={"h6"}
                                    gutterBottom>{translate("newshub.error.notFoundContent.description1")}</Typography>
                    </Box>
                    <Box px={2} py={2}>
                        <Typography component={"h3"}
                                    gutterBottom>{translate("newshub.error.notFoundContentTips.title")}</Typography>
                        <Typography component={"p"} variant={"h6"}
                                    gutterBottom>{translate("newshub.error.notFoundContentTips.tip1")}</Typography>
                        <Typography component={"p"} variant={"h6"}
                                    gutterBottom>{translate("newshub.error.notFoundContentTips.tip2")}</Typography>
                        <Typography component={"p"} variant={"h6"}
                                    gutterBottom>{translate("newshub.error.notFoundContentTips.tip3")}</Typography>
                        <Typography component={"p"} variant={"h6"}
                                    gutterBottom>{translate("newshub.error.notFoundContentTips.tip4")}<Link
                            href="/timeline" underline="always">{translate("newshub.error.notFoundContentTips.link")}</Link></Typography>
                    </Box>
                </Grid>
            </Card>
        </Grid>

    );
};

export default NotFoundContent;
