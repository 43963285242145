import {Button, ButtonProps, makeStyles, Typography} from "@material-ui/core";
import React, { FunctionComponent } from "react";
import { useTranslate } from "../../service/translation";
import {FiltersContext} from "../../service/filters";


const ClearTimelineFilterButton: FunctionComponent<ButtonProps> = (props: ButtonProps) => {
    const translate = useTranslate();
    const [filters,setFilters] = React.useContext(FiltersContext);

    const resetTimeLine = () => {
        setFilters({
            ...filters,
            crossActions:{
                ...filters.crossActions,
                timeLineEvent: {
                    action:'reset',
                    payload:{}
                }
            }
        });
    };

    // No button variant by default, so a text button is used
    // See https://material-ui.com/components/buttons/#text-buttons
    return (
        <Button
            onClick = {resetTimeLine}
            aria-label={translate("newshub.action.clearAllTimeLine")}
            color="primary"
            size="small"
            {...props}
        >
          <Typography variant="body2" color="primary" component="span">{translate("newshub.action.clearAllTimeLine")}</Typography>
        </Button>
    );
};

export default ClearTimelineFilterButton;
