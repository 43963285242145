import { Dispatch } from "redux";
import { FETCH_END, FETCH_ERROR, FETCH_START } from "../fetch";
import { DataProvider } from "./DataProvider";
import { DataProviderOptions } from "./DataProviderOptions";
import { DataProviderResult } from "./DataProviderResult";
import { DataProviderParams } from "./DataProviderParams";
import { GetCollectionParams } from "./GetCollectionParams";
import { GetInstanceParams } from "./GetInstanceParams";
import { MutationMode } from "./MutationMode";
import { OnFailureCallback } from "./OnFailureCallback";
import { OnSuccessCallback } from "./OnSuccessCallback";
import { showNotification } from "../notification";
import { refreshView } from "../refresh";
import { startOptimisticMode, stopOptimisticMode, undoableEventEmitter } from "../undo";

const sleep = (delay: number = 100): Promise<void> => new Promise<void>(resolve => setTimeout(resolve, delay));

const maxWaitCount = 100;
const waitFor = (condition: () => boolean, count: number = 0): Promise<void> => {
    if (count > maxWaitCount) {
        return Promise.reject(new Error("Max wait count exceeded"));
    }

    console.log("waiting for condition", condition());
    return new Promise(resolve => condition() ? resolve() : sleep().then(() => waitFor(condition, count + 1).then(() => resolve())));
}

type CallParams = {
    action: string;
    dataProvider: DataProvider;
    dispatch: Dispatch;
    mutationMode: MutationMode;
    onFailure?: OnFailureCallback;
    onSuccess?: OnSuccessCallback;
    params: GetCollectionParams | GetInstanceParams;
    signature?: string;
    type: string;
    [key: string]: any;
};

// As all dataProvider methods do not have the same signature, we must
// differentiate standard methods which have the (params, options)
// signature from the custom ones
const getDataProviderCallArguments = (args: any[]): {
    params: GetInstanceParams | GetCollectionParams;
    options?: DataProviderOptions;
} => {
    const [params, options] = args;

    return { params, options };
};


// A list of dataProvider calls emitted while in optimistic mode.
// These calls get replayed once the dataProvider exits the
// optimistic mode
const stackedCalls: CallParams[] = [];
const stackedOptimisticCalls: CallParams[] = [];
let numRemainingStackedCalls = 0;

const getRemainingStackedCalls = (): number => numRemainingStackedCalls;

const stackCall = (call: CallParams): void => {
    stackedCalls.push(call);
    numRemainingStackedCalls ++;
};

const stackOptimisticCall = (call: CallParams): void => {
    stackedOptimisticCalls.push(call);
    numRemainingStackedCalls ++;
};

const replayStackedCallsWith = async (stack: CallParams[]) => {
    const clone = [...stack];

    // Remove these calls from the stack *before* doing them
    // because side effects in the calls can add more calls
    // so we don't want to erase these.
    stack.splice(0, stackedOptimisticCalls.length);

    await Promise.all(
        clone.map(call => Promise.resolve(performCall.call(null, call)))
    );

    // Once the calls are finished, decrement the number of
    // remaining calls
    numRemainingStackedCalls -= clone.length;
};

// We must perform any undoable queries first so that the effects of
// previous undoable queries do not conflict with this one. We only
// handle all side effects queries if there are no more undoable
// queries
const replayStackedCalls = async () => stackedOptimisticCalls.length > 0 ?
    replayStackedCallsWith(stackedOptimisticCalls) :
    replayStackedCallsWith(stackedCalls);

// Delegates execution to cache, optimistic, undoable, or pessimistic
// requests
const performCall = (call: CallParams): Promise<DataProviderResult> => {
    const { mutationMode } = call;

    if (canReplyWithCache(call)) {
        return replyWithCache(call);
    }

    if (mutationMode === "optimistic") {
        return performOptimisticCall(call);
    }

    if (mutationMode === "undoable") {
        return performUndoableCall(call);
    }

    return performPessimisticCall(call);
};

const getResultFromCache = (type: string, params: DataProviderParams): DataProviderResult => {
    const error = new Error();
    error.message = "CacheError";
    error.message = `
        Could not retrieve dataProvider result from cache after determining
        that the request could be served from local cache.
    `;

    throw error;
};

const canReplyWithCache = (call: CallParams): boolean => {
    return false;
};

const replyWithCache = async (call: CallParams): Promise<DataProviderResult> => {
    const {
        action,
        dispatch,
        params,
        onSuccess,
        signature,
        type,
    } = call;

    dispatch({
        type: action,
        payload: params,
        meta: { type },
    });
    const result = getResultFromCache(type, params);
    dispatch({
        type: `${action}_SUCCESS`,
        payload: result,
        requestPayload: params,
        meta: {
            type,
            signature,
            status: FETCH_END,
            fromCache: true,
        },
    });

    if (onSuccess) {
        onSuccess(result);
    }

    return result;
};

// In optimistic mode, the useDataProvider hook dispatches an optimistic
// action and executes the success side effects right away. Then it
// immediately calls the dataProvider.
//
// We call that "optimistic" because the hook returns a resolved Promise
// immediately (although it has an empty value). That only works if the
// caller reads the result from the Redux store, not from the Promise.
const performOptimisticCall = async (call: CallParams): Promise<DataProviderResult> => {
    const {
        action,
        dataProvider,
        dispatch,
        params,
        onFailure,
        onSuccess,
        signature,
        type,
    } = call;

    dispatch(startOptimisticMode());
    dispatch({
        type: action,
        payload: params,
        meta: { type },
    });
    dispatch({
        type: `${action}_OPTIMISTIC`,
        payload: params,
        meta: {
            type,
            optimistic: true,
        },
    });

    if (onSuccess) {
        onSuccess({});
    }

    setTimeout(async () => {
        dispatch(stopOptimisticMode());
        dispatch({
            type: `${action}_LOADING`,
            payload: params,
            meta: { type },
        });
        dispatch({ type: FETCH_START });

        // noinspection DuplicatedCode
        try {
            // @ts-ignore
            const result = await dataProvider[type](params);

            if (process.env.NODE_ENV !== "production") {
                validateResult(result, type);
            }

            dispatch({
                type: `${action}_SUCCESS`,
                payload: result,
                requestPayload: params,
                meta: {
                    type,
                    signature,
                    status: FETCH_END,
                },
            });
            dispatch({ type: FETCH_END });

            // noinspection ES6MissingAwait
            replayStackedCalls();

            return result;

        } catch (error) {
            if (process.env.NODE_ENV !== "production") {
                console.error(error);
            }

            dispatch({
                type: `${action}_FAILURE`,
                error: error.message ? error.message : error,
                requestPayload: params,
                meta: {
                    type,
                    signature,
                    status: FETCH_ERROR,
                },
            });
            dispatch({ type: FETCH_ERROR, error });

            if (onFailure) {
                onFailure(error);
            }

            throw error;
        }
    });

    return { status: 0 };
};

// In pessimistic mode, the useDataProvider hook calls the dataProvider.
// When a successful response arrives, the hook dispatches a SUCCESS action,
// executes success side effects and returns the response. If the response
// is an error, the hook dispatches a FAILURE action, executes failure side
// effects, and throws an error.
const performPessimisticCall = async (call: CallParams): Promise<DataProviderResult> => {
    const {
        action,
        dataProvider,
        dispatch,
        onFailure,
        onSuccess,
        params,
        signature,
        type,
    } = call;

    dispatch({
        type: action,
        payload: params,
        meta: { type },
    });
    dispatch({
        type: `${action}_LOADING`,
        payload: params,
        meta: { type },
    });
    dispatch({ type: FETCH_START });

    // noinspection DuplicatedCode
    try {
        // @ts-ignore
        const result = await dataProvider[type](params);

        if (process.env.NODE_ENV !== "production") {
            validateResult(result, type);
        }

        dispatch({
            type: `${action}_SUCCESS`,
            payload: result,
            requestPayload: params,
            meta: {
                type,
                signature,
                status: FETCH_END,
            },
        });
        dispatch({ type: FETCH_END });

        if (onSuccess) {
            onSuccess(result);
        }

        return result;

    } catch (error) {
        if (process.env.NODE_ENV !== "production") {
            console.error(error);
        }

        dispatch({
            type: `${action}_FAILURE`,
            error: error.message ? error.message : error,
            requestPayload: params,
            meta: {
                type,
                signature,
                status: FETCH_ERROR,
            },
        });
        dispatch({ type: FETCH_ERROR, error });

        if (onFailure) {
            onFailure(error);
        }

        throw error;
    }
};

// In undoable mode, the hook dispatches an optimistic action and executes
// the success side effects right away. Then it waits for a few seconds to
// actually call the dataProvider - unless the user dispatches an Undo action.
//
// We call that "optimistic" because the hook returns a resolved Promise
// immediately (although it has an empty value). That only works if the
// caller reads the result from the Redux store, not from the Promise.
const performUndoableCall = (call: CallParams): Promise<DataProviderResult> => {
    const {
        action,
        dataProvider,
        dispatch,
        params,
        onFailure,
        onSuccess,
        signature,
        type,
    } = call;

    dispatch(startOptimisticMode());

    if (window) {
        window.addEventListener("beforeunload", warnBeforeCloseWindow, {
            capture: true,
        });
    }

    dispatch({
        type: action,
        payload: params,
        meta: { type },
    });
    dispatch({
        type: `${action}_OPTIMISTIC`,
        payload: params,
        meta: { type },
    });

    if (onSuccess) {
        onSuccess({});
    }

    undoableEventEmitter.once("end", async ({ isUndo }): Promise<void> => {
        dispatch(stopOptimisticMode());

        if (isUndo) {
            dispatch(showNotification("warning", "newshub.notification.cancelled"));
            dispatch(refreshView());

            if (window) {
                window.removeEventListener("beforeunload", warnBeforeCloseWindow, {
                    capture: true,
                });
            }

            return;
        }

        // noinspection DuplicatedCode
        dispatch({
            type: `${action}_LOADING`,
            payload: params,
            meta: { type },
        });
        dispatch({ type: FETCH_START });

        try {
            // @ts-ignore
            const result = await dataProvider[type](params);

            if (process.env.NODE_ENV !== "production") {
                validateResult(result, type);
            }

            dispatch({
                type: `${action}_SUCCESS`,
                payload: result,
                requestPayload: params,
                meta: {
                    type,
                    signature,
                    status: FETCH_END,
                },
            });
            dispatch({ type: FETCH_END });

            if (window) {
                window.removeEventListener("beforeunload", warnBeforeCloseWindow, {
                    capture: true,
                });
            }

            // noinspection ES6MissingAwait
            replayStackedCalls();

        } catch (error) {
            if (window) {
                window.removeEventListener("beforeunload", warnBeforeCloseWindow, {
                    capture: true,
                });
            }

            // noinspection DuplicatedCode
            if (process.env.NODE_ENV !== "production") {
                console.error(error);
            }

            dispatch({
                type: `${action}_FAILURE`,
                error: error.message ? error.message : error,
                requestPayload: params,
                meta: {
                    type,
                    signature,
                    status: FETCH_ERROR,
                },
            });
            dispatch({ type: FETCH_ERROR, error });

            if (onFailure) {
                onFailure(error);
            }

            throw error;
        }
    });

    return Promise.resolve({ status: 0 });
};

const validateResult = (result: DataProviderResult, type: string, logger = console.error): void => {
    if (! result.hasOwnProperty("data")) {
        logger(`The response to ${type} must contain a data property`);
    }
};

const warnBeforeCloseWindow = (event: BeforeUnloadEvent) => {
    event.preventDefault(); // Standard
    event.returnValue = ""; // Chrome

    return "Your latest modifications are not yet saved. Are you sure?"; // Old IE
};

export {
    getDataProviderCallArguments,
    getRemainingStackedCalls,
    performCall,
    performOptimisticCall,
    performPessimisticCall,
    performUndoableCall,
    replayStackedCalls,
    stackCall,
    stackOptimisticCall,
    sleep,
    validateResult,
    waitFor,
}
