import React, { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import MepAvatar from "./MepAvatar";
import {Box, Card, CardHeader, Grid, MenuItem, Typography} from "@material-ui/core";
import { Mep } from "../../service/data";
import { ViewChannelButton } from "../button";
import {FiltersContext} from "../../service/filters"

interface MepCardProps {
    className?: string;
    record: Mep;
}

const MepCard: FunctionComponent<MepCardProps> = (props: MepCardProps) => {
    const { className, record } = props;
    const { id, country, firstName, lastName, politicalGroup: { party }} = record;
    const [filters,setFilters] = React.useContext(FiltersContext);

    const highlightRegExp = () => {
        let search:string = filters.channels.search || '';
        let reg:string = search.split(' ').filter(item => item.length > 0).join('|');
        return new RegExp(reg,'ig');
    }

    const regexp = React.useRef(highlightRegExp());

    React.useEffect(() => {
        regexp.current = highlightRegExp();
    },[filters.channels.search]);


    const highlightContent = (text:string):string => {
        let search:string = filters.channels.search || '';
        if(search.length === 0) return text;
        return text.replace(regexp.current, (item:string,i:number) => {
            return '<span style="background-color:#ead183">'+item+'</span>';
         })
        
    };


    return (
        <Grid item xs={12} sm={6} md={6} lg={4} className="gridItem mepGridItem">
            <Link to={`/contributors/${id}`} className="dblock">
                <Card className={className}>
                    <CardHeader
                        action={
                            <ViewChannelButton />
                        }
                        avatar={
                            <MepAvatar record={record} />
                        }
                        subheader={
                            <Box className="preWrap">
                                <Typography variant="subtitle2" display="inline" component="p">
                                    <strong>
                                        {party}
                                        {party? ", " :''}
                                    </strong>
                                </Typography>
                                <Typography variant="subtitle2" display="inline" component="p">
                                    {country}
                                </Typography>
                            </Box>
                        }
                        title={
                            <Typography variant="h2" component="p">
                                <div  dangerouslySetInnerHTML={{ __html: highlightContent(`${firstName} ${lastName}`) }} />
                                                 
                            </Typography>
                        }
                    />
                </Card>
            </Link>
        </Grid>
    );
};

export default MepCard;
