import React, { FunctionComponent ,useState,useEffect,useContext} from "react";
import {useLocation} from 'react-router-dom';
import SocialCardList from "./SocialCardList";
import SocialCardListSkeleton from "./SocialCardListSkeleton";
import { ErrorContent503,NotFoundContent,NotFoundContentTimeline,NotFoundContentHome } from "../layout";
import {FiltersContext,MapSocialParams} from "../../service/filters";
import {apiProvider,ApiParamsType} from "../../service/data/apiProvider";
import {roundCurrentTime,getDayEnd} from "../../service/tools/Tools";

import classNames from "classnames";
import Masonry from "react-masonry-component";
import AdvancedSearchForm from "../layout/AdvancedSearchFrom";
import {Box, Typography} from "@material-ui/core";
import {useTranslate} from "../../service/translation/hooks";
import {useTopicStyles} from "../topic";
import {useContentStyles} from "../layout/useContentStyles";


interface SocialItemsListProps {
    providerId?: number,
    resultsType?: string
}

const SocialItemsList: FunctionComponent<SocialItemsListProps> = (props: SocialItemsListProps) => {
    const classes = useContentStyles();
    const translate = useTranslate();
    const {providerId,resultsType} = props;
    const [filters,setFilters] = useContext(FiltersContext);
    const [itemsState, setItemsState] = useState<any>(null);
    const [isFetching, setIsFetching] = useState(0); //0  not fetching 1-by params, 2-next page
	const [nextPage, setNextPage] = useState('');
    const [hasError,setHasError] = useState(false);
    const [version,setVersion] = useState(0);
    const [total, setTotal] = useState(0);

    let intervalId:any = React.useRef(null);
    let lastVersion = React.useRef(0);
    
    useEffect(() => {
        if(intervalId.current) clearInterval(intervalId.current);
        //setFilters({...appDefaultFilters});
        window.removeEventListener('scroll', handleScroll);
        window.addEventListener('scroll', handleScroll);
	}, []);

    React.useEffect(() => {
        
        if(lastVersion.current >= version) return;
        lastVersion.current = version;
        //check new content
        if( !itemsState || itemsState.length === 0) return;
        let newFilters = {...filters};
        newFilters.form.to = getDayEnd();
        let params = {...newFilters};
        params.form.pageNo = 0;

        apiProvider.getSocialItems( {...MapSocialParams(params)},providerId)
        .then(response => {
        
            if (itemsState.length > 0
                && response.items.length > 0
                && itemsState[0].id !== response.items[0].id)
                {
                    newFilters.newContent = 1;
                    setFilters(newFilters);
                    if(intervalId.current) clearInterval(intervalId.current);
                }
        });
    },[version]);


    React.useEffect(() => {
        if(filters.newContent !== 2) return;
        setIsFetching(1);

    },[filters.newContent])


    const setNewcontentInterval = () => {
        if(intervalId.current) clearInterval(intervalId.current);
        let i = 0;
        intervalId.current =  setInterval(() => {setVersion(i++)}, 60000)
    };


    const handleScroll = () => {
        if (
            (document.documentElement.scrollTop + window.innerHeight < document.body.scrollHeight - 800)
     		||	isFetching > 0
		){
		    return;
        }
        setIsFetching(2);
	};


     const fetchData = (toNextPage:boolean = false) => {
        console.log('Filters Effect form');
        let params:ApiParamsType| string;

        let newFilters = {...filters};

        console.log('Form >>> ', newFilters.form);
        if(toNextPage){
            if(!nextPage || nextPage === '') return;
            params = nextPage;

        }else {
            newFilters.form.to = roundCurrentTime(1);
            if(resultsType === 'timeline' && newFilters.timeline.from === 0){
                newFilters.timeline.to = newFilters.form.to;
                newFilters.timeline.from = newFilters.form.to - 3600 * 24;
            }

            params = {...MapSocialParams(newFilters)};
        }
        console.log('PARAMS',params );
        if(hasError) setHasError(false);
        apiProvider.getSocialItems(params, providerId)
        .then((response) => {
            let oldItems = (itemsState === null)? [] :itemsState;
            let newState = (toNextPage)? [...oldItems, ...response.items] : [...response.items];
            
            setNextPage(response.next);
            setTotal(response.total);
            setItemsState(newState);
            setTimeout(() => {setIsFetching(0);}, 500);
            setNewcontentInterval();

            console.log('counter results in tsocialItems list',response.total );
            if(!toNextPage) {
                setFilters({
                    ...newFilters,
                    topicsVersion: newFilters.topicsVersion + 1,
                    counterResults : response.total
                });
            }

        })
        .catch((error) => {
            console.log('ERROR >>', error);
            setHasError(true);
        });
	};



    useEffect(() => {
        setIsFetching(1);//refresh page
    },[providerId,filters.activeTopics,filters.form,filters.advancedSearch.filters]);


    useEffect(() => {
        if (isFetching === 0) return 
        let toNextPage = (isFetching === 2)? true: false;
        fetchData(toNextPage);
	}, [isFetching]);

    useEffect(() => {
        if (filters.newContent) return;
        setIsFetching(1); //refresh page
	}, [filters.newContent]);


    if ( itemsState === null || isFetching === 1) {
        return (
          <SocialCardListSkeleton numItems={5} />
         );
    }

    if(hasError){
        return (
            <ErrorContent503/>
        );
    }

    if(itemsState.length === 0){
        switch (resultsType){
            case 'home':
                return (
                    <NotFoundContentHome/>
                );
                break;
            case 'timeline':
                return (
                    <NotFoundContentTimeline/>
                );
                break;
            
            default:
                return (
                    <NotFoundContent/>
                );
        }
    }

    
    return (
        <>
        { !/tw|embed/.test(window.location.pathname)  && 
            <Box mb={1} className={classes.counterContainer}>
                <Typography variant={"h6"} component={"p"}>{translate((total ===1)?  "newshub.pagination.resultsOne": "newshub.pagination.results",{total:total})}</Typography>
            </Box>
        }
            <SocialCardList
                showCounter
                data={itemsState}
                page={1}
                pageSize={25}
                total={itemsState.length}
            />
        </>
    );
};

export default SocialItemsList;
