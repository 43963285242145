import { DEFAULT_CACHE_TTL } from "./index";
import { Resource } from "./Resource";
import { ResourceList } from "./ResourceList";
import { ResourceMap } from "./ResourceMap";

const map = <T extends Resource>(data: T | ResourceList<T>): ResourceMap<T> => {
    const map: ResourceMap<T> = {};

    if (! Array.isArray(data)) {
        data = [data];
    }

    for (const record of data) {
        map[ record.id ] = record;
    }

    return map;
}

export class ResourceCacheEntry<T extends Resource> {
    readonly data: ResourceMap<T>;
    readonly total: number;
    readonly maxAge: number;
    readonly expireAt: Date;

    constructor(data: T | ResourceList<T>, timeToLiveInSeconds: number = DEFAULT_CACHE_TTL) {
        this.data = map(data);
        this.total = data.length;
        this.maxAge = 1e3 * timeToLiveInSeconds;
        this.expireAt = new Date(Date.now() + this.maxAge);
    }

    get age(): number {
        return this.maxAge - (this.expireAt.getTime() - Date.now());
    }

    get fresh(): boolean {
        return Date.now() < this.expireAt.getTime();
    }
}
