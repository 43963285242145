import React, { FunctionComponent, useCallback, useEffect, useState } from "react";
import ErrorContent from "./ErrorContent";
import { Box, Grid } from "@material-ui/core";
import SocialItemsList from "../social/SocialItemsList";
import { TopicList } from "../topic";
import { Timeline, TimelineFilterType } from "../timeline";
import { useContentStyles } from "./useContentStyles";
import { SocialItem, useDataProvider } from "../../service/data";
import { useTranslate } from "../../service/translation";
import classNames from "classnames";
import TimelineTutorial from "./JoyrideTimelineTutorial";
import TimelineTutorialXs from "./JoyrideTimelineTutorialXs";


const TimelineContent: FunctionComponent = () => {
    const classes = useContentStyles();
    const translate = useTranslate();
    const dataProvider = useDataProvider();
    const [data, setData] = useState([] as SocialItem[]);

    const [error, setError] = useState(null);
    const topics = data && data.length ? [] : [];

    const handleFilter = (filter: TimelineFilterType) => {};
    const handleRangeSelect = () => {};



    if (error) {
        return (
            <ErrorContent errorMessage="Something bad happened" />
        );
    }

    return (
        <Grid container className={classes.page} id="main-content"  >
            {/*<Box sx={{ display: { xs: 'none', md: 'block' }}}>
                <TimelineTutorial
                    breakpoint={""}
                    translate={translate}
                />
            </Box>
            <Box sx={{ display: { xs: 'block', md: 'none' }}}>
                <TimelineTutorialXs
                    breakpoint={""}
                    translate={translate}
                />
            </Box>*/}
            <Grid container className={classes.pageWrapper}>
                <Grid item xs={12} className="gridItem relative">
                    {/*<Box className={classNames('joyrideTimeline00')} sx={{ display: { xs: 'none', md: 'block' }}}></Box>
                    <Box className={classNames('joyrideTimelineXs00')} sx={{ display: { xs: 'block', md: 'none' }}}></Box>*/}
                    <Timeline
                        data={data}
                        onFilter={handleFilter}
                        onRangeSelect={handleRangeSelect}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md className="gridItem order2 sm_order1 xs_order1">
                    <Box className={classes.aside} sx={{ display: { xs: 'none', md: 'block' }}}>
                        <TopicList justifyContent="left" title={translate("topic.message.title")} />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={9} className="gridItem order1 sm_order2 xs_order2">
                    <SocialItemsList resultsType="timeline"  />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default TimelineContent;
