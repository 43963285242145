import classNames from "classnames";
import { CardContent } from "@material-ui/core";
import { FunctionComponent } from "react";
import { SocialMedia } from "../../service/data";
import {PlayArrow} from "@material-ui/icons";

interface SocialCardMediaProps {
    className: string;
    classes: {
        audio: string;
        image: string;
        video: string;
    };
    media?: SocialMedia;
    title: string;
    source?: string;
}

const SocialCardMedia: FunctionComponent<SocialCardMediaProps> = (props: SocialCardMediaProps) => {
    const { className, classes, media, title ,source} = props;

    if (! media) {
        return null;
    }

    const { type, src } = media;

    switch (type) {
        case "photo":
            return (
                <CardContent>
                    <img
                        alt={title}
                        className={classNames(className, classes.image)}
                        src={src}
                        width={"100%"}
                        height={"200"}
                    />

                </CardContent>
            );
        case "video":
            return (
                <CardContent>
                    <a href={source} target="_blank" className="" rel="noopener noreferrer">
                        <img
                            alt={title}
                            className={classNames(className, classes.image)}
                            src={src}
                            width={"100%"}
                            height={"200"}
                        />
                        <PlayArrow/>
                    </a>
                </CardContent>
            );
    }

    const error = new Error();
    error.name = "UnsupportedMediaTypeError";
    error.message = `
        Unsupported media type "${type}". Supported media types
        are "image", "audio", and "video"
    `;

    throw error;
};

export default SocialCardMedia;
