import React, { FunctionComponent,useState,useEffect,useContext } from "react";
import {Box, Button, Grid, makeStyles} from "@material-ui/core";
import { useTranslate } from "../../service/translation";
import {apiProvider,ApiParamsType,ContributorType} from "../../service/data/apiProvider";
import { any } from "prop-types";
import {FiltersContext} from "../../service/filters/FiltersContext"
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";



const useStyles = makeStyles(theme => ({
    groupFilterContainer: {
        padding: theme.spacing(2,0,2),
        width: "100%",
        whiteSpace: "nowrap",
        flexWrap: "nowrap",
        maxWidth: "100%",
        overflowX: "auto",
        position: "relative",
        [theme.breakpoints.down("sm")]: {
            justifyContent: "start",
        },
        "& button": {
            margin: theme.spacing(0,1),
            boxShadow: "none !important",
            backgroundColor: theme.palette.type === "light" ? "#f6f8fa" : theme.palette.background.default,
            color: theme.palette.type === "light" ? "#555555" : theme.palette.secondary.dark,
            fontWeight: "normal",
            "&.active": {
                backgroundColor: theme.palette.type === "light" ? theme.palette.primary.main : theme.palette.primary.main,
                color: theme.palette.type === "light" ? "white" : "black",
            },
            "&:hover": {
                backgroundColor: theme.palette.type === "light" ? theme.palette.primary.main : theme.palette.primary.main,
                color: theme.palette.type === "light" ? "white" : "black",
            },
        },
    },
    leftArrow: {
        display: "none",
        position: "absolute",
        top: 0,
        left: 0,
        height: "100%",
        width: "90px",
        backgroundColor: "transparent !important",
        background:  theme.palette.type === "light" ? "linear-gradient(to right, #f6f8fa 0%,rgba(255,255,255,0) 100%) !important" : "linear-gradient(to right, #333 0%,rgba(255,255,255,0) 100%) !important",
        zIndex:1,
        margin: "0 !important",
        padding: "0 !important",
        minWidth: "0 !important",
        borderRadius: "0 !important",
        justifyContent: "flex-start",
        color: theme.palette.type === "light" ? "#ccc !important" : "#ccc !important",
        pointerEvents: "none",
        [theme.breakpoints.down("sm")]: {
            display: "flex",
        }
    },
    rightArrow: {
        display: "none",
        position: "absolute",
        top: 0,
        right: 0,
        height: "100%",
        width: "90px",
        backgroundColor: "transparent !important",
        background:  theme.palette.type === "light" ? "linear-gradient(to left, #f6f8fa 0%,rgba(255,255,255,0) 100%) !important" : "linear-gradient(to left, #333 0%,rgba(255,255,255,0) 100%) !important",
        zIndex:1,
        margin: "0 !important",
        padding: "0 !important",
        minWidth: "0 !important",
        borderRadius: "0 !important",
        justifyContent: "flex-end",
        color: theme.palette.type === "light" ? "#ccc !important" : "#ccc !important",
        pointerEvents: "none",
        [theme.breakpoints.down("sm")]: {
            display: "flex",
        }
    },
}), { name: "MepFilter" });

const MepTopFilter: FunctionComponent = () => {
    const classes = useStyles();
    const translate = useTranslate();
    const [contributorTypes, setContributorTypes] = useState<ContributorType[]>([]);
    const [contributorFilter, setContributorFilter]= useState<string|null>(null);
    const [filters,setFilters] = useContext(FiltersContext);

    const fetchContributorsType = () => {
       apiProvider.getContributorTypes().then((contributors) => {
            setContributorTypes(contributors)
       });
    };




    const contributorHandler = (key:string) => {
       let value:string = (filters.channels.type === key)? 'all':key;
        setFilters({
            ...filters,
            channels: {
                ...filters.channels,
                type: value,
                eParty: 'All'

            }
        });
    }

    useEffect(() => {
        fetchContributorsType();
    }, [])


    return (
        <>
            <Button className={classes.leftArrow}><ChevronLeftIcon /></Button>
            <Button className={classes.rightArrow}><ChevronRightIcon /></Button>
            <Grid container justifyContent="center" className={classes.groupFilterContainer}>

                {contributorTypes.map((item,index) =>

                    <Box display="flex" alignItems="center">
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() => { contributorHandler(item.type);}}
                            size="medium"
                            className={filters.channels.type === item.type? 'active':''}
                        >
                            {translate(item.name)}
                        </Button>


                    </Box>
                )}
            </Grid>
        </>
    );
};

export default MepTopFilter;
