import { History } from "history";
import { Reducer, combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { newshubReducer } from "./newshubReducer"

const defaultReducer = () => null;

interface CustomReducers {
    [key: string]: Reducer,
}

const createNewshubReducer = (customReducers: CustomReducers, history: History) => combineReducers({
    newshub: newshubReducer || defaultReducer,
    router: connectRouter(history),
    ...customReducers,
});

export { createNewshubReducer };
