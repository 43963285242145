import { NotificationType } from "../NotificationType";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { showNotification } from "../actions";

/**
 * Hook for notification side effect.
 *
 * @example
 * const notify = useNotify();
 *
 * notify("info", "Hello!");
 */
const useNotify = () => {
    const dispatch = useDispatch();

    return useCallback((
        type: NotificationType,
        message,
        messageArgs = {},
        translatable = true,
        undoable = false,
        autoHideDuration?: number
    ) => {
        dispatch(showNotification(type, message, {
            autoHideDuration,
            messageArgs,
            translatable,
            undoable,
        }));
    }, [dispatch]);
};

export { useNotify };
