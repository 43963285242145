import {allowedContributorTypes} from '../data/apiProvider';


export type ContributorsParamsType = {
    [key: string]: string | number ;
}



export function MapContributorParams(filters:any): ContributorsParamsType {
    let params:ContributorsParamsType = {};
    for(let i in filters){
        switch(i){
            case 'pageSize':
                params[i] = filters[i];
                break;
            case 'eParty':    
                if(filters[i] !== 'All') params[i] = filters[i];
                break;
            case 'type':
                params[i] = (filters[i] !== 'all')? filters[i] : '';
                break;
            case 'search':
                let searchValue:string = filters[i];
                params['search-value'] = searchValue.split(' ').filter(item => item.length > 0).join('|');
                params['search-type'] = 'contributor';
               
        }

        if(typeof params['type'] === 'undefined' || params['type'] === 'all' ) {
            params['type'] = allowedContributorTypes.join(',');
        }
    }
    return params;
}