import Polyglot from "node-polyglot";
import messages from "./messages";
import { Translate } from "./Translate";
import { getBrowserLocale } from "./translationUtil";

type TranslateOptions = Polyglot.PolyglotOptions;

const createTranslate = (locale: string, messages: object, polyglotOptions?: TranslateOptions): Translate => {
    const polyglot = new Polyglot({ locale, phrases: { "": "", ...messages }, ...polyglotOptions });

    return polyglot.t.bind(polyglot);
};

const getMessages = (locale: string): object => {
    if (! (locale in messages)) {
        const error = new Error();
        error.name = "MissingLocaleError";
        error.message = `
            No translation dictionary available for locale "${locale}". 
            Available locales are "${Object.keys(messages).join('", "')}"
        `;

        throw error;
    }

    // @ts-ignore
    return (messages[locale]) as object;
};

const polyglotProvider = (polyglotOptions?: TranslateOptions) => {
    let locale = 'en'; //getBrowserLocale();
    let translate = createTranslate(locale, getMessages(locale), polyglotOptions);

    return {
        translate,
        getLocale: (): string => locale,
        changeLocale: (newLocale: string): Promise<void> =>
            // We consistently return a Promise for the messages
            // because getMessages may return a Promise
            Promise.resolve(getMessages(newLocale)).then(messages => {
                locale = newLocale;
                translate = createTranslate(locale, messages, polyglotOptions);
            }),
    };
};

export default polyglotProvider;
