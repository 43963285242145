import {roundCurrentTime} from '../tools/Tools';
import {appDefaultFilters} from './FiltersContext'
import {allowedContributorTypes} from '../data/apiProvider';


export type TopicParamsType = {
    [key: string]: string | number ;
}

function getFormParams (filtersForm:any) : TopicParamsType {
    let params: TopicParamsType = {};

    for(let i in filtersForm){
        switch(i){
            case 'from':
                if(filtersForm[i] && filtersForm[i]>0) params[i] = filtersForm[i];
                break;
            case 'to':
                params[i] = filtersForm[i];
                break;
        }
    }

    return params;
}

function applyAdvancedSearchFilters(params:TopicParamsType,advancedSearchFilters:any ) {
    let newParams =  {
        ...params,
    };

    if(advancedSearchFilters.to && advancedSearchFilters.to !== appDefaultFilters.advancedSearch.filters.to) newParams['to'] = advancedSearchFilters.to;
    if(advancedSearchFilters.from && advancedSearchFilters.from  !== appDefaultFilters.advancedSearch.filters.from) newParams['from'] =  advancedSearchFilters.from;

    return newParams;
}

function applyTimeLineFilters(params:TopicParamsType,timeLineFilters:any ) {
    let newParams =  {
        ...params,
    };

    newParams['to'] =  timeLineFilters.to;
    newParams['from'] =  timeLineFilters.from;

    return newParams;
}





export function MapTopicParams (filters:any): TopicParamsType {
    let params:TopicParamsType = {...getFormParams(filters.form)};;
    params = applyAdvancedSearchFilters(params,filters.advancedSearch.filters);
    
    if(window.location.pathname.indexOf('timeline') !== -1){
        params = applyTimeLineFilters(params,filters.timeline);
        console.log('params timeline', params);
    }



    ////for efective cache add  always a roud time
    if(!params.to || params.to <= 0) params.to = roundCurrentTime(2);

    if(typeof params['contributorType'] === 'undefined'){
        params['contributorType'] = allowedContributorTypes.join(',');
    }

    return params;
}