import React, { FunctionComponent, MouseEvent, useState } from "react";
import {Button, ButtonProps, Popover, makeStyles, Typography} from "@material-ui/core";
import { useTranslate } from "../../service/translation";
import AdvancedSearchForm from "../layout/AdvancedSearchFrom";
import { FiltersContext, appDefaultFilters } from "../../service/filters";
import classNames from "classnames";

const useStyles = makeStyles(theme => ({
    filterButton: {
        "& span": {
            textTransform: "none"
        },
    },
    popover: {
        margin: theme.spacing(2, 0, 0),
        "& .MuiPopover-paper": {
            outline: 0,
            position: 'absolute',
            maxWidth: 'calc(100% - 55px)',
            width: '100%',
            left: '55px !important',
            top: '58px !important',
            maxHeight: 'calc(100% - 60px)',
        },
    },
    form: {
        backgroundColor: theme.palette.type === "light" ? theme.palette.background.default : theme.palette.background.default,
    },
    formContainer: {
        padding: theme.spacing(2),
        marginBottom: -theme.spacing(2),
        "& label": {
            transform: "none !important",
            fontSize: "12px",
            fontWeight: "bold",
            color: theme.palette.type === "light" ? "#555" : theme.palette.background.default,
            position: "static",
            marginBottom:theme.spacing(1),
        },
        "& legend": {
            display: "none",
        },
        "& fieldset": {
            top: 0,
            borderColor: theme.palette.type === "light" ? "#CCC" : theme.palette.background.default,
        },
        "& input[type='text']": {
            height: "40px",
            padding: theme.spacing(0,2),
            fontSize: "12px",
        },
    },
    divider: {
        margin: theme.spacing(2, -3),
    },
    legend: {
        alignItems: "center",
        display: "flex",
    },
    label: {

    },
    button: {
        marginLeft: theme.spacing(2),
    },
    clear: {
    },
    bottomBar: {
        backgroundColor: theme.palette.type === "light" ? theme.palette.background.paper : theme.palette.background.paper,
        padding: theme.spacing(2),
        marginTop: -theme.spacing(2),
    },
    active:{
        "& .MuiButton-label": {
            position: "relative",
            "&:before": {
                position: "absolute",
                top: 0,
                left: "100%",
                width: "5px",
                height: "5px",
                borderRadius: "50%",
                content: "''",
                backgroundColor: theme.palette.type === "light" ? theme.palette.primary.main : theme.palette.secondary.main,
            },
        },
    },
}));

const AdvancedSearchButton: FunctionComponent<ButtonProps> = (props: ButtonProps) => {
    const classes = useStyles();
    const translate = useTranslate();
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const { onClick, ...rest } = props;
    const [filters,setFilters] = React.useContext(FiltersContext);
    const [isOpen, setIsOpen] = useState(filters.advancedSearch.showPanel);
    const [buttonClasses, setButtonClasses] = React.useState([classes.filterButton])

   //classes.active];

    const handleClose = () => {
        setFilters({
            ...filters,
            advancedSearch:{
                ...filters.advancedSearch,
                showPanel :  false
            }
         });
    }

    const handleSubmit = (values: object): void => {};

    const handleOpen = (event: MouseEvent<HTMLButtonElement>) => {
        setFilters({
            ...filters,
            advancedSearch:{
                ...filters.advancedSearch,
                showPanel :  true
            }
         });
    };


    React.useEffect(() => {
        let active = false;
        active = active || filters.advancedSearch.filters.allWords.length > 0;
        active = active || JSON.stringify(filters.advancedSearch.filters.anyWords).length > 2;
        active = active || JSON.stringify(filters.advancedSearch.filters.hashtags).length > 2;
       
        active = active || JSON.stringify(filters.advancedSearch.filters.mentions).length > 2;
        active = active || JSON.stringify(filters.advancedSearch.filters.andWords).length > 2;
        active = active || JSON.stringify(filters.advancedSearch.filters.excludeWords).length > 2;
 
        active = active || filters.advancedSearch.filters.from !== appDefaultFilters.advancedSearch.filters.from ;
        active = active || filters.advancedSearch.filters.to !== appDefaultFilters.advancedSearch.filters.to ;
        

        if(active) setButtonClasses([classes.filterButton,classes.active]);
        else setButtonClasses([classes.filterButton]);

    },[filters.advancedSearch.filters]);


    React.useEffect(() => {
        setIsOpen(filters.advancedSearch.showPanel);
    }, [filters.advancedSearch.showPanel]);


    return (
        <>
            {filters.advancedSearch.showToggleButton &&
            <Button
                aria-label={translate("newshub.action.advancedsearch")}
                size="small"
                onClick={handleOpen}
                className={classNames(...buttonClasses)}
                {...rest}
            >
                <Typography color="inherit" variant="subtitle1" component="span">{translate("newshub.action.advancedsearch")}</Typography>
            </Button>
            }
            <Popover
                anchorEl={anchorEl}
                anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
                className={classes.popover}
                open={isOpen}
                transformOrigin={{ horizontal: "center", vertical: "top" }}
                onClose={handleClose}
            >
                <AdvancedSearchForm />
            </Popover>
        </>
    );
};

export default AdvancedSearchButton;
