import { NotificationOptions } from "../NotificationOptions";
import { NotificationPayload } from "../NotificationPayload";
import { NotificationType } from "../NotificationType";

export const SHOW_NOTIFICATION = "EP/SHOW_NOTIFICATION";

export interface ShowNotificationAction {
    readonly type: typeof SHOW_NOTIFICATION;
    readonly payload: NotificationPayload;
}

export const showNotification = (type: NotificationType, message: string, notificationOptions?: NotificationOptions): ShowNotificationAction => ({
    type: SHOW_NOTIFICATION,
    payload: { type, message, ...notificationOptions },
});
