import React, { FunctionComponent } from "react";
import { ClearFilterButton } from "../button";
import {
    Button,
    Divider,
    FormGroup,
    Grid, makeStyles,
} from "@material-ui/core";
import { Form } from "react-final-form";
import { useTranslate } from "../../service/translation";
import { TextField } from "@material-ui/core";
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import { FiltersContext,appDefaultFilters} from "../../service/filters";
import {getDayEnd} from "../../service/tools/Tools";



const useStyles = makeStyles(theme => ({
    form: {
        backgroundColor: theme.palette.type === "light" ? theme.palette.background.default : theme.palette.background.default,
        [theme.breakpoints.down("sm")]: {
            backgroundColor: theme.palette.type === "light" ? 'white' : theme.palette.background.paper,
        },
    },
    formContainer: {
        padding: theme.spacing(2),
        marginBottom: -theme.spacing(2),
        "& label": {
            transform: "none !important",
            fontSize: "12px",
            fontWeight: "bold",
            color: theme.palette.type === "light" ? theme.palette.text.hint : theme.palette.text.hint,
            position: "static",
            marginBottom:theme.spacing(1),
        },
        "& legend": {
            display: "none",
        },
        "& fieldset": {
            top: 0,
            borderColor: theme.palette.type === "light" ? "#CCC" : theme.palette.text.hint,
        },
        "& input[type='text']": {
            height: "40px",
            padding: theme.spacing(0,2),
            fontSize: "12px",
        },
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(0, 1),
            marginBottom: theme.spacing(0),
        },
    },
    divider: {
        margin: theme.spacing(2, 0),
        [theme.breakpoints.down("sm")]: {
            display: 'none',
        },
    },
    clear: {
    },
    bottomBar: {
        backgroundColor: theme.palette.type === "light" ? theme.palette.background.paper : theme.palette.background.paper,
        padding: theme.spacing(2),
        marginTop: -theme.spacing(2),
        [theme.breakpoints.down("sm")]: {
            display: 'none',
        },
    },
}));

interface AdvancedSearchFormProps {
    classes: {
        form: string;
        formContainer: string;
        divider: string;
        legend: string;
        label: string;
        button: string;
        clear: string;
        bottomBar: string;
    };
    onSubmit: (values: object) => void;
}

const defaultSubscription = {
    submitting: true,
    pristine: true,
    valid: true,
    invalid: true,
};

export default function AdvancedSearchForm() {
    const currentFilters = {};
    const classes = useStyles();
    const translate = useTranslate();
    /*const { classes, onSubmit } = props;*/
    const [filters,setFilters] = React.useContext(FiltersContext);

    const handleSetFilter = () => {};
    const [advancedSearch,setAdvancedSearch] = React.useState({...filters.advancedSearch});

    /*
    const handleViewAllChannels = () => {};
    const handleViewAllInstitutions = () => {};
    */
    const [selectedDateFrom, setSelectedDateFrom] = React.useState(new Date(filters.advancedSearch.filters.from * 1000));
    const [selectedDateTo, setSelectedDateTo] = React.useState(new Date(filters.advancedSearch.filters.to * 1000));

    const handleClearFilter = () => {
        setAdvancedSearch({
            ...appDefaultFilters.advancedSearch
        });

        setSelectedDateTo(new Date(appDefaultFilters.advancedSearch.filters.to * 1000));
        setSelectedDateFrom(new Date(appDefaultFilters.advancedSearch.filters.from * 1000));

        setFilters({
            ...filters,
            crossActions:{
                ...filters.crossActions,
                clearAdvancedSearch : false,
                closeTopDrawer: false,
            }
        });
    };

    const handleChangeAll =(text:string) => {
        setAdvancedSearch({
            ...advancedSearch,
            filters:{
                ...advancedSearch.filters,
                allWords : text
            }
        });
    }

    const handleChangeAny =(text:string) => {
        setAdvancedSearch({
            ...advancedSearch,
            filters:{
                ...advancedSearch.filters,
                anyWords :[text]
            }
        });
    }

    const parseTagField = (words:string[]) => {
        return words.join(' ').trim()
           .replace(/[, ]/g,'|')
           .replace(/\|{2,}/g,'|');
    };

    const handleSearch = () => {
        console.log('Handle Search in advancedSearchForm');
        let anyWords = parseTagField(advancedSearch.filters.anyWords);
        let hashtags =  parseTagField(advancedSearch.filters.hashtags);
        let mentions = parseTagField(advancedSearch.filters.mentions);
        let excludeWords = parseTagField(advancedSearch.filters.excludeWords);
        let from = advancedSearch.filters.from;
        let to = advancedSearch.filters.to;

        if(from > to){
            let tmp = to;
            to = from;
            from = tmp;
        }

        let newAdvancedFilters = {
            ...advancedSearch,
            showPanel: false,
            filters:{
                ...advancedSearch.filters,
                anyWords :anyWords.split('|'),
                hashtags : [...hashtags.split('|')],
                mentions:mentions.split('|'),
                excludeWords: excludeWords.split('|'),
                from: from,
                to:to
            }
        };

        setAdvancedSearch({
            ...newAdvancedFilters
        });

        setFilters({
            ...filters,
            searchBar:'',
            advancedSearch :{
               ...newAdvancedFilters,
            },
            crossActions:{
                ...filters.crossActions,
                applyAdvancedSearch :false,
                closeTopDrawer:false
            }
        });
    };

    const handleChangeHashTag =(text:string) => {
        setAdvancedSearch({
            ...advancedSearch,
            filters:{
                ...advancedSearch.filters,
                hashtags :[text]
            }

        });
    };

    const handleChangeExclude =(text:string) => {
        setAdvancedSearch({
            ...advancedSearch,
            filters:{
                ...advancedSearch.filters,
                excludeWords :[text]
            }
        });
    };

    const handleChangeAnd = (text:string) => {
        setAdvancedSearch({
            ...advancedSearch,
            filters:{
                ...advancedSearch.filters,
                andWords :[text]
            }
        });
    };

    const handleChangeMentions= (text:string) => {
        setAdvancedSearch({
            ...advancedSearch,
            filters:{
                ...advancedSearch.filters,
                mentions :[text]
            }
        });
    };

    // @ts-ignore
    const handleDateChangeFrom = (date) => {
        setSelectedDateFrom(date);
        setAdvancedSearch({
            ...advancedSearch,
            filters :{
                ...advancedSearch.filters,
                from: Math.floor(date.getTime()/1000),
            }
        });
    };
    // @ts-ignore
    const handleDateChangeTo = (date) => {
        setSelectedDateTo(date);
        setAdvancedSearch({
            ...advancedSearch,
            filters :{
                ...advancedSearch.filters,
                to: Math.ceil(date.getTime()/1000),
            }

        });
    };


    React.useEffect(()=>{
        console.log('Filters Form crossAction',filters.crossActions);
        if(filters.crossActions.applyAdvancedSearch === true){
            handleSearch();
        }
        if(filters.crossActions.clearAdvancedSearch === true){
            handleClearFilter();
        }

    },[filters.crossActions]);

    return (
        <Form
            initialValues={currentFilters}
            subscription={defaultSubscription}
            onSubmit={handleSearch}
            render={
                ({ handleSubmit }) => (
                    <form
                        className={classes.form}
                        noValidate
                        onSubmit={handleSubmit}
                    >
                        <Grid container className={classes.formContainer}>
                            <Grid item xs={12} sm={6} md={3} className="gridItem">
                                <FormGroup>

                                    <TextField
                                        id="outlined-full-width"
                                        label={translate("newshub.action.mainSearch")}
                                        placeholder={translate("newshub.action.mainSearchPlaceholder")}
                                        /*helperText="Full width!"*/
                                        fullWidth
                                        margin="normal"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="outlined"
                                        value = {advancedSearch.filters.allWords}
                                        onChange = {(event:any) => { handleChangeAll(event.currentTarget.value)} }
                                    />

                                </FormGroup>
                                <FormGroup>
                                    <TextField
                                        id="outlined-full-width"
                                        label={translate("newshub.action.excludeWords")}
                                        placeholder={translate("newshub.action.excludeWordsPlaceholder")}
                                        /*helperText="Full width!"*/
                                        fullWidth
                                        margin="normal"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="outlined"
                                        onChange = {(event:any) => { handleChangeExclude(event.currentTarget.value)} }
                                        value={advancedSearch.filters.excludeWords.join(', ')}
                                    />

                                </FormGroup>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} className="gridItem">
                                <FormGroup>
                                    <TextField
                                        id="outlined-full-width"
                                        label={translate("newshub.action.includeWords")}
                                        placeholder={translate("newshub.action.includeWordsPlaceholder")}
                                        /*helperText="Full width!"*/
                                        fullWidth
                                        margin="normal"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="outlined"
                                        value={advancedSearch.filters.anyWords.join(', ')}
                                        onChange = {(event:any) => { handleChangeAny(event.currentTarget.value)} }
                                    />


                                </FormGroup>
                                <FormGroup>
                                    <TextField
                                        id="outlined-full-width"
                                        label={translate("newshub.action.allWords")}
                                        placeholder={translate("newshub.action.allWordsPlaceholder")}
                                        /*helperText="Full width!"*/
                                        fullWidth
                                        margin="normal"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="outlined"
                                        onChange = {(event:any) => { handleChangeAnd(event.currentTarget.value)} }
                                        value={advancedSearch.filters.andWords.join(', ')}
                                    />

                                </FormGroup>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} className="gridItem">
                                <FormGroup>
                                    <TextField
                                        id="outlined-full-width"
                                        label={translate("newshub.action.withHashtags")}
                                        placeholder={translate("newshub.action.withHashtagsPlaceholder")}
                                        /*helperText="Full width!"*/
                                        fullWidth
                                        margin="normal"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="outlined"
                                        onChange = {(event:any) => { handleChangeHashTag(event.currentTarget.value)} }
                                        value={advancedSearch.filters.hashtags.join(', ')}
                                    />

                                </FormGroup>
                                <FormGroup>
                                    <TextField
                                        id="outlined-full-width"
                                        label={translate("newshub.action.mentions")}
                                        placeholder={translate("newshub.action.mentionsPlaceholder")}
                                        /*helperText="Full width!"*/
                                        fullWidth
                                        margin="normal"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="outlined"
                                        onChange = {(event:any) => { handleChangeMentions(event.currentTarget.value)} }
                                        value={advancedSearch.filters.mentions.join(', ')}
                                    />

                                </FormGroup>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <FormGroup>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <Grid container>
                                            <Grid item xs={12} sm={6} md={6} className="gridItem">
                                                <KeyboardDatePicker
                                                    margin="normal"
                                                    id="date-picker-dialog1"
                                                    label="From date"
                                                    format="dd/MM/yyyy"
                                                    value={selectedDateFrom}
                                                    onChange={handleDateChangeFrom}
                                                    className="custom_datepicker"
                                                    KeyboardButtonProps={{
                                                        'aria-label': 'change date',
                                                    }}
                                                    disableFuture={true}
                                                    minDate = {appDefaultFilters.advancedSearch.filters.from * 1000}
                                                    maxDate = {appDefaultFilters.advancedSearch.filters.to * 1000}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6} className="gridItem">
                                                <KeyboardDatePicker
                                                    margin="normal"
                                                    id="date-picker-dialog2"
                                                    label="To date"
                                                    format="dd/MM/yyyy"
                                                    value={selectedDateTo}
                                                    onChange={handleDateChangeTo}
                                                    className="custom_datepicker"
                                                    KeyboardButtonProps={{
                                                        'aria-label': 'change date',
                                                    }}
                                                    disableFuture={true}
                                                    minDate = {appDefaultFilters.advancedSearch.filters.from * 1000}
                                                    maxDate = {getDayEnd() * 1000}
                                                />
                                            </Grid>
                                        </Grid>
                                    </MuiPickersUtilsProvider>
                                </FormGroup>
                            </Grid>

                        </Grid>
                        <Divider className={classes.divider} />
                        <Grid container alignItems="center" className={classes.bottomBar}>
                            <Grid container item xs={6} sm={6} md={6}>
                                <ClearFilterButton
                                    className={classes.clear}
                                    onClick={handleClearFilter}
                                />
                            </Grid>
                            <Grid container item xs={6} sm={6} md={6} justifyContent="flex-end" >
                                <Button variant="contained"
                                    onClick={handleSearch}
                                    type={"submit"}
                                    color="primary">
                                    {translate("newshub.action.search")}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                )
            }
        />
    )
};

AdvancedSearchForm.defaultProps = {
    classes: {
        form: "",
        formContainer: "",
        divider: "",
        legend: "",
        label: "",
        button: "",
        clear: "",
        bottomBar: "",
    },
};
