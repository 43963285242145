import React, { FunctionComponent } from "react";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {Button, ButtonProps, makeStyles, Typography} from "@material-ui/core";
import { useGoBack } from "../../service/redirection";
import { useTranslate } from "../../service/translation";

const useStyles = makeStyles(theme => ({
    backButton: {
        [theme.breakpoints.down("sm")]: {
            minWidth: '0 !important',
            marginBottom: theme.spacing(2),
        },
        [theme.breakpoints.down("sm")]: {
            minWidth: '0 !important',
        },
    },
}));


const HistoryBackButton: FunctionComponent<ButtonProps> = (props: ButtonProps) => {
    const goBack = useGoBack();
    const translate = useTranslate();
    const classes = useStyles();

    return (
        <Button
            startIcon={<ArrowBackIcon />}
            onClick={goBack}
            {...props}
            className={classes.backButton}
        >
            <Typography variant="body1">{translate("newshub.action.goBack")}</Typography>
        </Button>
    )
};

export default HistoryBackButton;
