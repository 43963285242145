import { I18nProvider } from "./I18nProvider";
import { createContext } from "react";
import { identity } from "./translationUtil";

export interface TranslationContextProps {
    locale: string;
    setLocale: (locale: string) => void;
    i18nProvider: I18nProvider;
}

const TranslationContext = createContext<TranslationContextProps>({
    locale: "en",
    setLocale: () => {},
    i18nProvider: {
        translate: identity,
        changeLocale: () => Promise.resolve(),
        getLocale: () => "en",
    },
});

TranslationContext.displayName = "TranslationContext";

export {
    TranslationContext,
}
