import React from "react";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import {IconButton, IconButtonProps, makeStyles, Tooltip} from "@material-ui/core";
import { useTranslate } from "../../service/translation";

const useStyles = makeStyles(theme => ({
    infoButton: {
        "& svg": {
            width: '20px',
            height: '20px',
            color: theme.palette.type === "light" ? theme.palette.primary.main : theme.palette.secondary.dark,
        },
    },
}));

const InfoTimelineFilterIconButton = (props: IconButtonProps) => {
    const classes = useStyles();
    const translate = useTranslate();

    return (
        <Tooltip title={translate("timeline.tooltip")}>
            <IconButton
                className={classes.infoButton}
                aria-label={translate("timeline.tooltip")}
                color="primary"
                size="small"
                {...props}
            >
                <InfoOutlinedIcon />
            </IconButton>
        </Tooltip>
    )
};

export default InfoTimelineFilterIconButton;
