import React, { FunctionComponent } from "react";
import { useContentStyles } from "./useContentStyles";
import { useTranslate } from "../../service/translation";
import {Divider, Grid, Link, List, ListItem, ListItemIcon, ListItemText, Typography} from '@material-ui/core';
import Bullet from '@material-ui/icons/FiberManualRecord';

interface PrivacyContentProps {
}

const PrivacyContent: FunctionComponent<PrivacyContentProps> = (props: PrivacyContentProps) => {
    const classes = useContentStyles();
    const translate = useTranslate();

    return (
        <Grid container className={classes.page} id="main-content">
            <Grid container className={classes.pageWrapper}>
                <Grid item className={classes.staticPageContainer}>
                    <Typography gutterBottom variant={"h1"} component={"h1"}>
                        {translate("privacy.message.title")}
                    </Typography>
                    <Typography gutterBottom variant={"h2"} component={"h2"}>
                        {translate("privacy.message.subtitle1")}
                    </Typography>
                    <Typography variant={"body1"} component={"p"}>
                        {translate("privacy.message.description1A")}
                    </Typography>
                    <Typography variant={"body1"} component={"p"}>
                        {translate("privacy.message.description1B")}
                    </Typography>
                    <Typography gutterBottom variant={"h2"} component={"h2"}>
                        {translate("privacy.message.subtitle2")}
                    </Typography>
                    <Typography variant={"body1"} component={"p"}>
                        {translate("privacy.message.description2A")}
                    </Typography>
                    <Typography variant={"body1"} component={"p"}>
                        {translate("privacy.message.description2B")}
                    </Typography>
                    <List>
                        <ListItem><ListItemIcon><Bullet /></ListItemIcon><ListItemText primary={translate("privacy.message.description2ListA")}/></ListItem>
                        <ListItem><ListItemIcon><Bullet /></ListItemIcon><ListItemText primary={translate("privacy.message.description2ListB")}/></ListItem>
                        <ListItem><ListItemIcon><Bullet /></ListItemIcon><ListItemText primary={translate("privacy.message.description2ListC")}/></ListItem>
                        <ListItem><ListItemIcon><Bullet /></ListItemIcon><ListItemText primary={translate("privacy.message.description2ListD")}/></ListItem>
                        <ListItem><ListItemIcon><Bullet /></ListItemIcon><ListItemText primary={translate("privacy.message.description2ListE")}/></ListItem>
                        <ListItem><ListItemIcon><Bullet /></ListItemIcon><ListItemText primary={translate("privacy.message.description2ListF")}/></ListItem>
                    </List>
                    <Typography gutterBottom variant={"h2"} component={"h2"}>
                        {translate("privacy.message.subtitle3")}
                    </Typography>
                    <Typography variant={"body1"} component={"p"}>
                        {translate("privacy.message.description3A")}
                    </Typography>
                    <Typography variant={"body1"} component={"p"}>
                        {translate("privacy.message.description3B")}
                    </Typography>
                    <Typography gutterBottom variant={"h2"} component={"h2"}>
                        {translate("privacy.message.subtitle4")}
                    </Typography>
                    <Typography variant={"body1"} component={"p"}>
                        {translate("privacy.message.description4A")}
                    </Typography>
                    <Typography variant={"body1"} component={"p"}>
                        {translate("privacy.message.description4B")}
                        <Link href={translate("privacy.message.description4mailto")} >{translate("privacy.message.description4mail")}</Link>
                    </Typography>
                    <Typography gutterBottom variant={"h2"} component={"h2"}>
                        {translate("privacy.message.subtitle5")}
                    </Typography>
                    <Typography variant={"body1"} component={"p"}>
                        {translate("privacy.message.description5A")}
                    </Typography>
                    <Typography variant={"h3"} component={"h3"}>
                        {translate("privacy.message.description5subtitle1")}
                    </Typography>
                    <Typography variant={"body1"} component={"p"}>
                        {translate("privacy.message.description5B")}
                    </Typography>
                    <List>
                        <ListItem><ListItemIcon><Bullet /></ListItemIcon><ListItemText primary={translate("privacy.message.description5C1")}/></ListItem>
                        <ListItem><ListItemIcon><Bullet /></ListItemIcon><ListItemText primary={translate("privacy.message.description5C2")}/></ListItem>
                        <ListItem><ListItemIcon><Bullet /></ListItemIcon><ListItemText primary={translate("privacy.message.description5C3")}/></ListItem>
                        <ListItem><ListItemIcon><Bullet /></ListItemIcon><ListItemText primary={translate("privacy.message.description5C4")}/></ListItem>
                    </List>
                    <Typography variant={"h3"} component={"h3"}>
                        {translate("privacy.message.description5subtitle2")}
                    </Typography>
                    <Typography variant={"body1"} component={"p"}>
                        {translate("privacy.message.description5D")}
                    </Typography>
                    <Typography variant={"body1"} component={"p"}>
                        <em>{translate("privacy.message.description5listSeparator1")}</em>
                    </Typography>
                    <List>
                        <ListItem><ListItemIcon><Bullet /></ListItemIcon><ListItemText primary={translate("privacy.message.description5ListA")}/></ListItem>
                        <ListItem><ListItemIcon><Bullet /></ListItemIcon><ListItemText primary={translate("privacy.message.description5ListB")}/></ListItem>
                        <ListItem><ListItemIcon><Bullet /></ListItemIcon><ListItemText primary={translate("privacy.message.description5ListC")}/></ListItem>
                        <ListItem><ListItemIcon><Bullet /></ListItemIcon><ListItemText primary={translate("privacy.message.description5ListD")}/></ListItem>
                        <ListItem><ListItemIcon><Bullet /></ListItemIcon><ListItemText primary={translate("privacy.message.description5ListE")}/></ListItem>
                    </List>
                    <Typography variant={"body1"} component={"p"}>
                        <em>{translate("privacy.message.description5listSeparator2")}</em>
                    </Typography>
                    <List>
                        <ListItem><ListItemIcon><Bullet /></ListItemIcon><ListItemText primary={translate("privacy.message.description5ListF")}/></ListItem>
                        <ListItem><ListItemIcon><Bullet /></ListItemIcon><ListItemText primary={translate("privacy.message.description5ListG")}/></ListItem>
                    </List>
                    <Typography variant={"h3"} component={"h3"}>
                        {translate("privacy.message.description5listSeparator3")}
                    </Typography>
                    <List>
                        <ListItem><ListItemIcon><Bullet /></ListItemIcon><ListItemText primary={translate("privacy.message.description5ListH")}/></ListItem>
                    </List>
                    <Typography gutterBottom variant={"h2"} component={"h2"}>
                        {translate("privacy.message.subtitle6")}
                    </Typography>
                    <Typography variant={"body1"} component={"p"}>
                        {translate("privacy.message.description6A")}
                    </Typography>
                    <Typography variant={"body1"} component={"p"}>
                        {translate("privacy.message.description6B")}
                    </Typography>
                    <Typography variant={"body1"} component={"p"}>
                        {translate("privacy.message.description6C")}
                    </Typography>
                    <Typography variant={"body1"} component={"p"}>
                        {translate("privacy.message.description6D")}
                    </Typography>
                    <Typography variant={"body1"} component={"p"}>
                        {translate("privacy.message.description6E")}
                    </Typography>
                    <Typography variant={"body1"} component={"p"}>
                        {translate("privacy.message.description6F")}
                    </Typography>
                    <Typography variant={"body1"} component={"p"}>
                        {translate("privacy.message.description6G")}
                    </Typography>
                    <Typography gutterBottom variant={"h2"} component={"h2"}>
                        {translate("privacy.message.subtitle7")}
                    </Typography>
                    <Typography variant={"body1"} component={"p"}>
                        {translate("privacy.message.description7A")}
                    </Typography>
                    <Typography variant={"h3"} component={"h3"}>
                        {translate("privacy.message.description7subtitle1")}
                    </Typography>
                    <Typography variant={"body1"} component={"p"}>
                        {translate("privacy.message.description7B")}
                    </Typography>
                    <Typography variant={"h3"} component={"h3"}>
                        {translate("privacy.message.description7subtitle2")}
                    </Typography>
                    <Typography variant={"body1"} component={"p"}>
                        {translate("privacy.message.description7D")}
                    </Typography>
                    <Typography variant={"h3"} component={"h3"}>
                        {translate("privacy.message.description7subtitle3")}
                    </Typography>
                    <Typography variant={"body1"} component={"p"}>
                        {translate("privacy.message.description7E")}
                    </Typography>
                    <Typography variant={"h3"} component={"h3"}>
                        {translate("privacy.message.description7subtitle4")}
                    </Typography>
                    <Typography variant={"body1"} component={"p"}>
                        {translate("privacy.message.description7F")}
                    </Typography>
                    <Typography gutterBottom variant={"h2"} component={"h2"}>
                        {translate("privacy.message.subtitle8")}
                    </Typography>
                    <Typography variant={"body1"} component={"p"}>
                        {translate("privacy.message.description8A")}
                    </Typography>
                    <List>
                        <ListItem><ListItemIcon><Bullet /></ListItemIcon><ListItemText primary={translate("privacy.message.description8ListA")}/></ListItem>
                        <ListItem><ListItemIcon><Bullet /></ListItemIcon><ListItemText primary={translate("privacy.message.description8ListB")}/></ListItem>
                        <ListItem><ListItemIcon><Bullet /></ListItemIcon><ListItemText primary={translate("privacy.message.description8ListC")}/></ListItem>
                        <ListItem><ListItemIcon><Bullet /></ListItemIcon><ListItemText primary={translate("privacy.message.description8ListD")}/></ListItem>
                        <ListItem><ListItemIcon><Bullet /></ListItemIcon><ListItemText primary={translate("privacy.message.description8ListE")}/></ListItem>
                        <ListItem><ListItemIcon><Bullet /></ListItemIcon><ListItemText primary={translate("privacy.message.description8ListF")}/></ListItem>
                        <ListItem><ListItemIcon><Bullet /></ListItemIcon><ListItemText primary={translate("privacy.message.description8ListG")}/></ListItem>
                    </List>
                    <Typography variant={"body1"} component={"p"}>
                        {translate("privacy.message.description8B")}
                        <Link href={translate("privacy.message.description8mailto")} >{translate("privacy.message.description8mail")}</Link>.
                    </Typography>
                    <Typography gutterBottom variant={"h2"} component={"h2"}>
                        {translate("privacy.message.subtitle9")}
                    </Typography>
                    <Typography variant={"body1"} component={"p"}>
                        {translate("privacy.message.description9A")}
                        <Link href={translate("privacy.message.description9mailtoA")} >{translate("privacy.message.description9mailA")}</Link>.
                    </Typography>
                    <Typography variant={"body1"} component={"p"}>
                        {translate("privacy.message.description9B1")}
                        (<Link href={translate("privacy.message.description9mailtoB")} >{translate("privacy.message.description9mailB")}</Link>)
                        {translate("privacy.message.description9B2")}
                    </Typography>
                    <Typography variant={"body1"} component={"p"}>
                        {translate("privacy.message.description9C")}
                    </Typography>
                    <Typography variant={"body1"} component={"p"}>
                        {translate("privacy.message.description9D")}<br/>
                        {translate("privacy.message.description9E")}<br/>
                        {translate("privacy.message.description9F")}<br/>
                        {translate("privacy.message.description9G")}
                    </Typography>
                    <Typography variant={"body1"} component={"p"}>
                        {translate("privacy.message.description9H1")}
                        (<Link href={translate("privacy.message.description9mailtoC")} >{translate("privacy.message.description9mailC")}</Link>)
                        {translate("privacy.message.description9H2")}
                    </Typography>
                    <Typography variant={"body1"} component={"p"}>
                        {translate("privacy.message.description9I")}
                    </Typography>
                    <Divider/>
                    <Typography variant={"body1"} component={"p"}>
                        <em>{translate("privacy.message.references1")}</em><br/>
                        <em>{translate("privacy.message.references2")}</em>
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default PrivacyContent;
