import { makeStyles } from "@material-ui/core";

const useContentStyles = makeStyles(theme => ({
    error404:{
        fontSize: "128px",
        lineHeight: "120px",
        fontWeight: "bold",
        color: theme.palette.type === "light" ? "#CCC" : theme.palette.text.hint,
        marginBottom: theme.spacing(3),
    },
    notFoundContentContainer:{
        height: "calc(100vh - 92px)",
        textAlign: "center",
        [theme.breakpoints.down("xs")]: {
            height: "auto",
            margin: "20px 0 92px 0",
        },
    },
    page: {
        display: "flex",
        paddingLeft: 55,
        paddingTop: 74,
        height: "100%",
        backgroundColor: theme.palette.type === "light" ? theme.palette.background.default : theme.palette.background.default,
        [theme.breakpoints.down("sm")]: {
            paddingTop: 52,
            paddingLeft: 0,
        },
        [theme.breakpoints.down("xs")]: {
            paddingLeft: 0,
            paddingTop: 52,
        },
    },
    pageWrapper: {
        display: "flex",
        height: "100%",
        maxWidth: 1200,
        width: "100%",
        margin: "0 auto",
        paddingTop: theme.spacing(2),
        alignContent: "flex-start",
        [theme.breakpoints.down("sm")]: {
            paddingBottom: "40px",
            paddingTop: 0,
        },
        [theme.breakpoints.down("xs")]: {
            maxWidth: "500px",
            paddingBottom: "40px",
            paddingTop: 0,
        },
    },
    topBar: {
        boxShadow: "none !important",
    },
    aside: {
        /*paddingTop: 10,*/
        maxHeight: "100%",
        [theme.breakpoints.down("sm")]: {
            marginBottom: theme.spacing(2),
            maxWidth: '480px',
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    topicTitle: {
        textAlign: "center",
    },
    message: {
        textAlign: "center",
        marginTop: "-100px",
        [theme.breakpoints.down("sm")]: {
            marginTop: "-50px",
        },
    },
    toolbar: {
        marginTop: "2em",
        textAlign: "center",
    },
    trace: {
    },
    details: {
        whiteSpace: "pre-wrap",
    },
    masonryLayout: {
        padding: 0,
        margin: 0,
    },
    topFilterContainer:{
        backgroundColor: theme.palette.type === "light" ? theme.palette.background.paper : theme.palette.background.paper,
        whiteSpace: "nowrap",
        flexWrap: "nowrap",
        maxWidth: "100%",
        overflowX: "auto",
        /*[theme.breakpoints.down("sm")]: {
            marginTop: "-22px",
        },
        [theme.breakpoints.down("xs")]: {
            marginTop: 0,
        },*/
    },
    topFilterWrapper:{
        display: "flex",
        height: "100%",
        maxWidth: 1200,
        width: "100%",
        margin: "0 auto",
        position: "relative",
    },
    tableContainer:{
        maxWidth: "100vw",
        margin:theme.spacing(2,0,2),
    },
    tableDuration:{
        minWidth: "100px",
    },
    staticPageContainer:{
        padding: theme.spacing(2,1,7),
        "& h2":{
            margin:theme.spacing(3,0,2),
        },
        "& h3":{
            margin:theme.spacing(2,0,1),
        },
        "& p":{
            margin:theme.spacing(1,0,1),
        },
        "& a":{
            color:  theme.palette.type === "light" ? theme.palette.primary.main : theme.palette.primary.main,
        },
        "& hr":{
            margin:theme.spacing(3,0,3),
            backgroundColor:  theme.palette.type === "light" ? theme.palette.primary.main : theme.palette.primary.main,
        },
        "& table":{
            minWidth: "600px",
            "& thead":{
                "& *":{
                    fontWeight: "bold",
                    borderColor:  theme.palette.type === "light" ? theme.palette.text.hint : theme.palette.text.hint,
                },
            },
            "& tbody":{
                "& tr":{
                    "& td":{
                        border: 0,
                    },
                },
            },
        },
        "& ul":{
            padding: 0,
            margin:theme.spacing(1,0,2),
            "& li":{
                padding: 0,
                "& .MuiListItemIcon-root":{
                    minWidth: "25px",
                    color:  theme.palette.type === "light" ? theme.palette.primary.main : theme.palette.primary.main,
                    "& svg":{
                        width: "8px",
                    },
                },
            },
        },
    },
    counterContainer:{
        [theme.breakpoints.down("xs")]: {
            margin: "0px auto",
            maxWidth: "480px",
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
        "@media (width: 768px)": {
            margin: "0px auto",
            maxWidth: "480px",
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
    },
    cookiesWidget:{
        /*backgroundColor: "#f1f2f2",*/
        backgroundColor: theme.palette.type === "light" ? "#f1f2f2 !important" : theme.palette.background.paper + " !important",
        border: "1px solid #d1d3d4",
        padding: "20px",
        marginTop: "30px",
    },
    /* GLOBAL OVERRIDES */
    '@global': {
        "#cookie-policy.epjs_cookiepolicy": {
            top: "0 !important",
            left: "0 !important",
            right: "0px !important",
            bottom: "0px !important",
            height: "100%",
            textAlign: "left !important",
            fontFamily: "Open Sans" + " !important",
            backgroundColor: "rgba(0,0,0,0.5)",
            transition: "transform 1s ease-out 0s",
            "& .cookie-consent-popup-container": {
                position: "absolute !important",
                left: "initial !important",
                right: theme.spacing(2) +"px !important",
                bottom: theme.spacing(2) +"px !important",
                maxWidth: "500px",
                padding: "16px 2px 6px !important",
                boxShadow: "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
                borderRadius: "10px",
                backgroundColor: theme.palette.type === "light" ? theme.palette.background.paper + " !important" : theme.palette.background.paper + " !important",
                [theme.breakpoints.down("sm")]: {
                    left: "16px !important",
                    right: "16px !important",
                    maxWidth: "calc(100% - 32px)",
                    bottom: theme.spacing(2) +"px !important",
                },
                "& .epjs_text": {
                    "&:focus-visible": {
                        outline: "none",
                    },
                },
                "& p, & a": {
                    fontSize: "12px !important",
                    color: theme.palette.type === "light" ? theme.palette.text.primary + " !important" : theme.palette.text.primary + " !important",
                },
                "& a": {
                    "&:hover": {
                        color: theme.palette.type === "light" ? theme.palette.text.primary + " !important" : theme.palette.text.primary + " !important",
                        textDecoration: "underline",
                    }
                },
                "& .epjs_buttons button":{
                    padding: "6px 16px",
                    fontSize: "16px",
                    boxSizing: "border-box",
                    transition: "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                    fontFamily: "Open Sans",
                    fontWeight: 400,
                    lineHeight: 1.75,
                    borderRadius: "50px !important",
                    color: theme.palette.type === "light" ? theme.palette.background.paper + " !important" : theme.palette.background.paper + " !important",
                    backgroundColor:  theme.palette.type === "light" ? theme.palette.primary.main + " !important" : theme.palette.primary.main + " !important",
                    height: 40,
                    border: "0px !important",
                    "& span":{
                        fontSize: "16px !important",
                    },
                },
            },
        },
        "html body[data-jsactive] #cookie-policy.epjs_cookiepolicy .cookie-consent-popup-container .epjs_text a.cc-link-default:hover": {
            color: theme.palette.type === "light" ? theme.palette.text.primary + " !important" : theme.palette.text.primary + " !important",
        },
        ".MuiButton-containedSecondary:hover": {
            backgroundColor: theme.palette.type === "light" ? "#e5ba0a" : "#e5ba0a",
        },
        ".MuiButton-textPrimary:hover": {
            backgroundColor: theme.palette.type === "light" ? "transparent" : "transparent",
            "& *": {
                color: theme.palette.type === "light" ? theme.palette.primary.dark + " !important" : theme.palette.primary.dark + " !important",
            },
        },
        ".MuiAvatar-colorDefault": {
            color: theme.palette.type === "light" ? theme.palette.background.paper : theme.palette.background.default,
            backgroundColor: theme.palette.type === "light" ? "#999" : theme.palette.secondary.dark,
        },
        ".MuiChip-root": {
            fontSize: "16px",
        },
        ".MuiAvatar-root": {
            fontSize: "20px",
        },
        ".MuiTouchRipple-root": {
            display: "none",
        },

        ".MuiIconButton-label": {
            [theme.breakpoints.up("md")]: {
                "& input": {
                    "&:focus-visible": {
                        "& +svg": {
                            borderRadius: "0px !important",
                            outline: "3px dotted #d9d9d9 !important",
                            outlineOffset: "-3px !important",
                            backgroundColor: "rgba(0,0,0,0.2)",
                        },
                    },
                    "&:-moz-focusring": {
                        "& +svg": {
                            borderRadius: "0px !important",
                            outline: "3px dotted #d9d9d9 !important",
                            outlineOffset: "-3px !important",
                            backgroundColor: "rgba(0,0,0,0.2)",
                        },
                    },
                },
            },
        },

        "*": {
            [theme.breakpoints.up("md")]: {
                "&:focus-visible": {
                    borderRadius: "0px !important",
                    outline: "3px dotted #919191 !important",
                    outlineOffset: "-3px",
                },
                "&:-moz-focusring": {
                    borderRadius: "0px !important",
                    outline: "3px dotted #919191 !important",
                    outlineOffset: "-3px",
                },
            },
        },
        ".MuiDrawer-root *": {
                [theme.breakpoints.up("md")]: {
                "&:focus-visible": {
                    borderRadius: "0px !important",
                    outline: "3px dotted #d9d9d9 !important",
                    outlineOffset: "-3px",
                },
                "&:-moz-focusring": {
                    borderRadius: "0px !important",
                    outline: "3px dotted #d9d9d9 !important",
                    outlineOffset: "-3px",
                },
            },
        },/*
        ".MuiDrawer-paperAnchorLeft":{
            overflowX: "hidden !important",
        },*/
        /* DYNAMIC TUTORIAL STYLES*/
        ".react-joyride__tooltip":{
            padding: "16px !important",
            /*left: "-8px !important",*/
            maxWidth: "350px !important",
            borderRadius: "16px !important",
            backgroundColor: "rgba(0,0,0,0.3) !important",
            //TXT CONTAINER
            "& p":{
                color: "white !important",
                whiteSpace: "pre-wrap",
            },
            "&> div":{
                textAlign: "left !important",
                "&> div":{
                    padding: "0 !important",
                },
            },
            //FOOTER
            "&> div + div":{
                //NEXT BUTTON
                "& > button":{
                    height: "40px !important",
                    borderRadius: "30px !important",
                    padding: theme.spacing(1, 2) + " !important",
                    "& > span":{
                        color: theme.palette.type === "light" ? theme.palette.text.primary + " !important" : theme.palette.background.default + " !important",
                    },
                },
            },
            //CLOSE BUTTON
            "&> button":{
                top: "8px !important",
                right: "8px !important",
            },
        },

        ".relative":{
            position: "relative",
        },
        ".homeCardList":{
            position: "relative",
        },
        ".joyrideHome00":{
            position: "absolute",
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
        },
        ".joyrideHome03":{
            position: "absolute",
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
            pointerEvents: "none",
        },
        ".joyrideHome04":{
            position: "absolute",
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
            pointerEvents: "none",
        },
        ".joyrideHomeXs00":{
            position: "absolute",
            width: "0",
            height: "0",
            top: "25%",
            left: "50%",
        },
        ".joyrideHomeXs03":{
            position: "absolute",
            width: "45px",
            height: "100%",
            top: "0",
            left: "0",
            pointerEvents: "none",
        },
        ".joyrideHomeXs04":{
            position: "absolute",
            width: "45px",
            height: "100%",
            top: "0",
            left: "0",
            pointerEvents: "none",
        },
        ".joyrideTimeline00":{
            position: "absolute",
            width: "100%",
            height: "100%",
            top: "-10px",
            left: 0,
        },
        ".joyrideChannels00":{
            position: "absolute",
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
        },
        ".joyrideChannelsXs00":{
            position: "absolute",
            width: "0",
            height: "0",
            top: "25%",
            left: "50%",
        },
        ".ScrollbarsCustom-Track":{
            backgroundColor: theme.palette.type === "light" ? "rgba(0, 0, 0, 0.1) !important" : "#000 !important",
        },
        ".ScrollbarsCustom-Thumb":{
            backgroundColor: theme.palette.type === "light" ? "rgba(0, 0, 0, 0.4) !important" : theme.palette.secondary.dark + " !important",
        },
        ".MuiPopover-root":{
            top: "74px !important",
        },
        ".MuiPopover-root > div[aria-hidden='true']":{
            top: "74px !important",
        },
        ".MuiPopover-root .MuiPaper-root.MuiPopover-paper":{
            top: "-16px !important",
        },
        ".MuiAlert-standardInfo" :{
            color: theme.palette.type === "light" ? theme.palette.text.primary + " !important" : theme.palette.text.primary + " !important",
            backgroundColor: theme.palette.type === "light" ? theme.palette.background.paper + " !important" : theme.palette.background.paper + " !important",
            boxShadow: "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
        },
        ".MuiAlert-icon" :{
            padding: "12px 0" + " !important",
            color: theme.palette.type === "light" ? theme.palette.primary.main + " !important" : theme.palette.primary.main + " !important",
            fill: theme.palette.type === "light" ? theme.palette.primary.main + " !important" : theme.palette.primary.main + " !important",
        },
        ".startTutorialButton" :{
            marginLeft: "30px",
            color: theme.palette.type === "light" ? theme.palette.background.paper + " !important" : theme.palette.background.paper + " !important",
            backgroundColor: theme.palette.type === "light" ? theme.palette.primary.main + " !important" : theme.palette.primary.main + " !important",
        },
        ".skipTutorialButton" :{

            color: theme.palette.type === "light" ? theme.palette.background.paper + " !important" : theme.palette.background.paper + " !important",
        },

    },
}));

export { useContentStyles };
