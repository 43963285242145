type ApiParamsType = {
    [key: string]: string | number ;
}

export class API {
   private _baseUrl: string;  


    constructor(config:any){
        this._baseUrl = config.baseUrl || 'https://www.epnewshub.eu/newshub/rest';
    }

    
    paramsToQuery(params:ApiParamsType):string{
        return Object.keys(params).map(function(prop) {
            return [prop, params[prop]].map(encodeURIComponent).join("=");
          }).join("&");
    }

    async getRequest(url:string,params:ApiParamsType){
        const response = await fetch(url +'?'+ this.paramsToQuery(params));
        return await response.json();
        
    }

    async SocialItem(params:ApiParamsType){
        let now = Date.now() - ( 24 * 3600000 * (1 + parseInt(''+params.pageNo)  )); //24 hours before;
        let urlParams:ApiParamsType = {
            fromDate: now,
            types:'twitter',
            limit: params.pageSize || 25 
        };
        const url = this._baseUrl+'/feedItems/find';
        const response = await this.getRequest(url,urlParams);
        return response;
      
    }   

    async Eparties(){
        const url = this._baseUrl+'/contributors/find';
        let urlParams:ApiParamsType = {
            cType:'grouppress'
        };
        const response = await this.getRequest(url,urlParams);
        return response.items;
       
    }

    async Topics(){
        const url = this._baseUrl+'/feedItems/hashTagsEvent';
        let urlParams:ApiParamsType = {
            event:'popularhashtag'
        };
        const response = await this.getRequest(url,urlParams);
        return response
    }
        
      
}







