import React, { FunctionComponent, MouseEvent, useState } from "react";
import {Button, ButtonProps, Popover, makeStyles, Typography} from "@material-ui/core";
import FilterListIcon from '@material-ui/icons/FilterList';
import { useTranslate } from "../../service/translation";
import { FilterForm } from "../layout";
import {FiltersContext} from "../../service/filters";
import classNames from "classnames";

const useStyles = makeStyles(theme => ({
    filterButton: {
        position: "absolute",
        right: 32,
        "& svg": {
            color: theme.palette.type === "light" ? theme.palette.primary.main : theme.palette.primary.main,
        },
        "& span": {
            textTransform: "none"
        },
    },
    popover: {
        margin: theme.spacing(2, 0, 0),
        "& .MuiPopover-paper": {
            outline: 0,
            position: 'absolute',
            maxWidth: 'calc(100% - 55px)',
            width: '100%',
            left: '55px !important',
            top: '58px !important',
            maxHeight: 'calc(100% - 60px)',
        },
    },
    "& .MuiMenu-list":{
        maxHeight: "300px !important",
    },
    active:{
        "& .MuiButton-label": {
            position: "relative",
            "&:before": {
                position: "absolute",
                top: 0,
                left: "100%",
                width: "5px",
                height: "5px",
                borderRadius: "50%",
                content: "''",
                backgroundColor: theme.palette.type === "light" ? theme.palette.primary.main : theme.palette.secondary.main,
            },
        },
    },
}));

const ToggleFilterButton: FunctionComponent<ButtonProps> = (props: ButtonProps) => {
    const classes = useStyles();
    const translate = useTranslate();
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [filters,setFilters] = React.useContext(FiltersContext);
    const [buttonClasses, setButtonClasses] = React.useState([classes.filterButton])


    const { onClick, ...rest } = props;

    React.useEffect(() => {
        let active = false;
        let fields = ['president','byCountry','byEparty','grouppress',
            'commission','eppress','eye','photos','onlyText','videos'];

        for(let i in fields){
            //@ts-ignore
            active = active || filters.form[fields[i]];
        }

        if(active) setButtonClasses([classes.filterButton,classes.active]);
        else setButtonClasses([classes.filterButton]);

    },[filters.form]);

    function toggleOpenFilters(){
        setFilters({...filters, openFilters: !filters.openFilters});
    }

    const handleClose = () => {
        toggleOpenFilters();
        setAnchorEl(null);
    }
    const handleOpen = (event: MouseEvent<HTMLButtonElement>) => {
        toggleOpenFilters();
        setAnchorEl(event.currentTarget);
    }
    const handleSubmit = (values: object): void => {};

    // No button variant by default, so a text button is used
    // See https://material-ui.com/components/buttons/#text-buttons
    return (
        <>
            <Button
                aria-label={translate("newshub.filter.currentFilters")}
                size="small"
                startIcon={<FilterListIcon />}
                onClick={handleOpen}
                className={classNames(...buttonClasses, classes.filterButton, 'joyrideHome01')}
                {...rest}
            >
                <Typography color="inherit" variant="subtitle1" component="span">{translate("newshub.filter.currentFilters")}</Typography>
            </Button>
            <Popover
                anchorEl={anchorEl}
                anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
                className={classes.popover}
                open={filters.openFilters}
                transformOrigin={{ horizontal: "center", vertical: "top" }}
                onClose={handleClose}
            >
                <FilterForm
                    onSubmit={handleSubmit}
                />
            </Popover>
        </>
    );
};

export default ToggleFilterButton;
