import classNames from "classnames";
import React, { FunctionComponent } from "react";
import TopicChipSkeleton from "./TopicChipSkeleton";
import { Skeleton, SkeletonProps } from "@material-ui/lab";
import { useTopicStyles } from "./useTopicStyles";
import {Box} from "@material-ui/core";

interface TopicChipListSkeletonProps extends SkeletonProps {
    justifyContent?: string;
    numItems: number;
    showTitle?: boolean;
}

const TopicChipListSkeleton: FunctionComponent<TopicChipListSkeletonProps> = (props: TopicChipListSkeletonProps) => {
    const classes = useTopicStyles();
    const { justifyContent, numItems, showTitle, ...rest } = props;
    const items = Array.from({ length: numItems }, (_, i) => i + 1);

    return (
        <Box className={classes.topics}>
            {
                showTitle && (
                    <Skeleton
                        className={classNames(classes.title, {
                            [classes.alignCenter]: justifyContent === "center",
                            [classes.alignLeft]: justifyContent === "left",
                        })}
                        variant="text"
                        width="25%"
                        {...rest}
                    />
                )
            }
            <Box className={classNames(classes.chips, {
                [classes.justifyCenter]: justifyContent === "center",
                [classes.justifyLeft]: justifyContent === "left",
            })}>
                {
                    items.map(i => (
                        <TopicChipSkeleton
                            className={classes.chip}
                            key={i}
                            {...rest}
                        />
                    ))
                }
            </Box>
        </Box>
    );
}

TopicChipListSkeleton.defaultProps = {
    animation: "wave",
    justifyContent: "center",
    showTitle: false,
};

export default TopicChipListSkeleton;
