import { Resource } from "./Resource";
import { ResourceList } from "./ResourceList";
import { ResourceMap } from "./ResourceMap";
import { ResourceName } from "./ResourceName";

const RESOURCE_MEP = "mep";
const RESOURCE_POLITICAL_GROUP = "politicalGroup";
const RESOURCE_SOCIAL_ITEM = "socialItem";
const RESOURCE_TOPIC = "topic";

const listToMap = <T extends Resource>(list: ResourceList<T>): ResourceMap<T> => {
    const map: ResourceMap<T> = {};

    for (const resource of list) {
        map[resource.id] = resource;
    }

    return map;
};

const mapToList = <T extends Resource>(map: ResourceMap<T>): ResourceList<T> => Object.values(map);

const getResourceName = (type: string): ResourceName => {
    switch (type) {
        case "getMep":
        case "getMeps":
            return RESOURCE_MEP;
        case "getPoliticalGroup":
        case "getPoliticalGroups":
            return RESOURCE_POLITICAL_GROUP;
        case "getSocialItems":
            return RESOURCE_SOCIAL_ITEM;
        case "getTopics":
            return RESOURCE_TOPIC;
    }

    const error = new Error();
    error.name = "UnsupportedOperationError";
    error.message = `
        Cannot determine the resource for the "${type}" dataProvider operation.
        Available operations are "getMep", "getMeps", "getPoliticalGroup", 
        "getPoliciticalGroups", "getSocialItems", and "getTopics"
    `;

    throw error;
};

export {
    RESOURCE_MEP,
    RESOURCE_POLITICAL_GROUP,
    RESOURCE_SOCIAL_ITEM,
    RESOURCE_TOPIC,
    getResourceName,
    listToMap,
    mapToList,
}
