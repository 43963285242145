import React, { FunctionComponent, useContext,useState,useEffect } from "react";
import { ClearFilterButton, ViewAllButton } from "../button";
import {apiProvider} from "../../service/data/apiProvider";
import {
    Box,
    Button,
    Checkbox,
    Divider,
    FormControlLabel,
    FormGroup,
    Grid, makeStyles,
    Typography,
    withStyles
} from "@material-ui/core";
import { useTranslate } from "../../service/translation";
import MultipleSelect from "./MultipleSelect";
import {countries,languages} from "../../config/countries";
import {FiltersContext, appDefaultFilters} from "../../service/filters";

interface FilterFormProps {
    onSubmit: (values: object) => void;
}

const useStyles = makeStyles(theme => ({
    form: {
        backgroundColor: theme.palette.type === "light" ? theme.palette.background.default : theme.palette.background.default,
        [theme.breakpoints.down("sm")]: {
            backgroundColor: theme.palette.type === "light" ? theme.palette.background.paper : theme.palette.background.paper,
        },
        '& .MuiCheckbox-root':{
            color: theme.palette.type === "light" ? theme.palette.text.hint : theme.palette.text.hint,
            '&.Mui-checked': {
                color: theme.palette.type === "light" ? theme.palette.primary.main : theme.palette.primary.main,
                '& svg':{
                    stroke: 'transparent',
                    color: theme.palette.type === "light" ? theme.palette.primary.main : theme.palette.primary.main,
                },
            },
            '& svg':{
                stroke: theme.palette.type === "light" ? theme.palette.background.paper : theme.palette.background.paper,
            },
        },
        checked: {},
    },
    formContainer: {
        padding: theme.spacing(2),
        marginBottom: -theme.spacing(2),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(0),
            marginBottom: theme.spacing(0),
            '& .gridItem': {
                padding: theme.spacing(0),
                marginBottom: theme.spacing(2),
            },
        },
    },
    divider: {
        margin: theme.spacing(2, 0),
    },
    legend: {
        alignItems: "center",
        display: "flex",
    },
    label: {

    },
    button: {
        marginLeft: theme.spacing(2),
    },
    clear: {
    },
    bottomBar: {
        backgroundColor: theme.palette.type === "light" ? theme.palette.background.paper : theme.palette.background.paper,
        padding: theme.spacing(2),
        marginTop: -theme.spacing(2),
    },

}));

const defaultSubscription = {
    submitting: true,
    pristine: true,
    valid: true,
    invalid: true,
};


const CustomCheckbox = (Checkbox);


const FilterForm: FunctionComponent<FilterFormProps> = (props: FilterFormProps) => {
    const classes = useStyles();
    const translate = useTranslate();
    const [filters,setFilters] = useContext(FiltersContext);
    const [formValues, setFormValues] = useState({...filters.form});
    const [groups, setGroups] = useState([]);

    useEffect(() => {
        applyHandler();
    }, [formValues]);

    function updateFilter(name:string ,value:any){
        console.log('Update filter '+ name);
        let newFilters = {...formValues, [name]: value};
        setFormValues( newFilters );

    }

    const handleClearFilter = () => {
        setFormValues( appDefaultFilters.form );
        setFilters({
            ...filters,
            crossActions: {
                ...filters.crossActions,
                clearSearchFilters : false,
                closeTopDrawer: false,
            }
        });
    };


    const clearByCountry = () => {
        setFormValues({
            ...formValues,
            byCountry: appDefaultFilters.form.byCountry,
            countries: appDefaultFilters.form.countries
        });
    };

    const clearByParty = () => {
        setFormValues({
            ...formValues,
            byEparty: appDefaultFilters.form.byEparty,
            eParties: appDefaultFilters.form.eParties
        });
    };


    const closeHandler = (event:React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        setFilters({
            ...filters,
            openFilters: false,
         });
    };


    const applyHandler = () => {
        setFilters({
            ...filters,
            //openFilters: false,
            form: {
                ...formValues,

            },
            //activeTopics:[],
            crossActions:{
                ...filters.crossActions,
                applySearchFilters: false,
                closeTopDrawer: false,
            }
         });
    }

    const handleViewAllChannels = () => {};
    const handleViewAllInstitutions = () => {};



    useEffect(() => {
        apiProvider.getPoliticalGroups().then((data) => {
            let groups:any = [];
            for(var i in data){
                groups.push({key: data[i].eparty, name:data[i].fullName});
            }
            setGroups(groups);
        });

    }, []);

    useEffect(() => {

        if(filters.crossActions.clearSearchFilters === true){
            handleClearFilter();
        }
        if(filters.crossActions.applySearchFilters === true){
            applyHandler();
        }

    },[filters.crossActions.clearSearchFilters, filters.crossActions.applySearchFilters]);



    return (

        <form
            className={classes.form}
            noValidate
            onSubmit={(e) => {e.preventDefault(); applyHandler()}}

        >
            <Grid container className={classes.formContainer}>
                <Grid item xs={12} sm={4} md={3} className="gridItem">
                    <Box className={classes.legend}>
                        <Typography
                            className={classes.label}
                            component="legend"
                            variant="h2"
                            display="inline"
                        >
                            {translate("newshub.filter.channels")}
                        </Typography>

                    </Box>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <CustomCheckbox
                                    checked={formValues.president}
                                    name="president"
                                    size="small"
                                    onChange={e => {updateFilter('president',!formValues.president)}}
                                />
                            }
                            label= {translate("newshub.channel.president")}
                        />
                        <FormControlLabel
                            control={
                                <CustomCheckbox
                                    checked={formValues.byCountry}
                                    name="country"
                                    size="small"
                                    onChange={e => {updateFilter('byCountry',!formValues.byCountry)}}
                                />
                            }
                            label= {translate("newshub.channel.country")}
                        />
                        {formValues.byCountry &&
                            <MultipleSelect
                                defaultValue = {formValues.countries}
                                handleChange = {value => { updateFilter('countries',value)}}
                                options={countries}
                                clearHandler={clearByCountry}
                                ariaLabel= {translate("newshub.channel.country")}
                            />
                        }
                        <FormControlLabel
                            control={
                                <CustomCheckbox
                                    checked={formValues.byEparty}
                                    name="politicalGroup"
                                    size="small"
                                    onChange={e => {updateFilter('byEparty',!formValues.byEparty)}}
                                />
                            }
                            label= {translate("newshub.channel.politicalGroup")}
                        />
                        { formValues.byEparty &&
                            <MultipleSelect
                                defaultValue = {formValues.eParties}
                                options={groups}
                                handleChange = {value => { updateFilter('eParties',value)}}
                                clearHandler={clearByParty}
                                ariaLabel = {translate("newshub.channel.politicalGroup")}
                            />
                        }
                    </FormGroup>
                </Grid>
                <Grid item xs={12} sm={4} md={3} className="gridItem">
                    <Box className={classes.legend}>
                        <Typography
                            className={classes.label}
                            component="legend"
                            variant="h2"
                        >
                            {translate("newshub.filter.institutions")}
                        </Typography>

                    </Box>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <CustomCheckbox
                                    name="eye"
                                    size="small"
                                    checked = {formValues.eye}
                                    onChange= {e => {updateFilter('eye',!formValues.eye)}}

                                />
                            }
                            label= {translate("newshub.institution.meps")}
                        />
                        <FormControlLabel
                            control={
                                <CustomCheckbox
                                    name="grouppress"
                                    size="small"
                                    checked = {formValues.grouppress}
                                    onChange= {e => {updateFilter('grouppress',!formValues.grouppress)}}
                                />
                            }
                            label= {translate("newshub.institution.grouppress")}
                        />
                        <FormControlLabel
                            control={
                                <CustomCheckbox
                                    name="commission"
                                    size="small"
                                    checked = {formValues.commission}
                                    onChange= {e => {updateFilter('commission',!formValues.commission)}}

                                />
                            }
                            label= {translate("newshub.institution.commission")}
                        />
                        <FormControlLabel
                            control={
                                <CustomCheckbox
                                    name="eppress"
                                    size="small"
                                    checked = {formValues.eppress}
                                    onChange= {e => {updateFilter('eppress',!formValues.eppress)}}

                                />
                            }
                            label= {translate("newshub.institution.eppress")}
                        />

                    </FormGroup>
                </Grid>
                <Grid item xs={12} sm={4} md={3} className="gridItem">
                    <Box className={classes.legend}>
                        <Typography
                            className={classes.label}
                            component="legend"
                            variant="h2"
                        >
                            {translate("newshub.filter.mediaTypes")}
                        </Typography>
                    </Box>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <CustomCheckbox
                                    name="photo"
                                    size="small"
                                    checked = {formValues.photos}
                                    onChange= {e => {updateFilter('photos',!formValues.photos)}}

                                />
                            }
                            label= {translate("newshub.mediaType.photo")}
                        />
                        <FormControlLabel
                            control={
                                <CustomCheckbox
                                    name="text"
                                    size="small"
                                    checked = {formValues.onlyText}
                                    onChange= {e => {updateFilter('onlyText',!formValues.onlyText)}}
                                />
                            }
                            label= {translate("newshub.mediaType.text")}
                        />
                        <FormControlLabel
                            control={
                                <CustomCheckbox
                                    name="video"
                                    size="small"
                                    checked = {formValues.videos}
                                    onChange= {e => {updateFilter('videos',!formValues.videos)}}
                                />
                            }
                            label= {translate("newshub.mediaType.video")}
                        />
                    </FormGroup>
                </Grid>
            </Grid>
            <Box sx={{ display: { xs: 'none', md: 'block' }}}>
                <Divider className={classes.divider} />
                <Grid container alignItems="center" className={classes.bottomBar}>
                    <Grid container item xs={6} sm={6} md={6}>
                        <ClearFilterButton
                            className={classes.clear}
                            onClick={handleClearFilter}
                        />
                    </Grid>
                    <Grid container item xs={6} sm={6} md={6} justifyContent="flex-end" >
                        <Button variant="contained" color="primary" type={"submit"} onClick={closeHandler}>
                            {translate("newshub.action.close")}
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </form>
    )
};

FilterForm.defaultProps = {

};

export default FilterForm;
