import React, { FunctionComponent, useContext, useState } from "react";
import { AnalyticsContext, AnalyticsProvider } from "../../service/analytics";
import {appDefaultFilters,FiltersContext} from "../../service/filters";
import { BrowserRouter } from "react-router-dom";
import { DataProvider, DataProviderContext } from "../../service/data";
import { History, createHashHistory } from "history";
import { InitialState } from "../../service/newshub";
import { I18nProvider, TranslationProvider } from "../../service/translation";
import { Provider, ReactReduxContext } from "react-redux";
import { ThemeOptions } from "@material-ui/core";
import { createNewshubStore } from "../../service/newshub";
import { ThemeProvider } from "@material-ui/core";
import {ThemeContext,lightTheme} from "../../config/theme";
import CssBaseline from "@material-ui/core/CssBaseline";


interface NewshubContextProps {
    analyticsProvider: AnalyticsProvider,
    children: any,
    dataProvider: DataProvider,
    history?: History,
    i18nProvider: I18nProvider,
    initialState?: InitialState,
    theme?: ThemeOptions,
}

const NewshubContext: FunctionComponent<NewshubContextProps> = (props: NewshubContextProps) => {
    const {
        analyticsProvider,
        children,
        dataProvider,
        history,
        i18nProvider,
        initialState,
    } = props;

    const finalInitialState = initialState || {};
    const finalHistory = history || createHashHistory();
    const reduxIsAlreadyInitialized = !! useContext(ReactReduxContext);

    const [filters,setFilters] = useState(appDefaultFilters);
    const [currentTheme,setCurrentTheme] = useState(lightTheme);

    const [store] = useState(! reduxIsAlreadyInitialized ? createNewshubStore({
        dataProvider,
        initialState: finalInitialState,
        history: finalHistory,
    }) : undefined);

    const renderNewshub = () => (
        <AnalyticsContext.Provider value={analyticsProvider}>
            <DataProviderContext.Provider value={dataProvider}>
                <TranslationProvider i18nProvider={i18nProvider}>
                    <BrowserRouter>
                    <ThemeContext.Provider value={[currentTheme,setCurrentTheme]}>
                        <ThemeProvider theme={currentTheme}>
                            <CssBaseline/>
                            <FiltersContext.Provider value={[filters,setFilters]}>
                                {children}
                            </FiltersContext.Provider>
                        </ThemeProvider>
                    </ThemeContext.Provider>
                    </BrowserRouter>
                </TranslationProvider>
            </DataProviderContext.Provider>
        </AnalyticsContext.Provider>
    );

    if (reduxIsAlreadyInitialized) {
        if (! history) {
            throw new Error(`
                Missing history prop. When integrating newshub into
                an existing redux Provider, you must provide the same
                'history' prop to the <Newshub> as the one used to
                bootstrap your routerMiddleware
            `);
        }

        return renderNewshub();
    } else {
        return (
            // @ts-ignore
            <Provider store={store}>
                {renderNewshub()}
            </Provider>
        );
    }
};

const rootElement = document.getElementById("root");

export default NewshubContext;
