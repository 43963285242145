import React, { useState } from "react";
import { useTranslate } from "../../service/translation";
import {
    Box,
    Button, ButtonGroup,
    Drawer, IconButton, makeStyles,
} from "@material-ui/core";
import CloseIconButton from "./CloseIconButton";
import AdvancedSearchForm from "../layout/AdvancedSearchFrom";
import {FiltersContext,appDefaultFilters} from "../../service/filters";

const useStyles = makeStyles(theme => ({
    searchDrawer: {
        '& .MuiDrawer-paper':{
            width: "100%",
        },
    },
    filterFormContainer: {
        padding: theme.spacing(1, 0, 7),
    },
    closeButton: {
        position: "absolute",
        right: 6,
        top: 14,
        zIndex: 1,
        '& svg':{
            width: 20,
            height: 20,
            color:  theme.palette.type === "light" ? theme.palette.text.hint : theme.palette.text.hint,
        }
    },
    bottomButtonsContainer: {
        position: "fixed",
        width: "100%",
        bottom: 0,
        zIndex: 1,
        '& button':{
            width: "100%",
            borderRadius: "0 !important",
            fontSize: "14px",
        },
    },
    outlinedButton:{
        '&.MuiButton-root': {
            borderColor: theme.palette.type === "light" ? '#CCC' : theme.palette.text.hint,
            '&:hover': {
                backgroundColor: theme.palette.type === "light" ? 'white' : theme.palette.background.paper,
            },
        },
        fontWeight: 400,
        color: theme.palette.type === "light" ? theme.palette.text.hint : theme.palette.text.hint,
        backgroundColor: theme.palette.type === "light" ? theme.palette.background.paper : theme.palette.background.paper,

    },
    form:{
      backgroundColor: theme.palette.type === "light" ? theme.palette.background.paper : theme.palette.background.paper,
    },
}));


export default function ToggleAdvancedSearchButton() {
    const classes = useStyles();
    const translate = useTranslate();
    const [open, setOpen] = useState(false);
    const [filters,setFilters] = React.useContext(FiltersContext);

    const handleToggle = () => {
        setOpen(! open);
    }
    const handleClear = () => {
        console.log('clear in toggleAdvancedSearchButton',filters.crossActions);
        setFilters({
            ...filters,
            crossActions:{
                ...filters.crossActions,
                clearAdvancedSearch:true,
                er:true
            }
        });

    };

    const handleApply = () => {
        console.log('Apply in toggleAdvancedSearchButton',filters.crossActions);
        setFilters({
            ...filters,
            crossActions:{
                ...filters.crossActions,
                applyAdvancedSearch: true,
                closeTopDrawer:true,
            }
        });
        setOpen(false);
    };

    


    return (
        <>
            <ButtonGroup disableElevation variant="contained" color="primary" className={classes.bottomButtonsContainer}>
                <Button onClick={handleToggle}>{translate("newshub.action.advancedsearch")}</Button>
            </ButtonGroup>
            <Drawer
                variant="temporary"
                anchor={"bottom"}
                open={open}
                className={classes.searchDrawer}
            >
                <CloseIconButton onClick={handleToggle} className={classes.closeButton} />
                <Box className={classes.filterFormContainer}>
                    <AdvancedSearchForm />
                </Box>
                <ButtonGroup disableElevation variant="contained" color="primary" className={classes.bottomButtonsContainer}>
                    <Button onClick={handleClear} variant={"outlined"} className={classes.outlinedButton}>{translate("newshub.action.clearAll")}</Button>
                    <Button onClick={handleApply} >{translate("newshub.action.apply")}</Button>
                </ButtonGroup>
            </Drawer>
        </>
    );
};

