import React, {FunctionComponent, useState} from 'react';
import { useTranslate } from "../../service/translation";
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import {ButtonProps, makeStyles} from "@material-ui/core";
import classNames from "classnames";
import HomeIcon from '@material-ui/icons/Home';
import ToggleFilterBottomNavButton from "../button/ToggleFilterBottomNavButton";
import ToggleTopicsBottomNavButton from '../button/ToggleTopicsBottomNavButton';
import {Publish} from "@material-ui/icons";
import SearchBar from "./SearchBar";
import {Route} from "react-router";

const useStyles = makeStyles(theme => ({
    stickToBottom: {
        width: '100%',
        position: 'fixed',
        bottom: 0,
        height: '40px',
        boxShadow: '0 -2px 4px 0 rgba(0, 0, 0, 0.16)',
        zIndex: 1100,
        '& svg': {
            width: '20px',
            height: '20px',
            '& + span': {
                marginLeft: theme.spacing(0.5),
                fontSize: '12px !important',
                color: theme.palette.type === "light" ? theme.palette.text.hint : theme.palette.text.hint,
            },
        },
    },
}), { name: "XsBottomNavigation" });

const XsBottomNavigation: FunctionComponent<ButtonProps> = (props: ButtonProps) => {
    const translate = useTranslate();
    const [value, setValue] = React.useState(0);
    const classes = useStyles();
    const ScrollButton = () =>{

        const [, setVisible] = useState(false)


        const scrollToTop = () =>{
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        };


        return (
            <BottomNavigationAction showLabel={true} label={translate("newshub.bottomBar.top")} icon={<Publish />}  onClick={scrollToTop} />
        );
    }


    return (
        <BottomNavigation
            value={value}
            onChange={(event, newValue) => {
                setValue(newValue);
            }}
            showLabels
            className={classNames(classes.stickToBottom)}
        >
            <ScrollButton />
            <Route path={["/", "/timeline", "/contributors/:id", "/privacy", "/cookies"]} exact component={ToggleTopicsBottomNavButton}/>
            <Route path={["/", "/timeline", "/contributors/:id", "/privacy", "/cookies"]} exact component={ToggleFilterBottomNavButton}/>
        </BottomNavigation>
    );
}
export default XsBottomNavigation;
