export const REFRESH_VIEW = "EP/REFRESH_VIEW";

export interface RefreshViewAction {
    readonly type: typeof REFRESH_VIEW;
    readonly payload: { hard: boolean };
}

export const refreshView = (hard: boolean = false): RefreshViewAction => ({
    type: REFRESH_VIEW,
    payload: { hard },
});
