import { Translate } from "../Translate";
import { TranslationContext} from "../TranslationContext";
import { useCallback, useContext } from "react";

const identity = (message: string): string => message;

/**
 * A hook used to translate messages using the i18nProvider available in
 * the {@link TranslationContext}.
 *
 * This should be used from within components which are decorated by the
 * {@link TranslationProvider}, responsible for injecting the appropriate
 * values.
 */
const useTranslate = (): Translate => {
    const { i18nProvider, locale } = useContext(TranslationContext);
    const translate = useCallback((key: string, options?: any) =>
        i18nProvider.translate(key, options) as string,
        // Update the hook each time the locale changes
        [i18nProvider, locale] // eslint-disable-line react-hooks/exhaustive-deps
    );

    return i18nProvider ? translate : identity;
};

export { useTranslate };
