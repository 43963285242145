import React, { FunctionComponent } from "react";
import LoadingContent from "./LoadingContent";
import { ThemeProvider } from "@material-ui/styles";
import { ThemeOptions, createTheme } from "@material-ui/core";

interface LoadingPageProps {
    theme: ThemeOptions;
}

const LoadingPage: FunctionComponent<LoadingPageProps> = ({ theme }: LoadingPageProps) => (
    <ThemeProvider theme={theme}>
        <LoadingContent />
    </ThemeProvider>
);

LoadingPage.defaultProps = {
    theme: createTheme({}),
};

export default LoadingPage;
