import React, {FunctionComponent, useState, useContext, MouseEvent} from "react";
import SearchIcon from "@material-ui/icons/Search";
import {InputBase, makeStyles, Box, Popover, Typography, IconButton} from "@material-ui/core";
import { useTranslate } from "../../service/translation";
import SearchModule from "./SearchModule";
import {FiltersContext, appDefaultFilters} from "../../service/filters";
import { Form } from "react-final-form";
import CloseIcon from "@material-ui/icons/Close";


const useStyles = makeStyles(theme => ({
    popover: {
        "& .MuiPopover-paper": {
            outline: 0,
            position: 'absolute',
            maxWidth: '480px',
            width: '100%',
            top: '16px !important',
        }
    },
    search: {
        height: 40,
        borderRadius: 30,
        position: "relative",
        maxWidth: 480,
        width: "100%",
        margin: "0 auto",
    },
    searchBox: {
        color: "inherit",
        width: "100%",
    },
    searchIcon: {
        alignItems: "center",
        display: "flex",
        height: "100%",
        justifyContent: "center",
        padding: theme.spacing(0, 2),
        position: "absolute",
        pointerEvents: "none",
        color: theme.palette.primary.main,
        zIndex: 1,
    },
    searchInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create("width"),
        maxWidth: 480,
        width: "100%",
        height: "100%",
        borderRadius: "20px !important",
        "&:focus-visible": {
            outline: "1px solid #bbb !important",
            outlineOffset: "0",
            backgroundColor: theme.palette.background.default,
            boxShadow: "inset 0px 2px 3px #bbb",
            borderRadius: "20px !important",
        },
        "&:-moz-focusring": {
            outline: "1px solid #bbb !important",
            outlineOffset: "0",
            backgroundColor: theme.palette.background.default,
            boxShadow: "inset 0px 2px 3px #bbb",
            borderRadius: "20px !important",
        },
    },
    clearSearchButton:{
        alignItems: "center",
        display: "flex",
        height: "100%",
        justifyContent: "center",
        padding: theme.spacing(0, 2),
        position: "absolute",
        right: 0,
        top: 0,
        color: theme.palette.type === "light" ? "#999" : theme.palette.secondary.dark,
        zIndex: 1,
    },
}));

export default function ChannelsSearchBar() {
    const classes = useStyles();
    const translate = useTranslate();
    const [filters,setFilters] = useContext(FiltersContext);
    const [inputValue,setInputValue] = React.useState(filters.channels.search);


    const onChange = (value:string) => {
        setInputValue(value);
        if(value.length === 0 ) fetchSearch('');
    };

    const resetChannels = ()=> {
        onChange('');
    };

    const fetchSearch = (value:string) => {
        setFilters({
            ...appDefaultFilters,
            channels:{
                ...filters.channels,
                search: value
            }
        });
    }

    const submit = () => {
        fetchSearch (inputValue as string);
    };

    return (
        <Form
            onSubmit={submit}
            render={
                ({ handleSubmit }) => (
                <form
                        noValidate
                        onSubmit={handleSubmit}
                    >
                        <Box className={classes.searchIcon}>
                            <SearchIcon />
                        </Box>
                        <InputBase
                            classes={{
                                root: classes.searchBox,
                                input: classes.searchInput,
                            }}
                            inputProps={{ "aria-label": translate("newshub.action.search") }}
                            placeholder={translate("newshub.action.search")}
                            onChange={e => onChange(e.currentTarget.value) }
                            value={ inputValue}


                        />
                        { typeof inputValue === "string" && inputValue.length > 0 &&
                            <IconButton aria-label="delete" className={classes.clearSearchButton}>
                                <CloseIcon onClick={resetChannels} fontSize="small" />
                            </IconButton>
                        }
                    </form>
                )
            }
        />
    );
};
