import React, { useState } from "react";
import { useTranslate } from "../../service/translation";
import {
    Box,
    Drawer, IconButton, makeStyles,
} from "@material-ui/core";
import CloseIconButton from "./CloseIconButton";
import SearchIcon from "@material-ui/icons/Search";
import SearchModule from "../layout/SearchModule";
import ToggleAdvancedSearchButton from "./ToggleAdvancedSearchButton";
import {FiltersContext, appDefaultFilters} from "../../service/filters";
import classNames from "classnames";

const useStyles = makeStyles(theme => ({
    searchDrawer: {
        '& .MuiDrawer-paper':{
            width: "100%",
        },
    },
    filterFormContainer: {
        padding: theme.spacing(1, 0, 7),
    },
    closeButton: {
        position: "absolute",
        right: 6,
        top: 14,
        zIndex: 1,
        '& svg':{
            width: 20,
            height: 20,
            color:  theme.palette.type === "light" ? theme.palette.text.hint : theme.palette.text.hint,
        }
    },
    bottomButtonsContainer: {
        position: "fixed",
        width: "100%",
        bottom: 0,
        '& button':{
            width: "100%",
            borderRadius: "0 !important",
            fontSize: "14px",
        },
    },
    outlinedButton:{
        '&.MuiButton-root': {
            borderColor: theme.palette.type === "light" ? '#CCC' : theme.palette.text.hint,
            '&:hover': {
                backgroundColor: theme.palette.type === "light" ? 'white' : theme.palette.background.paper,
            },
        },
        fontWeight: 400,
        color: theme.palette.type === "light" ? theme.palette.text.hint : theme.palette.text.hint,
        backgroundColor: theme.palette.type === "light" ? 'white' : theme.palette.background.paper,

    },
    form:{
      backgroundColor: theme.palette.type === "light" ? 'white' : theme.palette.background.paper,
    },
}));

export default function ToggleSearchTopbarButton() {
    const classes = useStyles();
    const translate = useTranslate();
    const [open, setOpen] = useState(false);
    const [filters,setFilters] = React.useContext(FiltersContext);

    const handleToggle = () => setOpen(! open);
    const handleSubmit = () => {
        setOpen(false);
    };

    React.useEffect(() => {
        switch(filters.crossActions.searchBarEvent.action){
            case 'searchText':
                setOpen(false);
                break;
        }

    },[filters.crossActions.searchBarEvent]);

    React.useEffect(() => {
        console.log('useEffect Drawer 2', filters.crossActions.applyAdvancedSearch)
        if(filters.crossActions.closeTopDrawer === true){
            setOpen(false);
        }

    },[filters.crossActions.closeTopDrawer]);

    return (
        <>
            <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleToggle}
                edge="end"
                className={classNames('joyrideHomeXs02')}
            >
                <SearchIcon />
            </IconButton>
            <Drawer
                variant="temporary"
                anchor={"left"}
                open={open}
                className={classes.searchDrawer}
            >
                <CloseIconButton onClick={handleToggle} className={classes.closeButton} />
                <Box className={classes.filterFormContainer}>
                    <SearchModule onSubmit={handleSubmit} />
                </Box>
                <ToggleAdvancedSearchButton />
            </Drawer>
        </>
    );
};

