const config = {
    locale: {
        default: "en",
    },
    pagination: {
        perPage: 32,
    },
};

export default config;
