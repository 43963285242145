import { DataProvider, DataProviderResult, validateResult } from "../../data";
import { DeclarativeSideEffect } from "./DeclarativeSideEffect";
import { FETCH_CANCEL, FETCH_END, FETCH_ERROR, FETCH_START, sanitizeFetchType } from "../actions";
import { NewshubState } from "../../newshub";
import { all, call, cancelled, put, select, takeEvery } from 'redux-saga/effects';

interface ActionWithSideEffect {
    type: string;
    payload: any;
    meta: {
        fetch: string;
        resource: string;
        onSuccess?: DeclarativeSideEffect;
        onFailure?: DeclarativeSideEffect;
    };
}

export function* handleFetch(dataProvider: DataProvider, action: ActionWithSideEffect) {
    const { type, payload, meta: { fetch: restType, onSuccess, onFailure, ...meta }} = action;
    const successSideEffects = onSuccess instanceof Function ? {} : onSuccess;
    const failureSideEffects = onFailure instanceof Function ? {} : onFailure;

    try {
        const isOptimistic: boolean = yield select((state: NewshubState) => state.newshub.refresh.optimistic);

        if (isOptimistic) {
            // In optimistic mode, all fetch actions are cancelled,
            // so the app uses the store without synchronization
            return;
        }

        yield all([
            put({ type: `${type}_LOADING`, payload, meta }),
            put({ type: FETCH_START }),
        ]);

        // @ts-ignore
        const result: DataProviderResult = yield call(dataProvider[sanitizeFetchType(restType)], meta.resource, payload);

        if (process.env.NODE_ENV !== "production") {
            validateResult(result, restType);
        }

        yield put({
            type: `${type} SUCCESS`,
            payload: result,
            requestPayload: payload,
            meta: {
                ...meta,
                ...successSideEffects,
                fetchResponse: restType,
                fetchStatus: FETCH_END,
            },
        });

        yield put({
            type: FETCH_END,
        });

    } catch (error) {
        yield put({
            type: `${type} FAILURE`,
            error: (error && (error.message ? error.message : error)) || null,
            payload: (error && error.body) || null,
            requestPayload: payload,
            meta: {
                ...meta,
                ...failureSideEffects,
                fetchResponse: restType,
                fetchStatus: FETCH_ERROR,
            },
        });

        yield put({
            type: FETCH_ERROR,
            error,
        });

    } finally {
        const isCancel: boolean = yield cancelled();

        if (isCancel) {
            yield put({
                type: FETCH_CANCEL,
            });
        }
    }
}

export const takeFetchAction = (action: ActionWithSideEffect): string => action.meta && action.meta.fetch;

const fetchSaga = (dataProvider: DataProvider) => function* watchFetch() {
    // @ts-ignore
    yield takeEvery(takeFetchAction, handleFetch, dataProvider);
};

export { fetchSaga };
