import { put, takeEvery } from "redux-saga/effects";
import { refreshView } from "../actions";

function* handleRefresh() {
    yield put(refreshView());
}

const refreshSaga = function* () {
    // @ts-ignore
    yield takeEvery(action => action.meta && action.meta.refresh, handleRefresh);
};

export { refreshSaga };
