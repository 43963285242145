import React, { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import MepAvatar from "./MepAvatar";
import {Box, Card, CardHeader, Grid, MenuItem, Typography} from "@material-ui/core";
import { Mep } from "../../service/data";
import { ViewChannelButton } from "../button";

interface MepCardProps {
    className?: string;
    record: Mep;
}

const MepCardSearchBar: FunctionComponent<MepCardProps> = (props: MepCardProps) => {
    const { className, record } = props;
    const { id, country, firstName, lastName, politicalGroup: { party }} = record;

    return (
        <MenuItem component={"a"} href={`/contributors/${id}`} tabIndex={0}>

                    <Card className={className}>
                        <CardHeader
                            action={
                                <ViewChannelButton />
                            }
                            avatar={
                                <MepAvatar record={record} />
                            }
                            subheader={
                                <Box className="preWrap">
                                    <Typography variant="subtitle2" display="inline" component="p">
                                        <strong>
                                            {party}
                                            {party? ", " :''}
                                        </strong>
                                    </Typography>
                                    <Typography variant="subtitle2" display="inline" component="p">
                                        {country}
                                    </Typography>
                                </Box>
                            }
                            title={
                                <Typography variant="h2" component="p">
                                    {`${firstName} ${lastName}`}
                                </Typography>
                            }
                        />
                    </Card>
        </MenuItem>
    );
};

export default MepCardSearchBar;
