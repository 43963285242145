import React, {FunctionComponent} from "react";
import {SocialItemsList} from "../social";
import {TopicList} from "../topic";
import {useContentStyles} from "./useContentStyles";
import {useTranslate} from "../../service/translation";
import {Box, Grid, Typography} from '@material-ui/core';
import HomeTutorial from "./JoyrideHomeTutorial";
import HomeTutorialXs from "./JoyrideHomeTutorialXs";
import classNames from "classnames";
import {useCookies} from "react-cookie";
import SimpleSnackbar from "../button/TutorialSnackbar";
import {FiltersContext, appDefaultFilters} from "../../service/filters"


interface HomeContentProps {
}

const HomeContent: FunctionComponent<HomeContentProps> = (props: HomeContentProps) => {
    const classes = useContentStyles();
    const translate = useTranslate();
    const helpCookieName = 'eup_hlp';
    const [cookies, setCookie] = useCookies<any>([helpCookieName, helpCookieName + 'XS']);
    const [filters, setFilters] = React.useContext(FiltersContext);
    const [showTutorial, setShowTutorial] = React.useState(false);

    const skipOrEndCallback = () => {
        const expiryTime = (new Date()).getTime() + (1000 * 3600 * 24 * 180);
        const expiryDate = new Date(expiryTime);
        setCookie(helpCookieName, 1, {path: "/", expires: expiryDate});
    };

    const skipOrEndCallbackXs = () => {
        const expiryTime = (new Date()).getTime() + (1000 * 3600 * 24 * 180);
        const expiryDate = new Date(expiryTime);
        setCookie(helpCookieName + 'XS', 1, {path: "/", expires: expiryDate});
    };


    React.useEffect(() => {
        switch (filters.crossActions.TutorialEvent.action) {
            case 'show':
                setShowTutorial(true);
                break;
        }

    }, [filters.crossActions.TutorialEvent]);


    React.useEffect(() => {
        switch (filters.crossActions.timeLineEvent.action) {
            case 'skip':
            case 'end':
                setShowTutorial(false);
                break;
            case 'show':
                setShowTutorial(true);
                break;

        }

    }, [filters.crossActions.TutorialEvent]);


    return (
        <Grid container className={classes.page} id="main-content">

            {showTutorial &&
                <>
                    <Box sx={{display: {xs: 'none', md: 'block'}}}>
                        <HomeTutorial
                            breakpoint={""}
                            translate={translate}
                            skipOrEndCallback={skipOrEndCallback}
                        />
                    </Box>


                    <Box sx={{display: {xs: 'block', md: 'none'}}}>
                        <HomeTutorialXs
                            breakpoint={""}
                            translate={translate}
                            skipOrEndCallback={skipOrEndCallbackXs}
                        />
                    </Box>
                </>
            }
            <Grid container className={classes.pageWrapper}>
                <Grid item xs={12} sm={12} md className="gridItem order1"></Grid>
                <Grid item xs={12} sm={4} md className="gridItem stickyTopics order3">
                    <Box className={classNames(classes.aside)} sx={{display: {xs: 'none', md: 'block'}}}>
                        <TopicList justifyContent="left" title={translate("topic.message.title")}/>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={5} className="gridItem homeCardList order2">
                    <Box className={classNames('joyrideHome00')} sx={{display: {xs: 'none', md: 'block'}}}></Box>
                    <Box className={classNames('joyrideHomeXs00')} sx={{display: {xs: 'block', md: 'none'}}}></Box>
                    <SocialItemsList resultsType="home"/>
                </Grid>
            </Grid>

            {(cookies[helpCookieName] !== '1' || cookies[helpCookieName + 'XS'] !== '1') && !showTutorial &&
                <SimpleSnackbar/>
            }

        </Grid>
    );
};

export default HomeContent;
