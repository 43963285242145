import { makeStyles } from "@material-ui/core";

const useTopicStyles = makeStyles(theme => ({
    topics: {
        display: "flex",
        flexDirection: "column",
    },
    topicsTitle:{
        position: "relative",
        /*pointerEvents: "none",*/
    },
    topicsClearAll:{
        position: "absolute",
        right: 0,
        top: "3px",
        zIndex: 1,
    },
    title: {
        margin: theme.spacing(0, 0, 1),
        textAlign: "center",
        letterSpacing: -0.5,
        pointerEvents: "none",
    },
    alignCenter: {
        textAlign: "center",
    },
    alignLeft: {
        textAlign: "left",
    },
    clear: {
        marginLeft: "auto",
    },
    chips: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
    },
    justifyCenter: {
        justifyContent: "center",
    },
    justifyLeft: {
        justifyContent: "flex-start",
    },
    chip: {
        height: theme.spacing(3.5),
        margin: theme.spacing(0, 0.25, 0.5),
        /*letterSpacing: "0.4px",*/
        "& .MuiChip-label": {
            padding: theme.spacing(0, 1),
        },
        "&.active, &.active:focus, &.active:hover, &.active&:hover&:focus" : {
            backgroundColor: theme.palette.type === "light" ? theme.palette.secondary.main : theme.palette.secondary.main,
            color: theme.palette.type === "light" ? '#0d244a' : 'black',
        },
        "&:hover, &:focus&:hover" : {
            backgroundColor: theme.palette.type === "light" ? theme.palette.primary.dark : theme.palette.primary.dark,
        },
        "&:focus-visible" : {
            color: theme.palette.type === "light" ? '#0d244a' : 'black',
            borderRadius: "0px !important",
            outline: "3px dotted #d9d9d9",
            outlineOffset: "-3px",
        },
        "&:-moz-focusring": {
            borderRadius: "0px !important",
            outline: "3px dotted #d9d9d9",
            outlineOffset: "-3px",
        },

    },
    low: {
        backgroundColor:  theme.palette.type === "light" ? '#006CE8' : '#F3F8FE',
        color: theme.palette.type === "light" ? 'white' : 'black',
        "&:focus" : {
            backgroundColor:  theme.palette.type === "light" ? '#006CE8' : '#F3F8FE',
            color: theme.palette.type === "light" ? 'white' : 'black',
        },
    },
    medium: {
        backgroundColor:  theme.palette.type === "light" ? '#005AC2' : '#B9D2FB',
        color: theme.palette.type === "light" ? 'white' : 'black',
        "&:focus" : {
            backgroundColor:  theme.palette.type === "light" ? '#005AC2' : '#B9D2FB',
            color: theme.palette.type === "light" ? 'white' : 'black',
        },
    },
    high: {
        backgroundColor:  theme.palette.type === "light" ? '#002D60' : '#A2C3FA',
        color: theme.palette.type === "light" ? 'white' : 'black',
        "&:focus" : {
            backgroundColor:  theme.palette.type === "light" ? '#002D60' : '#A2C3FA',
            color: theme.palette.type === "light" ? 'white' : 'black',
        },
    },
    trending: {
        backgroundColor:  theme.palette.type === "light" ? '#001730' : '#8AB4F8',
        color: theme.palette.type === "light" ? 'white' : 'black',
        "&:focus" : {
            backgroundColor:  theme.palette.type === "light" ? '#001730' : '#8AB4F8',
            color: theme.palette.type === "light" ? 'white' : 'black',
        },
    },
    clearAllButton:{
        [theme.breakpoints.down("sm")]: {
            marginRight: theme.spacing(3),
            display: "none !important",
        },
    }
}));

export { useTopicStyles }
