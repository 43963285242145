import React, { Children, FunctionComponent, useCallback, useMemo, useState } from "react";
import { I18nProvider } from "./I18nProvider";
import { TranslationContext } from "./TranslationContext";

interface TranslationProviderProps {
    locale?: string;
    i18nProvider: I18nProvider;
}

interface TranslationProviderState {
    locale: string;
    i18nProvider: I18nProvider;
}

/**
 * Creates a {@link TranslationContext}, available to its children.
 *
 * @example
 * const App = () => {
 *     <Provider store={store}>
 *         <TranslationProvider i18nProvider={myI18nProvider}>
 *             <!-- Child components go here -->
 *         </TranslationProvider>
 *     </Provider>
 * }
 */
const TranslationProvider: FunctionComponent<TranslationProviderProps> = props => {
    const { children, i18nProvider } = props;
    const [state, setState] = useState<TranslationProviderState>({
        locale: 'en',  //i18nProvider ? i18nProvider.getLocale() : "en",
        i18nProvider,
    });

    const setLocale = useCallback((newLocale: string) => setState({ ...state, locale: newLocale }), [state]);

    // Allow locale modification by including setLocale in the context
    // This can't be done in the initial state because setState doesn't
    // exists yet
    const value = useMemo(() => ({
        ...state,
        setLocale,
    }), [setLocale, state]);

    return (
        <TranslationContext.Provider value= {value}>
            {Children.only(children)}
        </TranslationContext.Provider>
    );
};

export {
    TranslationProvider,
}
