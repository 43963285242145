import React, { FunctionComponent } from "react";
import {Box, makeStyles} from "@material-ui/core";
import { useTranslate } from "../../service/translation";

const useStyles = makeStyles(theme => ({
    loading: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        [theme.breakpoints.up("md")]: {
            height: "100%",
        },
        [theme.breakpoints.down("sm")]: {
            height: "100vh",
        },
    },
    message: {
        margin: "0 1em",
        textAlign: "center",
    },
}), { name: "NewshubLoading" });

const LoadingContent: FunctionComponent = () => {
    const classes = useStyles();
    const translate = useTranslate();

    return (
        <Box className={classes.loading}>
            <Box className={classes.message}>
                <h1>{translate("newshub.page.loading")}</h1>
                <Box>{translate("newshub.message.loading")}</Box>
            </Box>
        </Box>
    );
};

export default LoadingContent;
