import { GetCollectionParams } from "../GetCollectionParams";

export const SET_COLLECTION_PARAMS = "EP/SET_COLLECTION_PARAMS";

export interface SetCollectionParamsAction {
    readonly type: typeof SET_COLLECTION_PARAMS;
    readonly payload: GetCollectionParams;
}

export const set = (params: GetCollectionParams): SetCollectionParamsAction => ({
    type: SET_COLLECTION_PARAMS,
    payload: params,
});
