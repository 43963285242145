import { Button, makeStyles } from "@material-ui/core";
import { useTranslate } from "../../service/translation";
import {useEffect} from "react";

const useStyles = makeStyles(theme => ({
    skipToContent: {
        backgroundColor: theme.palette.background.default + " !important",
        color: theme.palette.getContrastText(theme.palette.background.default),
        left: theme.spacing(2),
        padding: theme.spacing(1),
        position: "fixed",
        top: theme.spacing(-10),
        transition: theme.transitions.create(["top", "opacity"], {
            easing: theme.transitions.easing.easeIn,
            duration: theme.transitions.duration.leavingScreen,
        }),
        zIndex: 5000,
        "&:hover": {
            opacity: 0.0,
            backgroundColor: theme.palette.background.default,
        },
        "&:focus": {
            top: theme.spacing(2),
            transition: theme.transitions.create(["top", "opacity"], {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
    },
}), { name: "NewshubSkipToContentButton" });

const skipToContent = () => {
    if (typeof document === "undefined") {
        return;
    }

    const element = document.getElementById("main-content");

    if (! element) {
        if (process.env.NODE_ENV !== "production") {
            console.warn(`
                No element with id "main-content" was found. Ensure the
                element that contains your main content has an id of
                "main-content"
            `);
        }

        return;
    }

    element.setAttribute("tabIndex", "-1");
    element.focus();
    element.blur();
    element.removeAttribute("tabIndex");
};

const SkipToContentButton = () => {
    const classes = useStyles();
    const translate = useTranslate();
    // terrible workaround to get 100% KPI for siteimprove. remove this if anything goes wrong
    //TODO: remove me when they kick siteimprove
    useEffect(() => {
        const o = document.querySelector('.MuiButtonBase-root')
        if(o)
            o.removeAttribute('aria-disabled');
    }, []);

    return (
        <Button
            role="region"
            aria-label={translate("newshub.action.skipToContent")}
            className={classes.skipToContent}
            variant="contained"
            href="#main-content"
            onClick={skipToContent}
        >
            {translate("newshub.action.skipToContent")}
        </Button>
    )
};

export default SkipToContentButton;
