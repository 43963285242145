import { makeStyles } from "@material-ui/core";

const useSocialCardListStyles = makeStyles(theme => ({
    list: {
        display: "flex",
        flexDirection: "column",
        margin: theme.spacing(0, "auto"),
        maxWidth: 480,
        width: "100%",
        position: "relative",
        [theme.breakpoints.down("sm")]: {
            paddingBottom: "50px",
        },
        [theme.breakpoints.down("xs")]: {
            paddingBottom: "40px",
        },
    },
    masonryLayout: {
        padding: 0,
        margin: 0,
    },
}), { name: "NewshubSocialCardList" });

export { useSocialCardListStyles };
