import React, { FunctionComponent, useEffect } from "react";
import Layout from "./Layout";
import ReactAlert from "../banner/TwitterBanner";

interface NewshubUiProps {
    children?: any;
    disableTelemetry?: boolean;
}

const NewshubUi: FunctionComponent<NewshubUiProps> = (props: NewshubUiProps) => {
    const { children, disableTelemetry } = props;

    useEffect(() => {
        if (disableTelemetry ||
            process.env.NODE_ENV !== "production" ||
            typeof window === "undefined" ||
            typeof window.location === "undefined" ||
            typeof Image === "undefined") {
            return;
        }
    }, [disableTelemetry])

    return (
        <Layout>
            {children}
        </Layout>
    );
};

export default NewshubUi;
