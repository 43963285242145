export const SET_SIDEBAR_VISIBILITY = "EP/SET_SIDEBAR_VISIBILITY";

export interface SetSidebarVisibilityAction {
    readonly type: typeof SET_SIDEBAR_VISIBILITY;
    readonly payload: boolean;
}

export const setSidebarVisibility = (isOpen: boolean): SetSidebarVisibilityAction => ({
    type: SET_SIDEBAR_VISIBILITY,
    payload: isOpen,
});
