import React, { FunctionComponent } from "react";
import { useTranslate } from "../../service/translation";
import { useContentStyles } from "./useContentStyles";
import {Box, Grid, Link, Typography} from "@material-ui/core";
import classNames from "classnames";

const NotFoundContentChannels: FunctionComponent = () => {
    const classes = useContentStyles();
    const translate = useTranslate();

    return (

        <Grid container justifyContent={"center"} alignItems={"center"} className={classNames(classes.notFoundContentContainer, 'notFoundContent')}>
            <Grid item>
                <Box mb={4}>
                    <Typography component={"h1"} variant={"h1"} gutterBottom>{translate("newshub.error.notFoundContentChannels.title")}</Typography>
                    <Typography component={"p"} variant={"h6"} gutterBottom>{translate("newshub.error.notFoundContentChannels.description1")}</Typography>
                </Box>
            
            </Grid>
        </Grid>

    );
};

export default NotFoundContentChannels;
