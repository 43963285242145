import React, {FunctionComponent, useContext, useState} from "react";
import ChannelsIcon from "@material-ui/icons/Forum";
import HomeIcon from "@material-ui/icons/Home";
import TimelineIcon from "@material-ui/icons/Schedule";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { MENU_WIDTH_OPEN, MENU_WIDTH_CLOSED } from "./index";
import ToggleThemeIcon from "@material-ui/icons/Brightness4";
import Brightness7Icon from '@material-ui/icons/Brightness7';
import { useTranslate } from "../../service/translation";
import {
    Drawer,
    List,
    ListItemText,
    ListItemIcon,
    ListItem,
    Tooltip,
    ToolbarProps,
    makeStyles, Box, Button, Typography, Fade, duration, Collapse,
} from "@material-ui/core";
import {ThemeContext,darkTheme,lightTheme} from "../../config/theme"
import HelpIcon from "@material-ui/icons/Help";
import {ExpandLess, ExpandMore} from "@material-ui/icons";
import MenuTutorialButton from "../button/MenuTutorialButton";
import MenuSendFeedbackButton from "../button/MenuSendFeedbackButton";
import { setFilter } from "../../service/data";
import {FiltersContext,appDefaultFilters} from "../../service/filters"
import { useCookies } from "react-cookie";

const useStyles = makeStyles(theme => ({
    fadeElement:{
        display:"none",
    },
    bottomContainer:{
        marginTop: 'auto',
    },
    claimTitle:{
        fontSize: 12,
        fontWeight: "bold",
        whiteSpace: "normal",
        padding: theme.spacing(0,2,0.5),
        color: "white",
    },
    claimTxt:{
        fontSize: 12,
        whiteSpace: "normal",
        padding: theme.spacing(0,2,2),
        color: "white",
    },
    nhlogo: {
        "& a":{
            display: "block",
            padding:'16px 7px 10px',
        },
        "& img":{
            maxWidth: 170,
        },
    },
    eplogo: {
        "& a":{
            display: "block",
            padding:'16px 6px',
            width: "100%",
        },
        "& img":{
            maxWidth: 180,
        },
        [theme.breakpoints.down('sm')]: {
            padding:'8px 6px',
        },
    },
    menuList: {
        '& .active': {
            backgroundColor: theme.palette.background.default,
            '& *':{
                color: theme.palette.primary.main,
            },
        },
        '& li': {
            '& a': {
                '&:hover': {
                    backgroundColor: theme.palette.background.default,
                    '& *': {
                        color: theme.palette.primary.main,
                    },
                },
            },
        },
        '& >li ': {
            padding: 0,
            /*[theme.breakpoints.down('sm')]: {
                paddingLeft: theme.spacing(1.5),
            },*/
            '&:hover': {
                '& >a ': {
                    backgroundColor: theme.palette.background.default,
                    '& *': {
                        color: theme.palette.primary.main,
                    },
                },
            },
            '& >a ': {
                display: "block",
                padding: theme.spacing(1,2),
                width: "100%",
                textDecoration: "none",

                '& >div': {
                    fontSize: 0,
                    minWidth: 40,
                    display: "inline-block",
                    verticalAlign: "middle",
                    '& >span': {
                        marginTop: 0,
                    },
                },
            },

            '& button ': {
                padding: 0,
                display: "block",
                textAlign: "left",
                '& >span': {
                    '& >div': {
                        fontSize: 0,
                        minWidth: 40,
                        display: "inline-block",
                        verticalAlign: "middle",
                    },
                },
            }
        },
        [theme.breakpoints.down('sm')]: {
            paddingTop: 53,
        },
        '& button': {
            '&:hover': {
                backgroundColor: theme.palette.background.default + " !important",
                '& *': {
                    color: theme.palette.primary.main,
                },
            },
        },
    },
    drawer: {
        width: theme.spacing(MENU_WIDTH_OPEN),
        flexShrink: 0,
        whiteSpace: 'nowrap',
        backgroundColor: theme.palette.type === "light" ? theme.palette.primary.main : theme.palette.background.paper,
        '& span': {
            fontWeight: '400',
            color: '#FFFFFF',
            opacity: 0,
            transition: "opacity 300ms ease-in-out",
        },
        '& .MuiButton-label': {
            opacity: 1,
            '& .MuiListItemText-root': {
                opacity: 0,
                transition: "opacity 300ms ease-in-out",
            },
        },
        '& svg': {
            color: '#FFFFFF',
            width: '20px',
        },
        [theme.breakpoints.down('sm')]: {
            zIndex: 1100,
        },
    },
    drawerOpen: {
        width: theme.spacing(MENU_WIDTH_OPEN),
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        backgroundColor: theme.palette.type === "light" ? theme.palette.primary.main : theme.palette.background.paper,
        overflowX: "hidden",
        overflowY: "hidden",
        border: 0,
        boxShadow:  theme.palette.type === "light" ? '-6px 0px 11px 6px #aaa' : '-3px 0px 9px #000',
        '& span': {
            opacity: 1,
        },
        '& .MuiButton-label': {
            '& .MuiListItemText-root': {
                opacity: 1,
            },
        },
        [theme.breakpoints.down('sm')]: {
            zIndex: 1101,
            overflowY: "auto",
            /*maxHeight: 'calc(100% - 40px)',*/
        },
    },
    drawerClosed: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: "hidden",
        border: 0,
        boxShadow:  theme.palette.type === "light" ? '-6px 0px 11px 6px #aaa' : '-3px 0px 9px #000',
        width: theme.spacing(MENU_WIDTH_CLOSED) - 1,
        backgroundColor: theme.palette.type === "light" ? theme.palette.primary.main : theme.palette.background.paper,
        [theme.breakpoints.up('xs')]: {
            width: theme.spacing(MENU_WIDTH_CLOSED) - 1,
        },
        [theme.breakpoints.down('sm')]: {
            width: theme.spacing(0),
            zIndex: 1101,
            /*maxHeight: 'calc(100% - 40px)',*/
        },
    },
    nested: {
        padding: "0 !important",
        /*paddingLeft: theme.spacing(7),*/
        '& >a': {
            padding: theme.spacing(0.5,2,0.5,7),
            textDecoration: "none",
            display: "block",
            width: "100%",
            "& .MuiTypography-root": {
                fontSize: "14px",
                [theme.breakpoints.down('xs')]: {
                    fontSize: "12px",
                },
            },
        },
        '& >button': {
            padding: theme.spacing(0.5,2,0.5,7),
            minWidth: "0 !important",
            width: "100%",
            textAlign: "left",
            borderRadius: "0 !important",
            '&:hover': {
                background: "transparent",
            },
            "& .MuiTypography-root": {
                fontSize: "14px",
                [theme.breakpoints.down('xs')]: {
                    fontSize: "12px",
                },
            },
        },
    },
    menuButton: {
        padding: theme.spacing(1,2) + " !important",
        cursor: "pointer",
        width: "100%",
        borderRadius: "0 !important",
        '& >div': {
            minWidth: 40,
        },
        '&:hover': {
            backgroundColor: theme.palette.background.default,
            '& *':{
                color: theme.palette.primary.main,
            },
        },
        '& button': {
            '&:hover': {
                backgroundColor: 'transparent'
            }
        }
    },
}), { name: "NewshubMenu" });

interface MenuProps {
    className?: string;
    openDrawer: boolean;
    onOpen: () => void;
    onClose: () => void;
}

const Menu: FunctionComponent<MenuProps & ToolbarProps> = props => {
    const classes = useStyles();
    const translate = useTranslate();
    const { className, openDrawer, onClose, onOpen } = props;
    const [currentTheme, setCurrentTheme] = useContext(ThemeContext);

    const urlParams = new URLSearchParams(window.location.search);
    const themeParam = urlParams.get('theme') || 'ligth';
    const forceDarkTheme:boolean = !!(themeParam === 'dark');

    const [useDarkTheme, setUseDarkTheme] = React.useState(forceDarkTheme);
    const [feedbackFormIsOpen, setFeedbackFormIsOpen] = React.useState(false);
    const [filters,setFilters] = useContext(FiltersContext);

    const helpCookieName = 'eup_hlp';
    const [cookies, setCookie] = useCookies<any>([helpCookieName]);

    const [open, setOpen] = useState(false);
    const handleClick = () => {
        setOpen(!open);
    };
    const handleKeyPress = () => {
        setOpen(true);
    };

    const handleCloseMenu = () => {
        if (! feedbackFormIsOpen) {
            onClose();
        }
    };

    const onChangeSection = () => {
        onClose();
        setFilters({...appDefaultFilters});
    };

    const handleCloseFeedbackForm = () => setFeedbackFormIsOpen(false);
    const handleOpenFeedbackForm = () => setFeedbackFormIsOpen(true);

    React.useEffect(() => {
        if(! openDrawer){
            setOpen(false);
        }
    },[openDrawer]);


    const itemActiveClass  = (to:string) =>{
        let baseUrl = window.location.pathname.split('/').slice(0,2).join('/');
        return (baseUrl === to)? 'active':'';
    };

    const showTutorial = () => {
        setFilters({
            ...filters,
            crossActions:{
                ...filters.crossActions,
                TutorialEvent:{
                    action: 'show',
                    payload :{time: Date.now()}
                }
            }
        });
    }

    const renderListItem = (to: string, label: string, icon: any, classname: any) => (
        <ListItem>
            <Link color="inherit" className={itemActiveClass(to)} to={to} onClick={onChangeSection}>
                <ListItemIcon>
                    {
                        openDrawer ? icon : (
                            <Tooltip
                                placement="right"
                                title={translate(label)}
                            >
                                {icon}
                            </Tooltip>

                        )
                    }
                </ListItemIcon>
                <ListItemText primary={translate(label)} />
            </Link>
            <Box className={classname} sx={{ display: { xs: 'none', md: 'block' }}}></Box>
        </ListItem>
    );

    React.useEffect(() => {
        if(useDarkTheme){
            setFilters({...filters,theme:'dark'});
            setCurrentTheme({...darkTheme});
        } else {
            setFilters({...filters,theme:'light'});
            setCurrentTheme({...lightTheme});
        }

    }, [useDarkTheme]);

    function handleThemeToggle() {
        setUseDarkTheme(!useDarkTheme)
    }

    return (
        <Drawer
            onMouseEnter={onOpen}
            onMouseLeave={handleCloseMenu}
            onFocus={onOpen}
            className={classNames(classes.drawer, className, {
                [classes.drawerOpen]: openDrawer,
                [classes.drawerClosed]: ! openDrawer,
            })}
            classes={{
                paper: classNames({
                    [classes.drawerOpen]: openDrawer,
                    [classes.drawerClosed]: ! openDrawer,
                }),
            }}
            variant="permanent"
        >
            <Box sx={{ display: { xs: 'none', md: 'block' }}}>
                <Typography className={classes.nhlogo} >
                    <Link color="inherit" to={"/"} onClick={onChangeSection}>
                        <img src="/epnh_full_logo.png" alt={translate("newshub.menu.claim.title")} width={170} height={43} />
                    </Link>
                </Typography>
            </Box>
            <nav>
            <List className={classes.menuList}>
                {renderListItem("/", "newshub.menu.home", <HomeIcon />, "")}
                {renderListItem("/timeline", "newshub.menu.timeline", <TimelineIcon />,"joyrideHome03")}
                {renderListItem("/channels", "newshub.menu.channels", <ChannelsIcon />,"joyrideHome04")}

                <ListItem onClick={handleClick} onKeyDown={handleKeyPress} tabIndex={0} className={classes.menuButton}>
                    <ListItemIcon>
                        <HelpIcon />
                    </ListItemIcon>
                    <ListItemText primary={translate("newshub.menu.help")} />
                    {open ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem className={classes.nested}>
                            {/*<MenuTutorialButton />*/}
                            <Link color="inherit" onClick={showTutorial} to={"/"}>
                                <ListItemText primary={translate("newshub.menu.tutorial")} />
                            </Link>
                        </ListItem>
                        <ListItem className={classes.nested} onClick={onClose}>
                            <Link color="inherit" to={"/privacy"}>
                                <ListItemText primary={translate("newshub.menu.privacyPolicy")} />
                            </Link>
                        </ListItem>
                        <ListItem className={classes.nested} onClick={onClose}>
                            <Link color="inherit" to={"/cookies"}>
                                <ListItemText primary={translate("newshub.menu.legalNote")} />
                            </Link>
                        </ListItem>
                        <ListItem className={classes.nested}>
                            <MenuSendFeedbackButton
                                open={feedbackFormIsOpen}
                                onClose={handleCloseFeedbackForm}
                                onOpen={handleOpenFeedbackForm}

                            />
                        </ListItem>
                    </List>
                </Collapse>

                <ListItem>
                    <Button disableRipple disableTouchRipple={true} disableElevation={true} onClick={() => { handleThemeToggle(); onClose();}} className={classes.menuButton}>
                        <ListItemIcon>
                            { (useDarkTheme)? <Brightness7Icon/>:<ToggleThemeIcon />}
                        </ListItemIcon>

                        <ListItemText primary={ (useDarkTheme)? translate("newshub.menu.lightmode"): translate("newshub.menu.darkmode")} />
                    </Button>
                </ListItem>
            </List>
            </nav>
            <Box className={classes.bottomContainer}>
                { (openDrawer)?
                    <Fade in={true} timeout={{ enter: 400, exit: 0, }} style={{ transitionDelay: "500ms" }} >
                        <Box>
                            <Typography component={"p"} className={classes.claimTitle}>{translate("newshub.menu.claim.title")}</Typography>
                            <Typography component={"p"} className={classes.claimTxt}>{translate("newshub.menu.claim.description")}</Typography>
                        </Box>
                    </Fade>
                : ''}
                <Box className={classes.eplogo}>
                    <a href="https://www.europarl.europa.eu/portal/en" target="_blank" >
                        <img src="/ep_logo.png" alt="logo" width={180} height={31}/>
                    </a>
                </Box>
            </Box>
        </Drawer>
    );
};

export default Menu;
