import { DataProvider } from "../../data";
import { all } from "redux-saga/effects";
import { fetchSaga } from "../../fetch";
import { notificationSaga } from "../../notification";
import { redirectionSaga } from "../../redirection";
import { refreshSaga } from "../../refresh";
import { undoSaga } from "../../undo";

const newshubSaga = (dataProvider: DataProvider) => function* newshub() {
    yield all([
        undoSaga(),
        fetchSaga(dataProvider)(),
        notificationSaga(),
        redirectionSaga(),
        refreshSaga(),
    ]);
};

export { newshubSaga }
