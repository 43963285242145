import React, { FunctionComponent } from "react";
import MepCard from "./MepCard";
import { Mep, ResourceList } from "../../service/data";
import { useMepCardListStyles } from "./useMepCardListStyles";
import {Grid, MenuItem, MenuList} from "@material-ui/core";
import MepCardSearchBar from "./MepCardSearchBar";

interface MepCardListProps {
    data: ResourceList<Mep>;
    title?: string;
    total: number;
}

const MepCardListSearchBar: FunctionComponent<MepCardListProps> = (props: MepCardListProps) => {
    const classes = useMepCardListStyles();
    const { data } = props;

    return (
        <Grid container className={classes.list}>
            <MenuList  variant={"selectedMenu"} className={classes.searchModuleMenuList}>
            {
                data.map(record => (
                    <MepCardSearchBar
                        className={classes.card}
                        key={record.id}
                        record={record}
                    />
                ))
            }
            </MenuList>
        </Grid>
    );
};

export default MepCardListSearchBar;
