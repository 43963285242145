import { FunctionComponent,useContext,useState,useEffect,useRef } from "react";
import MepCardList from "./MepCardList";
import MepCardListSkeleton from "./MepCardListSkeleton";
import { ErrorContent,ErrorContent503,NotFoundContent,NotFoundContentChannels } from "../layout";
import {apiProvider,ApiParamsType} from "../../service/data/apiProvider";
import {FiltersContext,MapContributorParams} from "../../service/filters"


const MepList: FunctionComponent = () => {
    const [filters,setFilters] = useContext(FiltersContext);
    const [contributorCards,setContributorCards] = useState<any|null>(null);
    const [version,setVersion] = useState(0);
    const [isFetching, setIsFetching] = useState(0); //0  nit fetching 1-by params, 2-next page
	const [nextPage, setNextPage] = useState('');

    let intervalId:any = useRef(null);
    let intervalFecth:any = useRef(null);

    useEffect(() => {
        if(intervalId.current) clearInterval(intervalId.current);
        window.removeEventListener('scroll', handleScroll);
        window.addEventListener('scroll', handleScroll);

	}, []);

    const handleScroll = () => {
        if (
            (document.documentElement.scrollTop + window.innerHeight !== document.body.scrollHeight )
            ||	isFetching > 0
		){
		    return;
        }

        setIsFetching(2);
	};

    const fetchContributors = (toNextPage:boolean) => {
        let params:ApiParamsType|string;

        if(toNextPage) {
            if(nextPage === '') return;
            params = nextPage;
        }else {
            params ={...MapContributorParams(filters.channels)} ;
        }

        apiProvider.getContributors(params)
            .then((response) => {
                let oldCards = (contributorCards === null)?[]:contributorCards;
                let newContributors =  (toNextPage)?
                        {
                            ...oldCards,
                            contributors: [...oldCards.contributors,...response.contributors ]
                        }
                    :  {
                            total: response.total,
                            contributors:[...response.contributors]
                        };
                setContributorCards({...newContributors});
                setNextPage(response.next|| '');
               // setTimeout(() => {setIsFetching(0);}, 500);
               setIsFetching(0);
            }).catch((error) => {
                return (
                    <ErrorContent503 errorMessage={error.message || error} />
                )
            });
    };

    useEffect(()=>{
        setIsFetching(1);
    },[filters.channels]);


    useEffect(() => {
        if(isFetching === 0 ) return;
        let toNextPage = (isFetching === 2)? true: false;
        fetchContributors(toNextPage);

	}, [isFetching]);


    if (contributorCards === null ||  isFetching === 1) {
        return (
            <MepCardListSkeleton numItems={5} />
        );
    }


    if(contributorCards.contributors.length === 0){
        return (
            <NotFoundContentChannels/>
        );
    }
    return (
        <MepCardList
            data={contributorCards.contributors}
            total={contributorCards.total}
        />
    );
};

export default MepList;
