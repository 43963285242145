import React, { FunctionComponent } from "react";
import { useTranslate } from "../../service/translation";
import { useContentStyles } from "./useContentStyles";
import {Box, Grid, Link, Typography} from "@material-ui/core";
import classNames from "classnames";

const NotFoundContentHome: FunctionComponent = () => {
    const classes = useContentStyles();
    const translate = useTranslate();

    const getLink = (link:string) => {
        const name = translate(`newshub.error.notFoundContentTipsHome.${link}`);
        return (
            <Link href={"/"+name.toLowerCase()}>{name}</Link>
        );

    };

    const parseTip = () => {
        const tip = translate("newshub.error.notFoundContentTipsHome.tip3", {link1:'##', link2:'##'});
        let parts = tip.split('##');

        return (
           <Typography component={"p"} variant={"h6"} gutterBottom>{parts[0]} {getLink('link1')} {parts[1]} {getLink('link2')} {parts[2]} </Typography>
            
        );

    }
   
    return (

        <Grid container justifyContent={"center"} alignItems={"center"} className={classNames(classes.notFoundContentContainer, 'notFoundContent')}>
            <Grid item>
                <Box mb={4}>
                    <Typography component={"h1"} variant={"h1"} gutterBottom>{translate("newshub.error.notFoundContentHome.title")}</Typography>
                    
                </Box>
                <Typography component={"h2"} gutterBottom>{translate("newshub.error.notFoundContentTipsHome.title")}</Typography>
                <Typography component={"p"} variant={"h6"} gutterBottom>{translate("newshub.error.notFoundContentTipsHome.tip1")}</Typography>
                <Typography component={"p"} variant={"h6"} gutterBottom>{translate("newshub.error.notFoundContentTipsHome.tip2")}</Typography>
                {parseTip()}
            </Grid>
        </Grid>

    );
};

export default NotFoundContentHome;
