import createSagaMiddleware from "redux-saga";
import { CLEAR_STATE } from "../actions";
import { DataProvider } from "../../data";
import { History } from "history";
import { I18nProvider } from "../../translation";
import { InitialState } from "../InitialState";
import { all, fork } from "redux-saga/effects";
import { createNewshubReducer } from "./createNewshubReducer";
import { createStore, compose, applyMiddleware } from "redux";
import { newshubSaga } from "../sideEffect";
import { routerMiddleware } from "connected-react-router";

interface TypedWindow {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: (traceOptions: object) => Function;
}

interface NewshubStoreParams {
    dataProvider: DataProvider;
    history: History;
    customReducers?: any;
    customSagas?: any[];
    i18nProvider?: I18nProvider;
    initialState?: InitialState;
    locale?: string;
}

const createNewshubStore = ({
    dataProvider,
    history,
    customReducers = {},
    customSagas = [],
    initialState,
}: NewshubStoreParams) => {
    const appReducer = createNewshubReducer(customReducers, history);

    // @ts-ignore
    const resettableReducer = (state, action) => appReducer(action.type !== CLEAR_STATE ? state : {
        ...state,
        newsHub: {
            ...state.newsHub,
            loading: 0,
        },
    }, action);

    const saga = function* rootSaga() {
        yield all([ newshubSaga(dataProvider), ...customSagas ].map(fork));
    };

    const sagaMiddleware = createSagaMiddleware();
    const typedWindow = window as TypedWindow;
    const composeEnhancers = (
        //process.env.NODE_ENV === "development" &&
        false &&
        typeof typedWindow !== "undefined" &&
        typeof typedWindow.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ === "function" &&
        // @ts-ignore
        typeof typedWindow.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
            trace: true,
            traceLimit: 25,
        }) as Function
    ) || compose;

    const store = createStore(
        resettableReducer,
        typeof initialState === "function" ? initialState() : initialState,
        composeEnhancers(applyMiddleware(sagaMiddleware, routerMiddleware(history)))
    );

    sagaMiddleware.run(saga);

    return store;
};

export { createNewshubStore };
