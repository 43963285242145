import React, { FunctionComponent } from "react";
import {Card, CardHeader, Grid} from "@material-ui/core";
import { Skeleton, SkeletonProps } from "@material-ui/lab";
import { useMepCardListStyles } from "./useMepCardListStyles";

const MepCardSkeleton: FunctionComponent<SkeletonProps> = (props: SkeletonProps) => {
    const classes = useMepCardListStyles();

    return  (
        <Grid container item xs={12} sm={6} md={4} className="gridItem mepGridItem" alignItems={"flex-start"} justifyContent={"flex-start"}>
            <Card className={classes.card}>
                <CardHeader
                    action={
                        <Skeleton
                            variant="rect"
                            height={30}
                            width={64}
                            {...props}
                        />
                    }
                    avatar={
                        <Skeleton
                            variant="circle"
                            height={48}
                            width={48}
                            {...props}
                        />
                    }
                    subheader={
                        <Skeleton
                            variant="text"
                            width={128}
                            {...props}
                        />
                    }
                    title={
                        <Skeleton
                            variant="text"
                            width={200}
                            {...props}
                        />
                    }
                />
            </Card>
        </Grid>
    );
}

MepCardSkeleton.defaultProps = {
    animation: "wave",
};

export default MepCardSkeleton;
