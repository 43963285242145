import noDataProvider from "../noDataProvider";
import { DataProvider } from "../DataProvider";
import { DataProviderContext } from "../DataProviderContext";
import { NewshubState } from "../../newshub";
import { useContext, useMemo } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import { getDataProviderCallArguments, getRemainingStackedCalls, performCall, stackCall, stackOptimisticCall, waitFor } from "../dataProviderUtils";

/**
 * A hook used to retrieve the dataProvider instance.
 *
 * This hook actually returns a Proxy instance, which behaves just like
 * the actual dataProvider, but dispatches Redux actions along the way.
 * The benefit is that the application tracks the fetching/loading state
 * when using this hook, and stores results in the Redux store for
 * future use.
 *
 * In addition to the usual dataProvider arguments, the methods of the
 * Proxy accept a third argument, which may be used to specify side-
 * effects, or make the action optimistic (using option mutationMode:
 * "optimistic"), or undoable (using mutationMode: "undoable").
 */
const useDataProvider = () => {
    const dispatch = useDispatch();
    const dataProvider = useContext(DataProviderContext) || noDataProvider;
    // optimistic mode can be triggered by a previous optimistic or undoable query
    const isOptimistic = useSelector((state: NewshubState) => state.newshub.refresh.optimistic);
    const store = useStore<NewshubState>();

    return useMemo(() => {
        return new Proxy(dataProvider, {
            get (target: DataProvider, name: string | symbol): any {
                if (typeof name === "symbol") {
                    return;
                }

                return (...args: any[]) => {
                    const { params, options = {}} = getDataProviderCallArguments(args);
                    const {
                        action = "CUSTOM_FETCH",
                        enabled = true,
                        undoable = false,
                        mutationMode = undoable ? "undoable" : "pessimistic",
                        onFailure = undefined,
                        onSuccess = undefined,
                        signature,
                    } = options;

                    const type = name.toString();

                    // @ts-ignore
                    if (typeof dataProvider[type] !== "function") {
                        const error = new Error();
                        error.name = "NoSuchDataProviderMethodError";
                        error.message = `Unknown dataProvider type ${type}`;

                        throw error;
                    }

                    if (onFailure && typeof onFailure !== "function") {
                        const error = new Error();
                        error.name = "IllegalArgumentError";
                        error.message = `The onFailure option should ne a function`;

                        throw error;
                    }

                    if (onSuccess && typeof onSuccess !== "function") {
                        const error = new Error();
                        error.name = "IllegalArgumentError";
                        error.message = `The onSuccess option should be a function`;

                        throw error;
                    }

                    if (mutationMode === "undoable" && ! onSuccess) {
                        const error = new Error();
                        error.name = "IllegalArgumentError";
                        error.message = `You must pass an onSuccess callback calling notify() to use the undoable mode`;

                        throw error;
                    }

                    // noinspection PointlessBooleanExpressionJS
                    if (enabled === false) {
                        return Promise.resolve();
                    }

                    const call = {
                        action,
                        mutationMode,
                        onFailure,
                        onSuccess,
                        params,
                        signature,
                        type,
                        // This ones are passed down because of the rules of hooks
                        dataProvider,
                        dispatch,
                        store,
                    };

                    if (false && isOptimistic) {
                        // When in optimistic mode, fetch calls aren't executed
                        // right away. Instead, they are stacked, to be executed
                        // once the dataProvider leaves optimistic mode. In the
                        // meantime, the app uses data from the store
                        if (mutationMode === "undoable" || mutationMode === "optimistic") {

                            // Optimistic and undoable calls are added to a specific
                            // stack, as they must be replayed first
                            stackOptimisticCall(call);

                        } else {
                            // Pessimistic calls are added to the regular stack and
                            // will be replayed last
                            stackCall(call);
                        }

                        // Return a Promise that only resolves when the optimistic
                        // call was made otherwise other hooks may return loaded=true
                        // before the content actually reaches the Redux store. But
                        // as we can't determine when this particular request was
                        // finished, the Promise resolves only when *all* optimistic
                        // requests are done.
                        return waitFor(() => getRemainingStackedCalls() === 0);

                    } else {
                        return performCall(call);
                    }
                };
            },
        });
    }, [dataProvider, dispatch, isOptimistic, store]);
};

export { useDataProvider }
