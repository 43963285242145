import React from 'react';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import {Alert} from "@material-ui/lab";
import {Typography} from "@material-ui/core";
import { FiltersContext, appDefaultFilters } from "../../service/filters";

export default function SimpleSnackbar() {
    const [open, setOpen] = React.useState(true);
    const [filters,setFilters] = React.useContext(FiltersContext);



    const handleClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const handleStart = () => {
        setFilters({
            ...filters,
            crossActions:{
                ...filters.crossActions,
                TutorialEvent:{
                    action: 'show',
                    payload :{time: Date.now()}
                }
            }
        });
    };

    return (
        <div>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                open={open}
                autoHideDuration={50000}
                onClose={handleClose}
            >

                <Alert onClose={handleClose} severity="info" icon={<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none"/><path d="M9 21c0 .5.4 1 1 1h4c.6 0 1-.5 1-1v-1H9v1zm3-19C8.1 2 5 5.1 5 9c0 2.4 1.2 4.5 3 5.7V17c0 .5.4 1 1 1h6c.6 0 1-.5 1-1v-2.3c1.8-1.3 3-3.4 3-5.7 0-3.9-3.1-7-7-7z"/></svg>}>
                    <Typography variant="body1" component="p" display="inline">Take a tour</Typography>
                    <Button color="primary" size="medium" variant="contained" className="startTutorialButton" onClick={handleStart}>
                        Let's go
                    </Button>
                </Alert>

            </Snackbar>
        </div>
    );
}
