import { DEFAULT_LOCALE } from "./index";

interface AnyNavigatorLanguage extends NavigatorLanguage {
    browserLanguage?: string;
    userLanguage?: string;
}

/**
 * Normalizes a locale tag, stripping out just the language part.
 */
const normalizeLocale = (locale: string): string => String(locale).toLowerCase().split("-")[0];

/**
 * Returns the locale defined in the global window.navigator object.
 * Relies on window.navigator object.
 */
const getBrowserLocale = (defaultLocale = DEFAULT_LOCALE): string => {
    const { language, browserLanguage, userLanguage } = navigator as AnyNavigatorLanguage;

    return normalizeLocale(language || browserLanguage || userLanguage || defaultLocale);
};

/**
 * Identity translation fn.
 */
const identity = (message: string): string => message;

/**
 * Merges translation dictionaries.
 */
const mergeMessages = (...messages: object[]): object => Object.assign({}, ...messages);

export {
    getBrowserLocale,
    identity,
    mergeMessages,
    normalizeLocale,
}
