import React, {ErrorInfo, FunctionComponent, useContext, useState} from "react";
import {useSelector} from "react-redux";
import ErrorContent from "./ErrorContent";
import Menu from "./Menu";
import Notification from "./Notification";
import TopBar from "./TopBar";
import {Box, makeStyles} from "@material-ui/core";
import {SkipToContentButton} from "../button";
import XsBottomNavigation from "./BottomNavigation";
import GoToTop from "./GoToTop";
import {Helmet, HelmetProvider} from 'react-helmet-async';
import {ThemeContext} from "../../config/theme";
import {useTranslate} from "../../service/translation";

const useStyles = makeStyles(theme => ({
    contentWithMenu: {
        height: "100%",
    },
    mainContent: {
        height: "100%",
    },
    topBar: {
        boxShadow: "none !important",
        zIndex: 1101,
    },
}));

interface LayoutProps {
    children: any;
}

interface LayoutState {
    hasError: boolean;
    errorMessage?: string;
    errorInfo?: ErrorInfo;
}

const defaultLayoutState: LayoutState = {
    hasError: false,
};

const Layout: FunctionComponent<LayoutProps> = (props: LayoutProps) => {
    const classes = useStyles();
    const [state] = useState(defaultLayoutState);
    const [menuOpen, setMenuOpen] = useState(false);
    const {children} = props;
    const {hasError, errorMessage, errorInfo} = state;

    const newshubState = useSelector(state => state);

    const handleOpen = () => setMenuOpen(true);
    const handleClose = () => {
        setMenuOpen(false);
    }
    const handleToggle = () => setMenuOpen(!menuOpen);
    const showMenu = window.location.pathname.indexOf("/embed/") === -1;
    const translate = useTranslate();
    const [currentTheme, setCurrentTheme] = useContext(ThemeContext);

    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <meta name="description" content={translate("newshub.description")} />
                    <meta name="theme-color"
                          content={currentTheme.palette.type === 'light' ? currentTheme.palette.primary.main : currentTheme.palette.background.paper}/>
                    <meta name="theme-color"
                          content={currentTheme.palette.type === 'light' ? currentTheme.palette.primary.main : currentTheme.palette.background.paper}
                          media="(prefers-color-scheme: light)" data-rh="true"/>
                    <meta name="theme-color"
                          content={currentTheme.palette.type === 'light' ? currentTheme.palette.primary.main : currentTheme.palette.background.paper}
                          media="(prefers-color-scheme: dark)" data-rh="true"/>
                </Helmet>
                {showMenu &&
                    <>
                        <SkipToContentButton/>
                        <main className={classes.contentWithMenu}>
                            <Menu
                                openDrawer={menuOpen}
                                onOpen={handleOpen}
                                onClose={handleClose}
                            />

                            <Box className={classes.mainContent}>

                                <TopBar
                                    className={classes.topBar}
                                    onToggle={handleToggle}
                                    menuopen={menuOpen ? 1 : 0}
                                />

                                {
                                    hasError ? (
                                        <ErrorContent errorMessage={errorMessage} errorInfo={errorInfo}/>
                                    ) : children
                                }
                            </Box>
                            <Box sx={{display: {xs: 'block', md: 'none'}}}>
                                <XsBottomNavigation/>
                            </Box>
                        </main>
                        <Notification/>
                    </>
                }

                {
                    !showMenu &&
                    <>
                        <main className={classes.contentWithMenu}>
                            <Box className={classes.mainContent}>
                                {
                                    hasError ? (
                                        <ErrorContent errorMessage={errorMessage} errorInfo={errorInfo}/>
                                    ) : children
                                }
                            </Box>
                        </main>
                    </>
                }
                <GoToTop/>
            </HelmetProvider>
        </>
    )
};

export default Layout;
