import React, { FunctionComponent } from "react";
import { ResourceList, SocialItem } from "../../service/data";
import {Box, Typography} from "@material-ui/core";
import { useTranslate } from "../../service/translation";
import { useSocialCardStyles } from "./useSocialCardStyles";

interface ResultsCounterProps {
    data: ResourceList<SocialItem>;
    page: number;
    pageSize: number;
    total: number;
}

const ResultsCounter: FunctionComponent<ResultsCounterProps> = ({ data, page, pageSize, total }: ResultsCounterProps) => {
    const classes = useSocialCardStyles();
    const translate = useTranslate();
    const startOffset = (page - 1) * pageSize;
    const endOffset = startOffset + Math.min(data.length, pageSize);

    return (
        <Box className={classes.counter}>
            <Typography variant="h6">
                {translate((total ===1)?  "newshub.pagination.resultsOne": "newshub.pagination.results", { startOffset, endOffset, page, pageSize, total })}
            </Typography>
        </Box>
    );
};

export default ResultsCounter;
