import React, {FunctionComponent} from "react";
import {AppBar, AppBarProps, Toolbar, makeStyles, Box, Grid, IconButton, Typography} from "@material-ui/core";
import { ToggleFilterButton } from "../button";
import { AdvancedSearchButton } from "../button";
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import {Route} from "react-router";
import SearchBar from "./SearchBar";
import ToggleSearchTopbarButton from "../button/ToggleSearchTopbarButton";
import ProgressBar from "./ProgressBar";
import ChannelsSearchBar from "./ChannelsSearchBar";
import { Link } from "react-router-dom";
import {useTranslate} from "../../service/translation";
import classNames from "classnames";
import { FiltersContext } from "../../service/filters"

const useStyles = makeStyles(theme => ({
    nhlogo: {
        maxWidth: '25px',
    },
    nhlogoContainer: {
        height: '100%',
        "& > div": {
            height: '100%',
        },
        "& h1":{
            fontSize: 0,
        }
    },
    toolbar: {
        display: "flex",
        position: "relative",
        paddingLeft: 55,
        paddingRight: 0,

        boxShadow: "none !important",
        minHeight: '0 !important',
        flexWrap: "wrap",
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        [theme.breakpoints.down("sm")]: {
            backgroundColor: theme.palette.type === "light" ? theme.palette.primary.main : theme.palette.background.paper,
            paddingLeft: 0,
            paddingTop: theme.spacing(0),
            paddingBottom: theme.spacing(0),
            '& svg': {
                color: theme.palette.type === "light" ? "#FFF" : theme.palette.secondary.dark,
            }
        },
    },
    tooolbarWrapper:{
        display: "flex",
        maxWidth: 1200,
        width: "100%",
        margin: "0 auto",
    },
    separator: {
        flex: 1,
    },
    whithoutSearch:{
        height: 40,
    },
    search: {
        height: 40,
        borderRadius: 30,
        backgroundColor: theme.palette.type === "light" ? '#f6f8fa' : '#202124',
        position: "relative",
        maxWidth: 480,
        width: "100%",
        boxShadow: theme.palette.type === "light" ? 'inset 0px 2px 3px #ccc' : 'inset 0px 2px 3px #111',
        margin: "0 auto",
        "&.active":{
            borderRadius: "16px 16px 0 0",
            backgroundColor: theme.palette.type === "light" ? theme.palette.background.paper : theme.palette.background.paper,
            boxShadow: theme.palette.type === "light" ? "0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)" : 'inset 0px 2px 3px #111',
        },
    },
    searchBox: {
        color: "inherit",
        width: "100%",
        "&.active":{
            borderRadius: "30px 30px 0 0",
            backgroundColor: theme.palette.type === "light" ? theme.palette.background.paper : theme.palette.background.paper,
            boxShadow: theme.palette.type === "light" ? "0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)" : 'inset 0px 2px 3px #111',
        },
    },
    searchIcon: {
        alignItems: "center",
        display: "flex",
        height: "100%",
        justifyContent: "center",
        padding: theme.spacing(0, 2),
        position: "absolute",
        pointerEvents: "none",
        color: theme.palette.primary.main,
    },
    searchInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create("width"),
        maxWidth: 480,
        width: "100%",
    },
    loading: {
        marginLeft: "auto",
        position: "absolute",
        right: 0
    },
}));

interface TopBarProps extends AppBarProps {
    onToggle: () => void,
    menuopen: number,
}

const TopBar: FunctionComponent<TopBarProps> = (props: TopBarProps) => {
    const classes = useStyles();
    const translate = useTranslate();
    const { onToggle, menuopen } = props;
    const [boxClass, setBoxClass] = React.useState('');
    const [filters,setFilters] = React.useContext(FiltersContext);

    const withSearch:string  =  (window.location.pathname.indexOf('/tw/') === -1)? classes.search :classes.whithoutSearch;

    //console.log('PROPS', window.location);


    // React.useEffect( () => {
    //     switch(filters.crossActions.searchBarEvent.action){
    //         case 'onFocus':
    //             setBoxClass('active');
    //             break;
    //         case 'onBlur':
    //             setBoxClass('');
    //             break;
    //         case 'searchText':

    //             setBoxClass('');
    //             break;
    //     }

    // },[filters.crossActions.searchBarEvent]);


    return (
        <AppBar position="fixed" {...props}>
            <Toolbar className={classes.toolbar}>
                <Grid container className={classes.tooolbarWrapper}>
                    <Grid item xs={3} sm={3} md className="gridItem">
                        <Box sx={{ display: { xs: 'block', md: 'none' }}}>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={onToggle}
                                edge="start"
                            >
                                {menuopen === 1 ? <CloseIcon /> : <MenuIcon /> }
                            </IconButton>
                            <Box className={classNames('joyrideHomeXs03')} sx={{ display: { xs: 'block', md: 'none' }}}/>
                            <Box className={classNames('joyrideHomeXs04')} sx={{ display: { xs: 'block', md: 'none' }}}/>
                        </Box>
                    </Grid>
                    <Grid item xs={6} sm={6} md={5} className="gridItem">
                        <Box className={classNames(withSearch, 'joyrideHome02',boxClass)} sx={{ display: { xs: 'none', md: 'block' }}}>
                            <Route path={["/", "/timeline", "/contributors/:id", "/privacy", "/cookies"]} exact component={SearchBar}/>
                            <Route path={["/channels"]} exact component={ChannelsSearchBar}/>
                        </Box>
                        <Box sx={{ display: { xs: 'block', md: 'none' }}} className={classes.nhlogoContainer}>
                            <Grid container justifyContent="center" alignItems="center">
                                <Typography component={"div"}>
                                    <Link color="inherit" to={"/"}>
                                        <img src="/epnh_logo.png" alt={translate("newshub.menu.claim.title")} className={classes.nhlogo} width={25} height={26}/>
                                    </Link>
                                </Typography>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item xs={3} sm={3} md className="gridItem">
                        <Box sx={{ display: { xs: 'none', md: 'block' }}}>
                            <AdvancedSearchButton />
                            <Route path={["/", "/timeline"]} exact component={ToggleFilterButton}/>
                        </Box>
                        <Box sx={{ display: { xs: 'block', md: 'none' }}}>
                            <Grid container justifyContent="flex-end">
                                <ToggleSearchTopbarButton />
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Toolbar>
            <ProgressBar/>
        </AppBar>
    );
};

export default TopBar;
