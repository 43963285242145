import { Button, ButtonProps } from "@material-ui/core";
import { FunctionComponent } from "react";
import { useTranslate } from "../../service/translation";

const TranslateContentButton: FunctionComponent<ButtonProps> = (props: ButtonProps) => {
    const translate = useTranslate();

    // No button variant by default, so a text button is used
    // See https://material-ui.com/components/buttons/#text-buttons
    return (
        <Button
           color="primary"
            size="small"
            {...props}
        >
            {props['aria-label']}
        </Button>
    );
};

export default TranslateContentButton;
