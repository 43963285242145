import { TopicVirality } from "../../component";
import { DataProvider } from "./DataProvider";
import { GetCollectionResult } from "./GetCollectionResult";
import { GetInstanceParams } from "./GetInstanceParams";
import { GetInstanceResult } from "./GetInstanceResult";
import { Mep } from "./Mep";
import { PoliticalGroup } from "./PoliticalGroup";
import { SocialItem } from "./SocialItem";
import { SocialMedia } from "./SocialMedia";
import { Topic } from "./Topic";
import { API } from "./API";
import Autolinker from 'autolinker';

const api = new API({});




const virality: TopicVirality[] = [
    "low",
    "medium",
    "high",
    "trending",
];

const topicList = [
    "fok",
    "fun",
    "ful",
    "buala",
    "fan",
    "masbirra",
    "emo",
    "emosido",
    "engañados",
    "elber",
    "galarga",
    "justice",
    "justisia",
    "justizia",
    "fos",
    "ños",
    "covid",
    "plum",
    "tal",
    "cual",
    "ruler",
    "ue",
    "eu",
];

const sleep = async (milliseconds: number): Promise<void> => new Promise(resolve => setTimeout(resolve, milliseconds));
const sleepRandom = async (): Promise<void> => await sleep(5000 * Math.random());

const randomSize = (size: number): number => parseInt((size * Math.random()).toFixed(0), 10);

const items = <T>(item: T, numItems: number): T[] => Array(numItems).fill(item).map((item, i) => ({ ...item, id: i + 1 }));
const itemsRandom = <T>(item: T, size: number = 25): T[] => items(item, randomSize(size));
const topicsRandom = (size: number = 15): Topic[] => {
    const numItems = randomSize(size);
    const topics: Topic[] = [];
    const topicNames = [];

    while (topics.length < numItems) {
        const nextTopic = topicList[randomSize(topicList.length - 1)];

        if (topicNames.indexOf(nextTopic) === -1) {
            topicNames.push(nextTopic);
            topics.push({
                id: topics.length + 1,
                content: nextTopic,
                virality: virality[randomSize(virality.length - 1)],
            });
        }
    }

    return topics;
};





function parseSocialItem(item:any){
    const linkerConf:any = {
        newWindow: true,
        hashtag: 'twitter',
        mention:'twitter'
    };

    return {
        id: item._id,
        content: Autolinker.link(item.item_content,linkerConf),
        media:null,
        mep: {
            id: item.contributorRef,
            avatar: item.mep_epFotoUrl,
            country: item.mep_country,
            facebook: item.mep_facebookId,
            firstName: item.mep_firstName,
            instagram: null,
            lastName: item.mep_lastName,
            politicalGroup:{
                name: item.mep_party,
                party: item.mep_party,
                picture: item.mep_party_picture

            },
            rss: '',
            twitter: item.feed_url,
            www: item.mep_epPageUrl,   //oficial website
            personalWebsite : item.mep_personalWebsite
        },
        sourceUrl: item.item_link,
        source: "twitter",
        postedAt: new Date(Date.now() - 3600000),
    }
}

const apiDataProvider: DataProvider = {

    getPoliticalGroups: async () => {
        let response = await api.Eparties();

        let data:PoliticalGroup[] = [];
        for(var i in response){
            let g = response[i];
            if(!g._id) continue;
            data.push({id:g.userId, name:g.fullName, party: g.eParty, picture: g.picture_link, url:g.profileLink})
        }

        return { data, total: data.length } as GetCollectionResult<PoliticalGroup>;
    },


    getPoliticalGroup: async (params: GetInstanceParams) => {
        await sleepRandom();
        const { id } = params;
        const data:any =  [];

        return { data, total: 1 } as GetInstanceResult<PoliticalGroup>;
    },
    getMeps: async () => {
        await sleepRandom();
        let data:any = [];

        return { data, total: data.length } as GetCollectionResult<Mep>;
    },
    getMep: async (params: GetInstanceParams) => {
        await sleepRandom();
        const { id } = params;
        let data:any = [];

        return { data, total: 1 } as GetInstanceResult<Mep>;
    },
    getSocialItems: async (params) => {
        let response = await api.SocialItem(params);
        let data:any = []
        for(let i in response ) {
            data.push(parseSocialItem(response[i]));
        }

        return { data, total: 0 } as GetCollectionResult<SocialItem>;
    },
    getTopics: async () => {
        let response = await api.Topics();
        let data:any = [];

        let maxValue = 0;

        for(let i in response){
            let value =  parseInt(response[i].value);
            if(value > maxValue) maxValue = value;
            data.push({
                id: response[i]._id,
                content: response[i]._id,
                value: value -1, //fix value 4 in index operation .
                virality:''
            });
        }

        for(let i in data){
            let viralityIndex = Math.floor(data[i].value *100 / (maxValue * 25));
            data[i].virality = virality[viralityIndex];
        }
        return { data, total: data.length } as GetCollectionResult<Topic>;
    },
}

export default apiDataProvider;
